import { Box } from "@mui/material"
import axios from "axios"
import { useEffect, useState } from "react"
import AutocompleteForm from "./Autocomplete"
import Dropdown from "./Dropdown"
import { RadioButton, CheckboxView } from "./inputs"

const Choice = (props) => {

    const [options, setOptions] = useState([])
    let extensionType = props?.item?.extension?.[0]?.valueCodeableConcept?.coding?.[0]?.display

    useEffect(() => {
        const fetchData = async () => {
            if (props?.item?.answerValueSet) {
                let data = await props?.getOptions(props?.item?.answerValueSet)
                setOptions(data)
            }
            else {
                setOptions(props?.item?.answerOption)
            }
        }

        fetchData();

    }, [props?.item])

    return (
        <Box>
            {
                extensionType === 'Radio Button' ?
                    <RadioButton
                        options={options} {...props}
                        onChange={(e) => props?.onChange(e.target.value)}
                        validators={props?.item?.required ? ['required'] : []}
                        errorMessages={props?.item?.required ? ['Please Select an option'] : []}
                    /> :
                    extensionType === 'Check-box' ?
                        <CheckboxView
                            options={options} {...props}
                            required={props?.item?.required ? true : false}
                        /> :
                        extensionType === 'Drop down' ?
                            <Dropdown options={options} {...props}
                                multiple={props?.item?.repeats}
                                validators={props?.item?.required ? ['required'] : []}
                                errorMessages={props?.item?.required ? ['Please Select an option'] : []}
                            /> :
                            extensionType === 'Lookup' || extensionType === 'Auto-complete' ?
                                <AutocompleteForm options={options}
                                    multiple={props?.item?.repeats}
                                    {...props}
                                    freeSolo={false}
                                    validators={props?.item?.required ? ['required'] : []}
                                    errorMessages={props?.item?.required ? ['Please Select an option'] : []}
                                /> : ''
            }
        </Box>
    )
}

export default Choice