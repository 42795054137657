import React, { useEffect } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import ViewPost from "./viewPost";
import { useHistory, useLocation, useParams } from "react-router-dom";
import '../Feed/Feed.css';
import Mobileviewpost from './Mobileviewpost';
import { Link } from 'react-router-dom';
import axios from 'axios';
import URL from '../../apiurl';
import './feedpost.css';
import UserService from '../../../services/UserService';
import { PostUnavailable } from '../Post/commonFun';
import { getPost } from '../../../redux/Actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

function Tabletviewpost(props) {
    console.log(props)
    // const location = useLocation()
    const [post, setPost] = React.useState({})
    const { id } = useParams()
    // console.log(id)
    const history = useHistory()

    const dispatch = useDispatch()
    const postData = useSelector(state => state.userProfile.getPost)

    useEffect(() => {
        getPostById(id)
    }, [id])

    useEffect(() => {
        if (postData.id || postData.error) {
            setPost(postData)
        }
    }, [postData])

    console.log(post, "view")

    const getPostById = () => {
        if (UserService.getToken()) {
            dispatch(getPost(id))
        }
        else {
            axios.get(`${URL.displayPost}/${id}`).then(res => {
                // console.log(res.data)
                setPost(res.data)
            }).catch(res => setPost({ error: true }))
        }
    }

    return (
        <div>
            <Grid container direction="row" className='icon_arrowback'>
                {(UserService.getToken()) ?
                    <Grid item xs={12} className='margin_arrow_icon' onClick={() => history.goBack()}>
                        <ArrowBackIosIcon className='back_view_post cursor'></ArrowBackIosIcon>
                    </Grid> : ""
                }

                <Grid item xs={12} style={{ height: '100%', width: '100%', overflow: 'scroll' }}>
                    {
                        post.error ? <PostUnavailable /> :
                            post.id ? <Mobileviewpost post={post} getPostById={getPostById}></Mobileviewpost> : ''}
                </Grid>
            </Grid>
        </div >
    )
}

export default Tabletviewpost
