import React, { useState, useEffect } from "react";

import { Box, Button, Card, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import UploadKYC from "./uploadDocs";
import { FormDivider } from "../../../../utility/utils";
import { ValidatorForm } from "react-material-ui-form-validator";
import { FileInput, FormLayout } from "../../../../common/components/layout";
import { DOC_API } from "../../../../../services/APIService";
import { URI } from "../../../../utility/uri";
import { Error, Timer } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getFile } from "../../../../common/functions/functions";
import UserService from "../../../../../services/UserService";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom";


let fValues = { type: '', value: '', path: '' }

const OnlyToUploadOrgDocs = (props) => {


    const [KYC, setKYC] = useState(fValues)
    const [entrance, setEntrance] = useState(fValues)
    const [photo, setPhoto] = useState(fValues)
    const [pending, setPending] = useState(false)
    const [load, setLoad] = useState(false)
    const [files, setFiles] = useState([])
    const orgData = useSelector(state => state.registration.userOrgData)

    console.log("props", orgData)

    const id = useParams();

    useEffect(() => {
        updateOrgDetailsByPageId(id)
    }, [])

    useEffect(() => {
        ValidatorForm.addValidationRule('allowedExtension', (value) => {
            if (!value.endsWith('.png') && !value.endsWith('.jpg') && !value.endsWith('jpeg') && !value.endsWith('.pdf')) {
                return false;
            }
            return true;
        });
    }, [])

    useEffect(() => {
        if (orgData?.id) {
            setLoad(true)
            DOC_API.get(URI.getDocsByPageId + orgData?.identifier?.[1]?.value).then(res => {
                // console.log(res.data.data)
                const filesList = res.data.data
                if (filesList?.length > 0) {
                    if (props.status) {
                        if (filesList.filter(f => f.status === 'PENDING')?.length === 3) {
                            setPending(true)
                        }
                        else if (filesList.filter(f => f.status === 'REJECTED')?.length > 0) {
                            setFiles(filesList)
                            for (let i = 0; i < filesList.length; i++) {
                                let file = filesList[i]
                                let values = { path: file?.documentName, value: file?.documentAddress, type: file?.documentName, status: 'PENDING' }
                                if (i === 0) {
                                    setKYC(file?.status === 'REJECTED' ? { ...fValues, id: file?.id } : values)
                                }
                                else if (i === 1) {
                                    setEntrance(file?.status === 'REJECTED' ? { ...fValues, id: file?.id } : values)
                                }
                                else if (i === 2) {
                                    setPhoto(file?.status === 'REJECTED' ? { ...fValues, id: file?.id } : values)
                                }

                            }
                        }
                        else {
                            // props.closeFn()
                        }
                    }
                    else {
                        // props.closeFn()
                    }
                }
                setLoad(false)
            }).catch(err => { alert(err?.data?.message || 'Error.Please try after sometime'); setLoad(false); props.closeFn() })
        }
    }, [])

    const uploadFile = (type) => (file) => {
        // console.log(file, type)
        if (file) {
            const reader = new FileReader()

            reader.readAsDataURL(file);

            reader.onloadend = () => {
                let document = { value: reader.result, path: file.name }
                console.log(document)

                if (type === 'KYC')
                    setKYC({ ...KYC, ...document })
                else if (type === 'entrance')
                    setEntrance({ ...entrance, ...document })
                else if (type === 'photo')
                    setPhoto({ ...photo, ...document })
            }
        }
    }

    const getOrgDetailsByPageId = async (id) => {
       let res = await axios.get(`${process.env.REACT_APP_API_URL}v1/PagesById/${id}`, {
            headers: {
                Authorization: UserService.getToken(),
            }
        })
        return res.data.data?.[0];

    }

    const updateOrgDetailsByPageId = async (id) => {
        let val = getOrgDetailsByPageId(id);

        console.log(val, "free")

        // let data = { ...val, page: { ...val.page, kycStatus: "PENDING" } }
        // await axios.put(`${process.env.REACT_APP_API_URL}v1/updatePagesById/${id}`, data, {
        //     headers: {
        //         Authorization: UserService.getToken()
        //     }
        // }).then((res) => console.log(res.data)).catch((err) => console.log(err));

    }
    const updateOrgDetailsByPageIdReJ = async (id) => {
        let val = getOrgDetailsByPageId(id)
        let data = { ...val, page: { ...val.page, kycStatus: "REJECTED" } }
        await axios.put(`${process.env.REACT_APP_API_URL}v1/updatePagesById/${id}`, data, {
            headers: {
                Authorization: UserService.getToken()
            }
        }).then((res) => console.log(res.data)).catch((err) => console.log(err));

    }


    const handleUpload = async () => {
        setLoad(true)
        let arr = [{ ...KYC }, { ...entrance, type: 'facilityBoard' }, { ...photo, type: 'buildingImage' }]
        // console.log(arr, orgData)
        let data = await Promise?.all(arr.filter(val => val.status !== 'PENDING')?.map(async li => ({
            documentName: li.type,
            id: li.id,
            // documentAddress: li.value,
            documentAddress: li.value.startsWith('https://s3') ? li.value : await getFile(li.value),
            kycStatus: 'PENDING',
            pageId: parseInt(orgData?.identifier?.[1]?.value),
            metadata: {}
        })))
        // console.log(data)
        DOC_API[files?.length > 0 ? "put" : "post"](URI[files?.length > 0 ? "updateKycDocs" : "addKycDocs"], data).then((res) => {

            setKYC(fValues)
            setEntrance(fValues)
            setPhoto(fValues)
            setPending(true)
            setLoad(false)
            setFiles([])
        }).catch(() => {
            alert("Error.Please try uploading again")
            setLoad(false)
        })
    }

    return (

        <Dialog open={true} onClose={props.closeFn} maxWidth={"sm"} sx={{ height: "600px", position: 'absolute', top: "10%" }} fullWidth>
            {/* <TabContext value={tab}> */}
            <DialogTitle sx={{ padding: '0px' }}>
                <Card sx={{ borderRadius: 0, boxShadow: '0px 3px 6px #00000029', padding: '8px' }}>
                    <Box>
                        UploadKYC Documents
                        {/* <TabList onChange={(e, newval) => { setTab(newval) }} classes={{ indicator: { backgroundColor: '#0F22B5!important' } }} aria-label="lab API tabs example" variant="scrollable"> */}
                        {/* {props.edit ?
                                    TabsListEdit?.map((tabs, i) => <Tab className='capitalize m-auto' key={i} classes={{ selected: { color: '#0F22B5!important' } }} label={tabs.name} value={`${i + 1}`} />) :
                                    TabsList?.map((tabs, i) => <Tab className='capitalize m-auto' key={i} classes={{ selected: { color: '#0F22B5!important' } }} label={tabs.name} value={`${i + 1}`} />)
                                } */}
                        {/* </TabList> */}
                    </Box>
                </Card>
            </DialogTitle>
            <DialogContent sx={{ padding: '10px' }}>
                {


                    load ? <p className="tac"><CircularProgress /></p> :
                        pending ? <>
                            {/* <p>Documents already uploaded successfully</p>
        <p className="flx-center" style={{color: 'red'}}><Error/>&nbsp;Pending KYC Verification</p>
        <p className="tar"><Button onClick={() => props.closeFn()} className="save-btn" variant="contained">Close</Button></p>
        </> : 
        success ? <> */}
                            <br />
                            <h5 className="tac" style={{ color: 'blue' }}>KYC documents uploaded successfully</h5>
                            <Typography variant="body1" className="flx"><Timer />&nbsp;Please wait while we review your KYC details, it will take less than 24 hours </Typography>
                            <p className="tar mb0 mt4">Thankyou</p>
                            <p className="tar fntBold">Team Docsteth</p>
                            <FormDivider />
                            <p className="tar mb0"><Button onClick={() => props.closeFn()} className="save-btn" variant="contained">Close</Button></p>
                        </> :
                            <ValidatorForm onSubmit={handleUpload}>

                                {files?.length > 0 ? <><h5 style={{ color: 'red' }}>KYC Submission Rejected</h5><p style={{ color: 'red' }}>Resubmit your KYC documents</p></> : <h5>Upload Facility Verfication Proofs</h5>}
                                <Grid container spacing={2}>
                                    <FormLayout size={12} disabled={KYC.status === 'PENDING'} required errorMessages={['Select Document Type']} label={<>Facility Address Proof Document{files.length > 0 && KYC.status !== 'PENDING' ? <span style={{ color: 'red' }}>&nbsp;<Error fontSize="small" /> Rejected</span> : ''} </>} type="select" options={['ROC Certificate', 'Building Lease Aggrement', 'GST Certificate', 'Tax Id']} handleChange={(name, value) => { setKYC({ ...KYC, type: value }) }} value={KYC.type} placeholder="Document Type" />

                                    <FileInput name="addressProof" value={KYC} onChange={uploadFile('KYC')} clear={() => setKYC(fValues)} />
                                    <FileInput label={<>Facility Board - Entrance View {files.length > 0 && entrance.status !== 'PENDING' ? <span style={{ color: 'red' }}>&nbsp;<Error fontSize="small" /> Rejected</span> : ''}</>} name="entrance" value={entrance} onChange={uploadFile('entrance')} clear={() => setEntrance(fValues)} />
                                    <FileInput label={<>Facility Building Photograph (street view of Facility){files.length > 0 && photo.status !== 'PENDING' ? <span style={{ color: 'red' }}>&nbsp;<Error fontSize="small" /> Rejected</span> : ''}</>} name="photo" value={photo} onChange={uploadFile('photo')} clear={() => setPhoto(fValues)} />

                                    <Grid item xs={12} className="tar">
                                        <Button onClick={() => props.closeFn()} className="close-btn" variant="outlined">Skip</Button>
                                        <Button type="submit" name="save" className="save-btn" variant="contained">Submit</Button>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                }
            </DialogContent>
            {/* </TabContext> */}
        </Dialog>
    )
}

export default OnlyToUploadOrgDocs;









