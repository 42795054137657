import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { useFormik, getIn } from 'formik'
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles';
import DialogContent from '@mui/material/DialogContent';
import Plus from '../../../../images/plus.svg'
import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import '../myprofile.css';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { CssTextField } from './createpage'
import { getAPI, allCountries } from './userupdate';
import URL from '../../../apiurl';
import { getMetaData, getPageById } from '../../../../redux/Actions/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
    container: {
        alignItems: 'flex-start'
    },
    paper: {
        marginTop: '65px'
    },
    grid: {
        marginBlockEnd: '20px',
    },
    // heading: {
    //     fontSize: theme.typography.pxToRem(15),
    //     flexShrink: 1,
    //     fontWeight: theme.typography.fontWeightRegular,
    // },
    righticon: {
        display: 'flex',
    },
    acordian: {
        margin: "12px"
    },
    icon: {
        position: "absolute",
        right: "50px"
    },
    label: {
        fontSize: '18px',
        fontWeight: 'bold',
        padding: '12px 0px 5px 0px'
    },
    subtext: {
        fontSize: '15px',
        // paddingLeft: '30px',
        margin: '0px'
    },
    email1: {
        // paddingLeft: '10px',
        fontSize: '14px'
    },
    itemSpace: {
        paddingLeft: '5%',
    },

}));


let myvalue = {}


export default function Updatepage({ open, handleDialog, mydata1, text, isopenupdatepage }) {
    const typedata = ["Healthcare Provider", "Organizational team", "Government", "Insurance Company",
        "Payer", "Educational Institute", "Religious Institution", "Clinical Research Sponsor",
        "Community Group", "Non - Healthcare Businessor Corporation", "Other"]
    let { id } = useParams();
    const classes = useStyles();
    //let { id } = useParams();
    const [location, setLocation] = React.useState("");
    const [contact, setContact] = React.useState([{ system: '', use: '', value: '' }])
    const [check, setCheck] = React.useState(false)
    const [link, setLink] = useState([{ assigner: '', type: '', id: '' }])
    const [expanded, setExpanded] = React.useState(false);
    const [countries, setCountries] = React.useState([])
    const [states, setStates] = React.useState([])
    const [cities, setCities] = React.useState([])
    const [loc, setLoc] = React.useState({ country: null, state: null, city: null })

    const data = useSelector(state => state.userProfile.user)
    const dispatch = useDispatch()


    // const handleChange1 = (panel) => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panel : false);
    // };

    // React.useEffect(() => {
    //     let countries = Country.getAllCountries()
    //     setCountries(countries)
    //     if (formik.values.address.country) {
    //         let cou = countries.filter(c => c.name === formik.values.address.country ? c : '')
    //         let states = State.getStatesOfCountry(cou[0].isoCode)
    //         setStates(states)
    //         if (formik.values.address.state) {
    //             let state = states.filter(c => c.name === formik.values.address.state ? c : '')
    //             let cities = City.getCitiesOfState(state[0].countryCode, state[0].isoCode)
    //             setCities(cities)
    //         }
    //     }
    // }, [])

    React.useEffect(() => {
        const fetchData = async () => {
            const country = await allCountries
            setCountries(country)
            if (formik.values.address.country) {
                let code = country.filter(country => (country.name == formik.values.address.country) ? country : '')

                if (code.length > 0) {
                    getStates(code[0].iso2, formik.values.address.state)
                    setLoc({ ...loc, country: code[0] })
                }
                else {
                    setStates([])
                    setCities([])
                }
            }
        }

        fetchData()
    }, [])

    const getStates = async (country, statename) => {
        let states = await getAPI(`${URL.elib}country_wise_states/${country}`)
        setStates(states)
        if (statename) {
            let code = states.filter(state => state.name == statename ? state : '')
            if (code.length > 0) {
                getCities(country, code[0].iso2)
            }
            else {
                setCities([])
            }
        }
    }

    const getCities = async (country, state) => {
        let cities = await getAPI(`${URL.elib}state_wise_cities/${country}-${state}`)
        setCities(cities)
    }

    useEffect(() => {
        setLocation(mydata1.location)
    }, [])

    const handleAdd = () => {
        let contact = formik.values.telecom
        let length = formik.values.telecom.length
        if (length == 0) {
            let telecom = [...formik.values.telecom]
            telecom.push({ system: '', use: '', value: '' })
            formik.setValues({ ...formik.values, telecom })
        }
        else if (contact.length > 0 && contact[length - 1].system !== '' && contact[length - 1].use !== '' && contact[length - 1].value !== '') {
            let telecom = [...formik.values.telecom]
            telecom.push({ system: '', use: '', value: '' })
            formik.setValues({ ...formik.values, telecom })
        }
        else {
            alert("Please fill the empty fields")
        }
    }

    // const handleAddFacility = () => {
    //     let length = link.length
    //     if (link[length - 1].assigner !== '' && link[length - 1].type !== '' && link[length - 1].id !== '') {
    //         setLink([...link, { assigner: '', type: '', id: '' }])
    //     }
    //     else {
    //         alert("Please fill the empty fields")
    //     }
    // }




    const handlevalidate = () => {
        alert("validated")
    }


    const getlocation = () => {
        const Location = window.navigator && window.navigator.geolocation
        if (Location) {
            Location.getCurrentPosition((position) => {
                setLocation(`${position.coords.latitude} ,${position.coords.longitude}`)
            }, (error) => {
                { }
            })
        }
    }

    const initialdata = (text === 'updatedform') ? mydata1 : myvalue
    const formik = useFormik({
        initialValues: initialdata,
        enableReinitialize: true,
        onSubmit: async (values) => {
            values.location = location
            let data = values.telecom.filter(con => con.system !== null && con.system !== '')
            values.telecom = data
            let myvalue = {
                date: new Date(),
                coverPhoto: values.coverPhoto,
                description: values.description,
                id: id,
                location: values.location,
                metadata: await getMetaData(initialdata.metadata),
                organisation: {
                    resourceType: "Organization",
                    name: values.name,
                    identifier: [
                        {
                            system: values.assigner,
                            type: {
                                text: values.caption,
                                coding: [{
                                    system: "http://terminology.hl7.org/CodeSystem/v2-0203",
                                    display: values.idType,
                                }]
                            },
                            value: values.id
                        }
                    ],
                    type: [
                        {
                            "coding": [
                                {
                                    "system": "http://terminology.hl7.org/CodeSystem/organization-type",
                                    "display": values.orgType
                                }
                            ]
                        }
                    ],
                    telecom: values.telecom,
                    address: [
                        {
                            use: values.Use,
                            line: [values.address.line[0], values.address.line[1]],
                            country: values.address.country,
                            state: values.address.state,
                            city: values.address.city,
                            district: values.address.district,
                            postalCode: values.address.postalCode,
                            text: 'Primary'
                        }
                    ],
                    contact: values.contact
                },
                orgPageImage: values.orgPageImage,
                pageName: values.name,
                userId: Number(sessionStorage.getItem("userId")),
                pagePortal: values.pagePortal,
                pageWebsite: values.pageWebsite,
                pageAdmin: values.pageAdmin,
                pageStatus: values.pageStatus,
                followersCount: values.followersCount,
                kycStatus: values.kycStatus,
                pageStatusUpdatedDate: values.pageStatusUpdatedDate
            }
            if (text === "updatedform") {
                let auth = `Bearer ${sessionStorage.getItem('kctoken')}`;
                const baseUrl5 = `${process.env.REACT_APP_API_URL}v1/updatePagesById/${id}`; //${sub}
                const header4 = { headers: { Authorization: auth } }
                try {
                    axios.put(baseUrl5, myvalue, header4).then((res) => {
                        // console.log(res.data)
                        isopenupdatepage(res.data)
                        handleDialog()
                        dispatch(getPageById(id))
                        return res.data;
                    })
                } catch (err) {
                    { }
                }
            }
        },
    }, [])


    return (
        <Dialog open={open} onClose={handleDialog} fullWidth maxWidth={'lg'} sx={{ mb: 2, alignItems: 'flex-start' }} className={classes.paper} >
            <DialogTitle sx={{ borderBottom: '1px solid #707070', color: '#B300F5', fontWeight: 'normal', fontSize: '21px' }}>
                Update Organisation Page
            </DialogTitle>
            <DialogContent className='update_org_padding' sx={{ mb: 3 }}>

                <form autoComplete="off" onSubmit={formik.handleSubmit}>

                    <Grid container sx={{ mt: 2 }} spacing={2}>
                        {/* <Grid item xs={2}>
                            <div style={{ border: '1px solid black', width: '105px', height: '98px', borderRadius: '20px' }}>
                                <img src={formik.values.orgPageImage} alt="" height="98px" width="105px" style={{ borderRadius: "20px" }}></img>
                            </div>

                        </Grid> */}

                        <Grid item sm={12} xs={12} style={{ display: "flex", alignItems: "center", padding: '10px 0px 0px 0px' }}>
                            <Grid container>
                                <Grid item xs={12} sm={6} className="identifier_pd" >
                                    <CssTextField
                                        placeholder="Enter Organisation Name"
                                        name="name"
                                        fullWidth
                                        label="Organization Name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="identifier_pd">
                                    <Autocomplete
                                        id="outlined-identifier.partOf-native-simple"
                                        name="orgType"
                                        options={typedata}
                                        getOptionLabel={option => option}
                                        value={formik.values.orgType}
                                        onChange={(e, value) => {
                                            if (value) {
                                                formik.setFieldValue("orgType", value);
                                            }
                                        }}
                                        renderInput={params => (
                                            <CssTextField
                                                {...params}
                                                name="orgType"
                                                label="Type"
                                                variant="outlined"

                                                fullWidth
                                            />
                                        )}
                                    />

                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={6} className="identifier_pd">
                                <CssTextField
                                    placeholder="Enter Caption or Tagline"
                                    name="caption"
                                    fullWidth
                                    label="Caption and Tagline"
                                    value={formik.values.caption}
                                    // value={formik.values.organisation.identifier ? formik.values.organisation.identifier[0].type.text || "" : ""}
                                    onChange={formik.handleChange}

                                />
                            </Grid>
                        </Grid>
                        <Grid xs={12} item style={{ padding: '10px 0px' }}>
                            <p className="p-heading flex-center" >Link Facility Id
                                {/* <img onClick={() => handleAddFacility()} src={Plus} alt="add" /> */}
                            </p>
                        </Grid>


                        <Grid item xs={12} sm={6} className="identifier_pd" >
                            <Autocomplete
                                fullWidth
                                name="assigner"
                                value={formik.values.assigner}
                                id="outlined-identifier.partOf-native-simple"
                                options={["Government of India"]}
                                getOptionLabel={option => option}
                                onChange={(e, value) => {
                                    formik.setFieldValue("assigner", value);
                                }}
                                renderInput={params => (
                                    <CssTextField
                                        {...params}
                                        name="assigner"
                                        label="Assigner"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} className="identifier_pd">
                            <Autocomplete
                                fullWidth
                                name="idType"
                                value={formik.values.idType}
                                id="outlined-identifier.partOf-native-simple"
                                options={["Provider Number"]}
                                getOptionLabel={option => option}
                                onChange={(e, value) => {
                                    formik.setFieldValue("idType", value);
                                }}
                                renderInput={params => (
                                    <CssTextField
                                        {...params}
                                        name="idType"
                                        label="ID Type"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>


                        <Grid item xs={12} sm={6} className="facility_pb">
                            <CssTextField label="ID" placeholder="xxxxxxxxxx"
                                fullWidth
                                name="id"
                                value={formik.values.id}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ my: 'auto' }} className="validate_alignment" >
                            <Button onClick={handlevalidate} className="validate1">Validate</Button>
                        </Grid>
                        <Grid xs={12} item style={{ padding: '15px 0px' }}>
                            <p className="p-heading">Official Billing Address</p>
                        </Grid>
                        <Grid xs={12} sm={6} id="address_line" item className='orz_input_field'>
                            <CssTextField
                                fullWidth

                                placeholder="Facility Name"
                                name="address.Use"
                                // value={formik.values.address.Use}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item id="address_line" className='orz_input_field'>
                            <CssTextField
                                fullWidth

                                placeholder="Address Line 1"
                                name="address.line[0]"
                                value={formik.values.address.line[0]}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item id="address_line" className='orz_input_field'>
                            <CssTextField
                                fullWidth

                                placeholder="Address Line 2"
                                name="address.line[1]"
                                value={formik.values.address.line[1] || ''}
                                onChange={formik.handleChange}


                            />
                        </Grid>

                        <Grid item xs={12} sm={6} className='orz_input_field1'>
                            <Autocomplete
                                id="foreignCountry"
                                // className="country-select"
                                name="address.country"
                                options={countries}
                                getOptionLabel={option => option.name || option}
                                value={formik.values.address.country}
                                onChange={(e, value) => {
                                    formik.setFieldValue("address.state", '');
                                    formik.setFieldValue("address.district", '');
                                    formik.setFieldValue("address.city", '');
                                    formik.setFieldValue("address.postalCode", '');
                                    setStates([])
                                    setCities([])
                                    if (value) {
                                        formik.setFieldValue("address.country", value.name);
                                        getStates(value.iso2);
                                        setLoc({ country: value, state: null, city: null })
                                        // setStates(states)
                                    }
                                    else {
                                        formik.setFieldValue("address.country", '');
                                    }
                                }}
                                renderInput={params => (
                                    <CssTextField
                                        {...params}
                                        name="address.country"
                                        label="Country"

                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} className='orz_input_field' sx={{ pt: '0!important' }}>
                            <Autocomplete
                                id="foreignCountry"
                                // className="country-select"
                                name="address.state"
                                options={states}
                                getOptionLabel={option => option.name || option}
                                value={formik.values.address.state}
                                onChange={(e, value) => {
                                    formik.setFieldValue("address.district", '');
                                    formik.setFieldValue("address.city", '');
                                    formik.setFieldValue("address.postalCode", '');
                                    setCities([])
                                    if (value) {
                                        formik.setFieldValue("address.state", value.name);
                                        getCities(loc.country.iso2, value.iso2);
                                        setLoc({ ...loc, state: value, city: null })
                                    }
                                    else {
                                        formik.setFieldValue("address.state", '');
                                    }
                                }}
                                renderInput={params => (
                                    <CssTextField
                                        {...params}
                                        name="address.state"
                                        label="State"

                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />

                        </Grid>
                        <Grid item xs={12} sm={6} className='orz_input_field1' sx={{ pt: '0!important' }}>
                            <Autocomplete
                                id="foreignCountry"
                                // className="country-select"
                                name="address.city"
                                options={cities}
                                getOptionLabel={option => option.name || option}
                                value={formik.values.address.city}
                                onChange={(e, value) => {
                                    // formik.setFieldValue("address.city", '');
                                    formik.setFieldValue("address.postalCode", '');
                                    if (value) {
                                        formik.setFieldValue("address.city", value.name);
                                        setLoc({ ...loc, city: value })
                                    }
                                    else {
                                        formik.setFieldValue("address.city", '');
                                    }
                                }}
                                renderInput={params => (
                                    <CssTextField
                                        {...params}
                                        name="address.city"
                                        label="City"
                                        variant="outlined"

                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>


                        {/* <Grid item xs={12} sm={6} className='orz_input_field1'>

                            <CssTextField
                                name="address.city"
                                label="City"

                                variant="outlined"
                                fullWidth
                                value={formik.values.address.city}
                                onChange={formik.handleChange}
                            />


                        </Grid> */}
                        <Grid xs={12} sm={6} item className='orz_input_field' sx={{ pt: '0!important' }}>
                            <CssTextField
                                fullWidth

                                label="Pincode"
                                placeholder="Enter your pincode"
                                name="address.postalCode"
                                value={formik.values.address.postalCode}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        {/* <Grid xs={12} item sx={{ padding: '10px 0px' }}>
                            <span className="location inputfield">Facility Location
                                <div className="setinput">
                                    <div className="inputstyle">
                                        <div className="input-container">
                                            <input
                                                className="input-field"
                                                type="text"
                                                placeholder="latitude,longitude"
                                                name="location"
                                                value={location}
                                                onChange={(e) => setLocation(e.target.value)}
                                            />
                                            <i className="material-icons cursor" onClick={getlocation}>&#xe55c;</i>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </Grid> */}
                        <Grid xs={12} item sx={{ padding: '10px 0px' }}>
                            <span className="p-heading flex-center">Facility Contact Point
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <p>Public</p>
                                    <FormGroup>
                                        <Checkbox className="check-pad" disableRipple checked={check} name="check" onChange={() => setCheck(!check)} inputProps={{ 'aria-label': 'controlled' }} />
                                    </FormGroup>
                                    <img onClick={() => handleAdd()} className="cursor" src={Plus} alt="add" />
                                </div>
                            </span>
                        </Grid>

                        {formik.values.telecom[0] !== null && formik.values.telecom.map((contact, i) => {
                            return (

                                <Grid container className='update_telecom'>
                                    <Grid item sm={4} xs={12}>
                                        <Autocomplete
                                            fullWidth
                                            name={`telecom[${i}].system`}
                                            value={formik.values.telecom[i].system}
                                            id="outlined-identifier.partOf-native-simple"
                                            options={["Phone", "Email"]}
                                            getOptionLabel={option => option}
                                            onChange={(e, value) => {
                                                formik.setFieldValue(`telecom[${i}].use`, '');
                                                formik.setFieldValue(`telecom[${i}].value`, '');
                                                formik.setFieldValue(`telecom[${i}].system`, value);
                                            }}
                                            renderInput={params => (
                                                <CssTextField
                                                    {...params}
                                                    name={`telecom[${i}].system`}
                                                    label="System"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />

                                    </Grid>
                                    <Grid item sm={4} xs={12} className="update_work_pd">
                                        <Autocomplete
                                            fullWidth
                                            name={`telecom[${i}].use`}
                                            value={formik.values.telecom[i].use}
                                            id="outlined-identifier.partOf-native-simple"
                                            options={["Official", "Work", "Home"]}
                                            getOptionLabel={option => option}
                                            onChange={(e, value) => {
                                                formik.setFieldValue(`telecom[${i}].use`, value);
                                            }}
                                            renderInput={params => (
                                                <CssTextField
                                                    {...params}
                                                    name={`telecom[${i}].use`}
                                                    label="Use"
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                        {/* <CssTextField
                                            fullWidth

                                            select
                                            name={`telecom[${i}].use`}
                                            value={formik.values.telecom[i].use}
                                            onChange={formik.handleChange}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            variant="outlined"
                                            className="formcontrol"

                                        >
                                            <option aria-label="None" value="" />
                                            <option value={'official'}>official</option>
                                            <option value={'Work'}>Work</option>
                                            <option value={'Home'}>Home</option>

                                        </CssTextField> */}
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <CssTextField
                                            // placeholder="value"
                                            fullWidth
                                            label="Value"
                                            name={`telecom[${i}].value`}
                                            value={formik.values.telecom[i].value}
                                            onChange={formik.handleChange}
                                        >
                                        </CssTextField>
                                    </Grid>
                                </Grid>
                            )
                        })}


                        {/* <Grid xs={12} item sx={{ padding: '10px 0px' }}>
                            <p className="p-heading flex-center">Add People
                                <img onClick={() => handleAdd()} src={Plus} alt="add" />
                            </p>
                        </Grid>

                        {contact.map((con, i) => (
                            <React.Fragment key={i}>
                                <Grid container style={{ padding: '0px 20px' }}>
                                    <Grid item sm={6} xs={12}>
                                        <CssTextField
                                            sx={{ mt: 2 }}
                                            fullWidth

                                            id="outlined-identifier.Person-native-simple"
                                            select
                                            name="person"
                                            value={formik.values.person}
                                            onChange={formik.handleChange}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            variant="outlined"
                                            className="formcontrol"

                                        >
                                            <option aria-label="None" value="" />
                                            {data.peersData.map((ele, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <option value={ele.id}>
                                                            {ele.name}
                                                        </option>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </CssTextField>
                                    </Grid>
                                    <Grid item sm={6} xs={12} className='role_orz'>
                                        <CssTextField
                                            sx={{ mt: 2 }}
                                            fullWidth

                                            id="outlined-identifier.Role-native-simple"
                                            select
                                            name="role"
                                            value={formik.values.role}
                                            onChange={formik.handleChange}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            variant="outlined"
                                            className="formcontrol"

                                        >
                                            <option aria-label="None" value="" />
                                            <option value={'Billing'}>Billing</option>
                                            <option value={'ADMIN'}>Administrative</option>
                                            <option value={'Human Resource'}>Human Resource</option>
                                            <option value={'Payor'}>Payor</option>
                                            <option value={'Patient'}>Patient</option>
                                            <option value={'Press'}>Press</option>
                                        </CssTextField>

                                    </Grid>

                                </Grid>

                            </React.Fragment>
                        ))}

                        <Grid container style={{ padding: '5px 10px' }} className={classes.acordian} >
                            <Grid item style={{ width: "100%" }}>
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange1('panel1')}>
                                    <div className="borderbottom1">
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography>Contact Point<EditOutlinedIcon className={classes.icon} /></Typography>
                                        </AccordionSummary>
                                    </div>
                                    <AccordionDetails>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <Typography className={classes.label}>Email</Typography>

                                            <Grid container className={classes.itemSpace}>
                                                <Grid item md={2} xs={12}><div className={classes.subtext}>Work: </div></Grid>
                                                <Grid item md={7} xs={12}><div className={classes.email1}>EmailID1 @gmail.com</div></Grid>
                                            </Grid>
                                            <Grid container className={classes.itemSpace}>
                                                <Grid item md={2} xs={12}><div className={classes.subtext}>Home: </div></Grid>
                                                <Grid item md={7} xs={12}><div className={classes.email1}>EmailID2 @gmail.com</div></Grid>
                                            </Grid>
                                            <Typography className={classes.label}>Phone</Typography>

                                            <Grid container className={classes.itemSpace}>
                                                <Grid item md={2} xs={12}><div className={classes.subtext}>Work: </div></Grid>
                                                <Grid item md={7} xs={12}><div className={classes.email1}>9848012345</div></Grid>
                                            </Grid>
                                            <Grid container className={classes.itemSpace}>
                                                <Grid item md={2} xs={12}><div className={classes.subtext}>Home: </div></Grid>
                                                <Grid item md={7} xs={12}><div className={classes.email1}>9848012345</div></Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                        <Grid container style={{ padding: '5px 10px' }} className={classes.acordian} >
                            <Grid item style={{ width: "100%" }}>
                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange1('panel2')}>
                                    <div className="borderbottom1">
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel4bh-content"
                                            id="panel4bh-header"
                                        >
                                            <Typography>Address<EditOutlinedIcon className={classes.icon} /></Typography>
                                        </AccordionSummary>
                                    </div>
                                    <AccordionDetails>
                                        <Grid item md={12} sm={12} xs={12}>

                                            <Typography className={classes.address}>plotNo</Typography>
                                            <Typography className={classes.address}>streetRoad,area</Typography>
                                            <Typography className={classes.address}>city</Typography>
                                            <Typography className={classes.address}>state</Typography>
                                            <Typography className={classes.address}>country</Typography>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid> */}
                        <div style={{ textAlign: 'right', marginTop: '15px', marginLeft: 'auto' }}>
                            <Button onClick={handleDialog} className="close-article">Cancel</Button>
                            <Button type="submit" className="save-btn btn__primary create-pad" >Update</Button>
                        </div>
                    </Grid>

                </form>

            </DialogContent>
        </Dialog >
    )
}