import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'
import Preview from './preview'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import CloseIcon from '@mui/icons-material/Close'

export default function Publish(props) {

    const [data, setData] = useState({ coAuthors: '', peers: '', contributors: '', organisations: '', tags: '', desc: '' })
    const { post } = props
    const { id } = useParams()


    useEffect(() => {
        if (id && props.open) {
            setData({
                coAuthors: post?.articleData?.coAuthors ? post.articleData?.coAuthors + " " : '' || '',
                peers: post?.articleData?.peers ? post?.articleData?.peers + " " : '' || '',
                contributors: post?.articleData?.contributors ? post?.articleData?.contributors + " " : '' || '',
                organisations: post?.articleData?.organisation ? post?.articleData?.organisation + " " : '' || '',
                tags: post?.tags || "",
                desc: post?.description || ""
            })
        }
    }, [props.open])

    const handleChange = (name, value) => {
        setData({ ...data, [name]: value })
    }

    return (
        <Dialog open={props?.open} onClose={props?.close} fullWidth>
            <DialogTitle sx={{ p: 0 }}>
                <p className="create-article newcontact-fieldheader">{id ? 'Update' : 'Create'} Post <CloseIcon className="cursor" onClick={props?.close} /></p>
            </DialogTitle>
            <DialogContent sx={{ pt: 3, px: 0, pb: 0, textAlign: 'center' }}>
            
                {/* <Preview title={props?.title} {...props} url={props?.url} data={props?.data ? props?.data : data} handleChange={handleChange} edit={id ? true : false} /> */}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'flex-end' }}>
                {/* <Button className="save-draft" onClick={(e)=>props.draft(e,data)}>Save as Draft</Button> */}
                <Button variant="contained" className="book-header post-btn" onClick={(e) => props?.publish(e, data)}>{id ? 'Update Post' : 'Post'}</Button>
            </DialogActions>
        </Dialog>
    )
}