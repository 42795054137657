import React, { useEffect } from 'react';
import '../App.css';
import Header from '../Components/Molecule/Header/appbar';
import Peers from '../Components/peers/peers.js';
import Signup from '../Components/SignUp/signup';
// import Aboutpage from '../Components/Molecule/Docsteth10/AboutPage';
import MarketPlace from '../Components/Molecule/MarketPlacePage/MarketPlace';
import SubscriptionReviewPage from '../Components/Molecule/MarketPlaceSubscriptionPage/SubscriptionReviewPage';
import Profile from "../Components/Layout/profile/profile";
import Account from "../Components/SignUp/createAccount"
import Login from '../Components/common/login';
import PublicView from '../Components/publicView/public';
import DoseDetails from '../Components/Molecule/DoseDetails/DoseDetails'
import Side from '../Components/Molecule/Elibrary/ElibraryPage'
import DrugClasses from '../Components/Molecule/Elibrary/drugClasses'
import Article from '../Components/Article/addArticle'
// import ArticlePage from '../Components/Molecule/ShareownArticle/Article';
import AddDrug from '../Components/Article/addDrugInfo'
import Trending from '../Components/Molecule/trending'
// import PublicHeader from '../Components/Molecule/ProfileViewConnect/publications/publicHeader';
import {
  BrowserRouter as Router,
  withRouter,
  Switch, Redirect,
  Route, useLocation
} from "react-router-dom";
import ProfileViewandConnect from '../Components/Molecule/ProfileViewConnect/ProfileViewandConnect';
import EbmKit from '../Components/Molecule/EbmKit/specialitites'
import EbmPracticeCardiology from '../Components/Molecule/EbmKit/conditions';
import EbmPracticeRadios from '../Components/Molecule/EbmKit/disease_articles';
import DrugSpecialities from '../Components/Molecule/Elibrary/drugSpecialities';
import EbmkitHeader from '../Components/Molecule/EbmKit/EbmkitHeader';
import DruginformationHeader from '../Components/Molecule/Elibrary/DruginformationHeader';
import Contributors from '../Components/contributors/contributors';
import AdminOrganisationNew from '../Components/Layout/MyProfile/organisation/AdminOrganization/adminOrganisationNew';
import UserService from '../services/UserService';
import Drugs from '../Components/Molecule/Elibrary/alldrugs'
import DevelopmentPage from '../Components/Molecule/EbmKit/development';
import { useDispatch } from 'react-redux';
// import HttpService from '../services/HttpService';
import Tabletviewpost from '../Components/Molecule/feedposts/Tabletviewpost';
import TabletviewpostOrg from '../Components/Layout/MyProfile/organisation/amenities/overviewgallery/Tabletviewpost';
import Ebmkithomepage from '../Components/Molecule/EbmKit/Ebmkithomepage';
import Privacyandpolicy from '../Components/Privacyandpolicy/Privacyandpolicyhomepage';
// import TermsandCondition from '../Components/Privacyandpolicy/TermsandCondition';
// import UserguideandFaq from '../Components/Privacyandpolicy/UserguideandFaq';
// import policyheader from '../Components/Privacyandpolicy/policyheader';
import Policyheader from '../Components/Privacyandpolicy/policyheader';
import NewArticle from '../Components/Article/article'
import ArticleView from '../Components/Article/articleview';
import CompleteProfile from '../Components/common/CompleteProfile';
import Addeducation from '../Components/common/Addeducation';
import Addexperience from '../Components/common/Addexperience';
import Calculator from '../Components/calculators/calculator';
import PdfViewer from '../Components/Molecule/Post/PdfViewer';
import CalculatorForm from '../Components/calculators/calculatorForm';
import Hashtagpost from '../Components/Molecule/Sidebar/Hashtagpost';
import Patientarticle from '../Components/Layout/profile/Patientarticle';
import ReactGA from 'react-ga4'
import MicroFrontend from '../MicroFrontend';
import ShareProfile from '../Components/Layout/LinkAccount/shareprofile';
import CVLogin from '../Components/cvlogin/login';
import NeonatAI from '../Components/Molecule/neonatai';
import LOETabs from '../Components/Article/LOE';
import MedscopeHomepage from '../Components/Molecule/MarketPlacePage/homepage/MedscopeHomepage';
import MedscopePage from '../Components/Molecule/MarketPlacePage/MedscopePage';
import UpComing from '../Components/Molecule/MarketPlacePage/UpComingData';
import TrendingEvent from '../Components/Molecule/MarketPlacePage/homepage/AllEventsData';
import MedicalGallery from '../Components/Molecule/MarketPlacePage/homepage/MedicalGallery';
import Hotvacancies from '../Components/Molecule/MarketPlacePage/homepage/HotVacancyData';
import CertificateCourse from '../Components/Molecule/MarketPlacePage/homepage/CertificateData';
import AppGallery from '../Components/Molecule/MarketPlacePage/AppVault/AppGallery';
import DocstethApps from '../Components/Molecule/MarketPlacePage/AppVault/categoryApps';
import AppData from '../Components/Molecule/MarketPlacePage/AppVault/AppData';
import AppSubscription from '../Components/Molecule/MarketPlacePage/AppSubscribe/AppSubscription';
import CountrySelect from '../Components/Molecule/MarketPlacePage/AppSubscribe/CountrySelect';
import Billing from '../Components/Molecule/MarketPlacePage/AppSubscribe/Billing';
import Confirm from '../Components/Molecule/MarketPlacePage/AppSubscribe/Confirm';
import Protected from '../Components/Molecule/MarketPlacePage/AppSubscribe/Protected';
import ForgotUsername from '../Components/SignUp/ForgotUsername';
import MyApps from '../Components/Molecule/MarketPlacePage/homepage/MyApps';
import HealthDeptInfo from "../Components/Layout/MyProfile/organisation/AdminOrganization/HealthCareDept/particularHealthDeptInfo";
import axios from 'axios';
import { headers } from '../redux/Actions/connectActions';
import OrganizationNew from '../Components/Layout/MyProfile/organisation/PublicOrganization/organisationNew';
import HealthServiceInfo from '../Components/Layout/MyProfile/organisation/HealthServicesDept/particularHealthService.js';
import { jwtDecode } from 'jwt-decode';
import URL from '../Components/apiurl.js';
import BackToPage from '../Components/Layout/profile/BackToPage.js';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';
// import keycloakInfo from "../../public/keycloak.json"

// const webinar = 'http://localhost:4000' 
const webinar = process.env.REACT_APP_WEBINAR

const chat = 'https://devchat.docsteth.com'
// const userAccount = 'http://localhost:2000'

// ReactGA.initialize('G-3R71ZNG12K');

function ScrollToTop() {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const WebinarApp = ({ history }) => (
  <MicroFrontend
    history={history}
    host={webinar}
    name="Webinar"
    title="New"
  />
);

// const ChatApp = ({ history }) => (

//   <MicroFrontend
//     history={history}
//     host={chat}
//     name="Chat"
//     title="New"
//   />

// );

// const UserAccountApp = ({ history }) => (
//   <MicroFrontend
//     history={history}
//     host={userAccount}
//     name="UserAccount"
//     title="UserAccount"
//   />
// );

// const CreateReactFHIR = ({ history }) => (
//   <MicroFrontend
//     history={history}
//     host={fhirhost}
//     name="FHIR"
//     title="New"
//   />
// );

// console.log(HttpService.configure())
function Routes() {
  // const state = useSelector((state) => state.userProfile.ChatAppOpen)
  const dispatch = useDispatch();
  const chatappclose = (val) => {
    // dispatch(getChatAppOpen(val))
  }

  // React.useEffect(() => {
  //   ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  //   // ReactGA.pageview(window.location.pathname + window.location.search);
  // })



  // useEffect(() => {
  //   sessionStorage?.setItem('kctoken', UserService.getToken())
  // }, [])

  // const kc=new KeyCloak("/keycloak.json")

  const refreshToken = UserService.getRefreshToken();
  const refreshdata = {
    refreshToken: refreshToken,
    clientId: UserService?.getClientName()
  };



  const { params } = useParams()
  const { search } = useLocation();
  console.log(params, "paramsssss123", sessionStorage.setItem("FBtoken", search), UserService?.getClientName(), UserService?.getUserId())

  useEffect(() => {



    const fetchNewAccessToken = async () => {

      try {

        if (sessionStorage?.getItem("kctoken")) {

          const decoded = jwtDecode(sessionStorage?.getItem("kctoken"));
          console.log(decoded, "decoded")
          let exp = decoded?.exp;
          let currentTime = Math?.floor(Date.now() / 1000);
          if (currentTime && exp) {
            if (typeof refreshToken === 'string' && refreshToken?.trim() !== '') {
              const response = await axios.post(
                process.env.REACT_APP_API_URL + `v1/AccessTokenByRefreshToken`,
                refreshdata
              );
              const { access_token, refresh_token } = response.data;
              sessionStorage?.setItem("kctoken", access_token);
              sessionStorage?.setItem("refresh_token", refresh_token);
            } else {
              console.error("Invalid refresh token:", refreshToken);
            }
          }
        }
      } catch (error) {
        console.error("Error refreshing access token:", error);
      }
    };

    if (refreshToken) {
      fetchNewAccessToken();
    }
  }, [sessionStorage.getItem("kctoken"), UserService.getRefreshToken()]);

  return (
    <div className="app">
      <div>
        {/* {(UserService.getToken()) ? <ConversationsApp chatappclose={chatappclose} state={state} /> : ""} */}
        {/* Header */}
        {console.log(UserService?.getToken(), "mani")}
        {(UserService?.getToken()) ?
          <>
            <ScrollToTop />
            <Switch>
              <Route exact path='/home' component={Signup} />
              <Route path='/createaccount' component={Account} />
              <Route path='/login' component={Login} />
              <Route path='/publicview' component={PublicView} />
              <Route path='/article' component={Article} />
              <Route path='/adddrug' component={AddDrug} />
              <Route path='/contributors' component={Contributors} />
              {/* <Route path='/newarticle' component={NewArticle} /> */}
              <Route path='/editarticle/:name/:id' component={NewArticle} />
              <Route path="/completeprofile" component={CompleteProfile} />
              <Route path='/addeducation' component={Addeducation} />
              <Route path='/addexperience' component={Addexperience} />
              <Route path="/BackToDocsteth" component={BackToPage} />

              {/* <Route path="/userprofile/:name/:id" component={ProfileViewandConnect} /> */}
              {/* App Body */}
              <div className='app__body'>
                <Header />
                <Route exact path="/">
                  <Trending />
                </Route>
                {/* <Route path="/fhirsource" render={({ match: { path } }) => (
                  <Switch>
                    <Route path={`${path}/:path`} component={CreateReactFHIR} />
                  </Switch>
                )} /> */}
                <Route path="/webinar" render={({ match: { path } }) => (
                  <Switch>
                    <Route path={`*`} component={WebinarApp} />
                  </Switch>
                )} />

                <Route path='/newarticle' component={NewArticle} />

                <Route path={`/lms`} component={() => <iframe src="http://lms.docsteth.com" width="100%" style={{ height: '100vh', borderWidth: 0, marginTop: '10px' }} />} />
                <Route path={`/courses`} component={() => <iframe src="http://apps.lms.docsteth.com/learning/course/course-v1:edX+DemoX+Demo_Course/home" width="100%" style={{ height: '100vh', borderWidth: 0, marginTop: '10px' }} />} />

                {/* <Route path="/dashboard" render={({ match: { path } }) => (
                  <Switch>
                    <Route path={`${path}`} component={UserAccountApp} />
                    <Route path={`${path}/*`} component={UserAccountApp} />
                  </Switch>
                )} /> */}

                {/* <Route path={`/dashboard`} component={UserAccountApp} /> */}

                <Route path="/marketplace" render={({ match: { path } }) => (
                  <>
                    <MedscopePage>
                      <Switch>
                        <Route path={`${path}/home`} component={MedscopeHomepage} />
                        <Route path={`${path}/upcomingevents`} component={UpComing} />
                        <Route path={`${path}/myapps`} component={MyApps} />
                        <Route path={`${path}/trendingevents`} component={TrendingEvent} />
                        <Route path={`${path}/certificatecourses`} component={CertificateCourse} />
                        <Route path={`${path}/hotvacancies`} component={Hotvacancies} />
                        <Route path={`${path}/medicaldevicegallery`} component={MedicalGallery} />

                        <Route path={`${path}/appvault`} render={({ match }) => (
                          <Switch>
                            {/* <Route path={`${match.path}/:appname-:id/subscribe`} component={AppSubscription} /> */}
                            <Route path={`${match.path}/:vault/:appname`} component={AppData} />
                            <Route path={`${match.path}/:appname-:id`} component={AppData} />
                            <Route path={`${match.path}/:vault`} component={DocstethApps} />
                            <Route path={match.path} component={AppGallery} />
                          </Switch>
                        )} />
                        <Redirect to="/marketplace/home" />
                      </Switch>
                    </MedscopePage>

                  </>
                )} />
                {/* <Route path={`/subscribe/:appname`} component={AppSubscription} /> */}

                <Route path={`/subscribe/:appname/:id`} render={({ match: { path } }) => (
                  <>
                    <AppSubscription>
                      <Switch>
                        <Protected path={`${path}/:countryCode/billing/:paymentid/confirm`} component={Confirm} />
                        <Route path={`${path}/:countryCode/billing`} component={Billing} />
                        <Route path={`${path}`} component={CountrySelect} />
                      </Switch>
                    </AppSubscription>
                  </>
                )} />


                <Route path="/elib" render={({ match: { path } }) => (
                  <>
                    <div className="">
                      <Side>
                        {/* <DoseDetails/> */}
                        <Switch>
                          <Route path={`${path}/specialities/:diseases/:content`} component={EbmPracticeRadios} />
                          <Route path={`${path}/specialities/:diseases`} component={EbmPracticeCardiology} />
                          <Route path={`${path}/specialities`} component={EbmKit} />
                          <Route path={`${path}/conditions/:content`} component={EbmPracticeRadios} />
                          <Route path={`${path}/conditions`} component={EbmPracticeCardiology} />
                          <Route path={`${path}/Ebmkithomepage`} component={Ebmkithomepage} />
                          <Route path={`${path}/drugspecialities/:drugclass/:drug/:druginfo`} component={DoseDetails} />
                          <Route path={`${path}/drugspecialities/:drugclass/:drug`} component={Drugs} />
                          <Route path={`${path}/drugspecialities/:drugclass`} component={DrugClasses} />
                          <Route path={`${path}/drugspecialities`} component={DrugSpecialities} />
                          <Route path={`${path}/alldrugs/:druginfo`} component={DoseDetails} />
                          <Route path={`${path}/alldrugs`} component={Drugs} />
                          <Route path={`${path}/drugclasses/:drug/:druginfo`} component={DoseDetails} />
                          <Route path={`${path}/drugclasses/:drug`} component={Drugs} />
                          <Route path={`${path}/drugclasses`} component={DrugClasses} />
                          <Redirect exact from={path} to={path + "/ebmkithomepage"} />
                          <Redirect exact from={path} to={path + "/specialities"} />
                          <Route path={`${path}/development`} component={DevelopmentPage} />
                          <Route path={`${path}/neonatai`} component={NeonatAI} />
                          <Route path={`${path}/:name/:id`} component={CalculatorForm} />
                          <Route path={`${path}/:name`} component={Calculator} />
                          <Redirect to="/elib/ebmkithomepage" />
                        </Switch>
                      </Side>
                    </div>
                  </>
                )} />
                <Route path="/privacypolicy" component={Privacyandpolicy} />
                <Route path="/toclinivault/:id" component={CVLogin} />
                <Route path="/organization/:id" component={OrganizationNew} exact={true} />
                <Route path="/admin/organization/:id" render={({ match: { path } }) => {
                  return (
                    <>
                      <AdminOrganisationNew >
                        <Switch>
                          <Route path={`${path}`} component={AdminOrganisationNew} />
                          <Route path={`${path}/healthDepartment`} component={HealthDeptInfo} exact={true} />
                          <Route path={`${path}/healthService`} component={HealthServiceInfo} exact={true} />
                          <Redirect to="/admin/organization/:id" />
                        </Switch>
                      </AdminOrganisationNew>
                    </>
                  )
                }} />

                <Route path="/admin/organization/post/:id" component={TabletviewpostOrg} exact={true} />
                {/* jkkjhgfdfgh */}
                {/* <Route path="/admin/organization/healthDepartment/:id" component={HealthDeptInfo} exact={true} /> */}
                {/* <Route path="/connect" component={Peers} /> */}
                <Route path='/viewarticle/:id' component={ArticleView} />
                <Route path="/connect" render={({ match: { path } }) => (
                  <Peers>
                    <Switch>
                      <Route path={`${path}/:page`} component={Peers} />
                    </Switch>
                  </Peers>
                )} />
                <Route path="/shareprofile" component={ShareProfile} />
                {/* <Route path="/aboutpage" component={Aboutpage} /> */}
                {/* <Route path="/marketplace" component={MarketPlace} /> */}
                <Route path="/myprofile/:name-:id" component={Profile} />
                {/* <Route path="/facebookaUploaded" component={BackToPage} /> */}
                <Route path='/patientarticle' component={Patientarticle} />
                <Route path="/subscriptionreviewpage" component={SubscriptionReviewPage} />
                <Route path="/profile/:name-:id" component={ProfileViewandConnect} />
                <Switch>
                  <Redirect from={`/:name-:id`} to={`/profile/:name-:id`} />
                </Switch>
                <Route path="/cardiology" component={EbmPracticeCardiology} />
                <Route path="/cardiologyradio" component={EbmPracticeRadios} />
                <Route path="/drugspecialities" component={DrugSpecialities} />
                <Route path="/ebmkitheader" component={EbmkitHeader} />
                <Route path="/drugheader" component={DruginformationHeader} />
                <Route path="/post/:id" component={Tabletviewpost} />
                <Route path="/article/:id" component={Tabletviewpost} />
                <Route path="/hashtagpost" component={Hashtagpost} />
                <Route path="/pdfviewer" component={PdfViewer} />
                <Route path="/loe" component={LOETabs} />
                {/* <ChatApp /> */}
              </div>
            </Switch>
          </>
          :
          <Switch>
            <Route exact path="/home" component={Signup} />
            <Route exact path="/" component={Signup} />
            <Route exact path="/forgotusername" component={ForgotUsername} />
            {/* <> */}
            <Route exact path='/createaccount' component={Account} />
            <Route path="/organization/:id" component={OrganizationNew} />
            <Route exact path="/profile/:name-:id" component={ProfileViewandConnect} />
            <Redirect from={`/:name-:id`} to={`/profile/:name-:id`} />
            {/* </> */}
            <Policyheader>
              <div className="app__body">
                <>
                  <Route path="/privacypolicy" component={Privacyandpolicy} />
                  <Route path="/termsandcondition" component={Privacyandpolicy} />
                  <Route path="/userguideandfaq" component={Privacyandpolicy} />
                  <Route path="/webinar" render={({ match: { path } }) => (
                    <Switch>
                      <Route path={`*`} component={WebinarApp} />
                    </Switch>
                  )} />
                  <Route path="/elib" render={({ match: { path } }) => (
                    <>
                      <Side>
                        {/* <DoseDetails/> */}
                        <Switch>
                          <Route path={`${path}/specialities/:diseases/:content`} component={EbmPracticeRadios} />
                          <Route path={`${path}/specialities/:diseases`} component={EbmPracticeCardiology} />
                          {/* <Route path={`${path}/specialities/cardiology/:content`} component={EbmPracticeRadios}/> */}
                          <Route path={`${path}/specialities`} component={EbmKit} />
                          <Route path={`${path}/conditions/:content`} component={EbmPracticeRadios} />
                          <Route path={`${path}/conditions`} component={EbmPracticeCardiology} />
                          <Route path={`${path}/ebmkithomepage`} component={Ebmkithomepage} />
                        </Switch>
                        <Switch>
                          <Route path={`${path}/drugspecialities/:drugclass/:drug/:druginfo`} component={DoseDetails} />
                          <Route path={`${path}/drugspecialities/:drugclass/:drug`} component={Drugs} />
                          <Route path={`${path}/drugspecialities/:drugclass`} component={DrugClasses} />
                          <Route path={`${path}/drugspecialities`} component={DrugSpecialities} />
                          <Route path={`${path}/alldrugs/:druginfo`} component={DoseDetails} />
                          <Route path={`${path}/alldrugs`} component={Drugs} />
                          <Route path={`${path}/drugclasses/:drug/:druginfo`} component={DoseDetails} />
                          <Route path={`${path}/drugclasses/:drug`} component={Drugs} />
                          <Route path={`${path}/drugclasses`} component={DrugClasses} />
                          <Redirect exact from={path} to={path + "/specialities"} />
                          <Route path={`${path}/development`} component={DevelopmentPage} />
                          {/* <Redirect to="/elib/ebmkithomepage" /> */}
                        </Switch>
                      </Side>
                    </>
                  )} />
                </>
              </div>
            </Policyheader>
          </Switch>
        }
      </div>
    </div >
  );
}
export default withRouter(Routes);