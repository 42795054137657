import TextField from '@mui/material/TextField';
// import { styled } from '@mui/material/styles';
// import { styled } from '@mui/material/styles';
import { styled } from '@mui/styles';

import { TextValidator } from 'react-material-ui-form-validator';

export const CssTextField = styled(TextValidator)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '12px',
      height: '43px',
      boxShadow: '0px 3px 4px #756d6d29',
      color: 'grey',
      top: 3
    },
  }
});

export const CommentTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    padding: '11px 15px',
    borderRadius: '10px',
    minHeight: '30px!important',
    padding: '7px 15px'
  }
});

export const CelTextField = styled(TextValidator)({
  '& .MuiOutlinedInput-root': {
    // padding: '11px 15px',
    borderRadius: '10px',
    // minHeight: '30px!important',
    height: '40px',
    padding: '10px 19px!important'
  },
  '& input': {
    padding: '0px!important'
  },
  '& label': {
    top: '-8px'
  },
  '& .MuiInputLabel-shrink': {
    top: '0px!important'
  },
  '& .MuiAutocomplete-endAdornment': {
    // top: '-1px!important',
    height: 'inherit'
  }
});