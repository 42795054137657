import { Box, Typography, Button } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router-dom";
import event_icon from '../../../../images/event.svg'
import UserService from "../../../../services/UserService";

export const AttendBtn = ({ text, fun, variant }) => {
    return (
        <Typography component="div">
            <p className="text-right">
                <Button className={`color-head add-option ${variant ? 'pointer_none' : ''}`} onClick={fun} variant={variant || "contained"}>{text}</Button>
            </p>
        </Typography>
    )
}

export default function EventView({ data, open, image, ...props }) {
    // console.log(data)
    const history = useHistory()

    const expired = () => {
        return new Date(moment.utc(data.closingTime || data.e_date).local()) > new Date()
    }

    return (
        <Box sx={{ px: props.children ? 0 : 2 }}>
            {data.organiser ? <><span className="font-bold">Organisers:</span> <span dangerouslySetInnerHTML={{ __html: data.organiser }} /></> : ''}
            {data.sponsor ? <p style={{ marginTop: '10px' }}><span className="font-bold">Sponsors:</span> <span dangerouslySetInnerHTML={{ __html: data.sponsor }} /></p> : ''}
            {data.loc || data.eventLocation ? <p style={{ marginTop: '10px' }}><span className="font-bold">Location:</span> <span>{data.loc || data.eventLocation}</span></p> : ''}
            {(data.preregistration || data.pre_reg) && data.link ? <p style={{ marginTop: '10px' }}><a className="add-cover cursor" target="_blank" href={data.registrationForm || data.reg_form}>Registration Link</a></p> : ''}
            {console.log(data, "docfeed")}
            {data.link ? <p style={{ marginTop: '10px' }}> <a href={data.link} target="_blank" className="add-cover cursor">Meeting Link</a></p> : ''}
            <Box sx={{ boxShadow: '0 3px 5px lightgrey' }}>
                <div style={{ position: 'relative' }}>
                    {!image && data.eventImage && data.eventImage.length > 0 ?
                        <div style={{ maxHeight: '300px', overflow: 'hidden' }} className="cursor" onClick={open ? () => open() : () => { }}>
                            <img src={data.eventImage} alt="Event Image" style={{
                                maxHeight: '100%', maxWidth: '100%', objectFit: 'cover', width: '100%',
                            }} />
                            <div style={{ backgroundColor: 'rgb(228 238 245 / 85%)', width: '100%', position: 'absolute', bottom: 0 }} className="newcontact-fieldheader">

                                {/* {sub ? <Button className="evnt-btn add-option" sx={{pt: 0}} variant="outlined">Subscribed</Button> : */}
                                <div className="overflow_hidden" style={{ padding: '5px' }}>
                                    <Typography className="d-flex e-align color_head font-bold cursor capitalize" onClick={() => data.link ? console.log('') : data.id && history.push(`/webinar/eventdata-${data.id}`)}>
                                        <img src={event_icon} width='20px' height={'20px'} />&nbsp;<span className="text-ellipsis">{data.title || data.eventTitle}</span>
                                    </Typography>
                                    <Typography sx={{ mt: '2px' }}>{moment(data.openingTime).format('DD/MM/YYYY')} - {moment(data.e_date || data.closingTime).format('DD/MM/YYYY')}</Typography>
                                </div>
                                {/* <Typography component="div"><p className="text-right"><Button className="color-head add-option" sx={{ backgroundColor: 'white !important' }} variant="outlined">Attend</Button></p></Typography> */}
                                {/* } */}

                                {expired() ?
                                    data.eventAdmin != UserService.getUserId() && data.link ? (data.registrationForm || data.reg_form) ? <AttendBtn text="Attend" fun={() => window.open(data.registrationForm || data.reg_form, '_blank')} /> : '' : props.children
                                    : data.eventAdmin != UserService.getUserId() && <AttendBtn text="Completed" fun={() => { }} variant="outlined" />}
                            </div>

                        </div> :
                        <div style={{ maxHeight: '300px', overflow: 'hidden' }} className="cursor" onClick={open ? () => open() : () => { }}>
                            <img src="https://as2.ftcdn.net/v2/jpg/04/99/93/31/1000_F_499933117_ZAUBfv3P1HEOsZDrnkbNCt4jc3AodArl.jpg" alt="Event Image" style={{
                                height: '50%', width: '50%', objectFit: 'cover', marginLeft: "24%"
                            }} />
                            <div style={{ backgroundColor: 'rgb(228 238 245 / 85%)', width: '100%', position: 'absolute', bottom: 0 }} className="newcontact-fieldheader">

                                {/* {sub ? <Button className="evnt-btn add-option" sx={{pt: 0}} variant="outlined">Subscribed</Button> : */}
                                <div className="overflow_hidden" style={{ padding: '5px' }}>
                                    <Typography className="d-flex e-align color_head font-bold cursor capitalize" onClick={() => data.link ? console.log('') : data.id && history.push(`/webinar/eventdata-${data.id}`)}>
                                        <img src={event_icon} width='20px' height={'20px'} />&nbsp;<span className="text-ellipsis">{data.title || data.eventTitle}</span>
                                    </Typography>
                                    <Typography sx={{ mt: '2px' }}>{moment(data.openingTime).format('DD/MM/YYYY')} - {moment(data.e_date || data.closingTime).format('DD/MM/YYYY')}</Typography>
                                </div>
                                {/* <Typography component="div"><p className="text-right"><Button className="color-head add-option" sx={{ backgroundColor: 'white !important' }} variant="outlined">Attend</Button></p></Typography> */}
                                {/* } */}

                                {expired() ?
                                    data.eventAdmin != UserService.getUserId() && data.link ? (data.registrationForm || data.reg_form) ? <AttendBtn text="Attend" fun={() => window.open(data.registrationForm || data.reg_form, '_blank')} /> : '' : props.children
                                    : data.eventAdmin != UserService.getUserId() && <AttendBtn text="Completed" fun={() => { }} variant="outlined" />}
                            </div>

                        </div>
                    }
                </div>

            </Box>
        </Box>
    )
}