class SuperscriptTool {
    constructor({ api }) {
        this.api = api;
    }

    // This function renders the superscript button in the toolbar
    render() {
        const button = document.createElement('button');
        button.classList.add('editorjs-button');
        button.innerHTML = 'X₂'; // The button text
        button.addEventListener('click', () => this.handleClick());
        return button;
    }


    handleClick() {
        const selection = window.getSelection();
        if (selection.rangeCount === 0) return;

        const range = selection.getRangeAt(0); // Get selected text range

        if (range.toString().length > 0) {
            const superscriptNode = document.createElement('sup');
            superscriptNode.textContent = range.toString(); // Wrap selected text in <sup> tag

            range.deleteContents(); // Remove the selected text
            range.insertNode(superscriptNode); // Insert the superscript node
        }
    }


    save(blockContent) {
        return {
            text: blockContent.innerHTML, // Save HTML content, including <sup> tag
        };
    }
}

export default SuperscriptTool;
