const api = `${process.env.REACT_APP_API_URL}v1/`
const elibrary = `${process.env.REACT_APP_API_ELIB_URL}`
const pyapi = `${process.env.REACT_APP_API_URL_PYI}`
const analytics = process.env.REACT_APP_ANALYTICS
const razorpay = `${process.env.REACT_APP_RAZORPAY}`
const webmasterapi = `${process.env.REACT_APP_WEBMASTER}`
const URL = {

    // generateToken
    generateToken: `${process.env.REACT_APP_API_URL}v1/generateTokenForChat/`,

    // Registration
    signupSendOTP: `${process.env.REACT_APP_API_URL}v1/addOTP`,
    verifyOTP: `${process.env.REACT_APP_API_URL}v1/verifyOTP`,
    verifyUserName: `${process.env.REACT_APP_API_URL}v1/findPeopleByUserName/`,
    verifyMobilenumber: `${process.env.REACT_APP_API_URL}v1/findPeopleByPhone/`,
    verifyemailId: `${process.env.REACT_APP_API_URL}v1/findPeopleByEmail/`,
    registerUser: `${process.env.REACT_APP_API_URL}v1/addUser`,
    sendEmailOTP: `${api}sendOtpForEmail`,
    verifyEmail: `${api}verifyEmail`,

    forgotUsername: `${process.env.REACT_APP_API_URL_OTP}send_otp_to_forgotusername`,
    verifyForgotUsername: `${process.env.REACT_APP_API_URL_OTP}verifyOtp`,
    findPeoplebyPhoneOrEmail: `${api}findUsersByPhoneNumberOrEmail`,
    sendUsernametoUser: `${process.env.REACT_APP_API_URL_OTP}send_user_name_to_user`,
    sendPushNotification: `${process.env.REACT_APP_API_URL_OTP}send_push_notification`,

    // http://127.0.0.1:8000/notify/send_user_name_to_user
    // user Api No Token (public)
    getFindUser: `${api}getUserData`,

    // Search ApI 
    searchUsers: `${elibrary}search`,
    elib_search: `${elibrary}search_epractise`,
    search_diseases: `${elibrary}search_diseases`,
    search_drugs: `${elibrary}search_drugs`,
    searchCollege: `${elibrary}search_colleges`,

    //searchLocation
    searchLocation: `${elibrary}search_location`,
    getAllState: `${elibrary}get-allstatenames`,
    getAllDistrict: `${elibrary}get-alldistricts-by-statename`,
    getAllSubDistrict: `${elibrary}get-allsubdistricts-by-districtname`,
    getAllVillage: `${elibrary}get-allvillages-by-subdistrictname`,


    // User API
    findUser: `${api}findAllUserDataByUserId`,
    findAllUsers: `${api}findAllUser`,
    findUserById: `${api}User`,
    updateUser: `${api}updateUserById`,
    blockUser: `${api}addUsersToBlockedList`,
    unblockUser: `${api}removeUsersFromBlockList`,

    // accomplishments API
    accomplishments: `${api}findAccomplishmentByUserId`,
    addAccomplishment: `${api}addAccomplishment`,
    updateAccomplishment: `${api}updateAccomplishmentById`,
    deleteAccomplishment: `${api}deleteAccomplishmentById`,

    // experience API
    addExperience: `${api}addExperience`,
    updateExperience: `${api}updateExperienceById`,
    deleteExperience: `${api}deleteExperienceById`,

    //DragnDrop
    dragDrop: `${api}updateRecordsOrder`,

    // GET All Organisations API
    findAllOrganisations: `${api}findAllOrganisation`,
    addOrganisation: `${api}addOrganisation`,

    //Connect API
    requestPending: `${api}findRequestPendings`,
    addRequest: `${api}addRequest`,
    acceptRequest: `${api}updateRequestById`,
    deleteRequestById: `${api}deleteRequestById`,
    deletePeersById: `${api}deletePeersById`,
    deletePeersByUser: `${api}deletePeers`,
    sentRequests: `${api}findRequestSent`,
    getCounts: `${api}getConnectPageCounts`,
    getFollowing: `${api}getNetworkFollowingsByUserId`,
    getFollowers: `${api}getNetworkFollowersByUserId`,
    getPeers: `${api}findFriendsByUserId`,
    getPages: `${api}getPageFollowingsByUserId`,
    getBlockedList: `${api}getBlockedUsersByUserId`,

    // chatChannel API
    creatingChannelForChatService: `${api}creatingChannelForChatService`,
    findTwilioChannelByUserId: `${api}findTwilioChannelByUserId`,

    //suggestedPeople
    suggestedPeople: `${api}findSuggestedPeopleById`,
    getSuggestedById: `${api}findFewSuggestions`,
    // suggestedPeople: `http://127.0.0.1:8000/elib/suggestedPeople`,

    // suggestedOrganization
    suggestedOrganization: `${api}findSuggestedPagesByUserId`,

    // suggestedOrganization : `http://127.0.0.1:8000/elib/suggestedPages`,
    // suggestedTopics
    // suggestedTopics: `http://127.0.0.1:8000/elib/`,
    suggestedTopics: `${pyapi}/`,
    followHashtag: `${elibrary}followHashtags/`,
    getFollowHashtags: `${elibrary}userHashtags`,
    mutualHashtags: `${elibrary}mutualHashtags`,

    // peer list get api
    findPeersDataByUserId: `${api}findPeersDataByUserId`,

    //usermentions
    mentions: `${api}userMentions`,

    //Follow API
    addNetwork: `${api}addNetwork`,
    followers: `${api}findFollowRequest`,
    deleteNetworkById: `${api}deleteNetworkById`,

    //Follow organisation
    followOrganisation: `${api}addPageFollower`,
    orgFollowers: `${api}PagesByIdWithoutKeycloak`,
    removePageFollower: `${api}removePageFollower`,

    //FileUpload
    uploadFile: `${api}upload/file`,
    upload: `${pyapi}/upload`,

    //feed
    feed: `${pyapi}/userfeed`,
    // feed: `${pyapi}/feed/?limit=15&offset=0`,
    // feed: `${process.env.REACT_APP_API_ELIB_URL}feed`,
    // feed: `http://127.0.0.1:8000/elib/feed`,

    //elibrary
    elib: `${process.env.REACT_APP_API_ELIB_URL}`,

    //drugSpecialities
    dSpecialities: `${elibrary}allDrugSpecialities/`,
    allDrugs: `${elibrary}allDrugs/`,
    allClasses: `${elibrary}getClassWiseDrugs/`,
    getArticles: `${elibrary}drugArticlesData/`,
    getSummary: `${elibrary}getDrugSummaryData/`,
    getClasses: `${elibrary}getDrugClasses/`,
    getDrugSummary: `${elibrary}getDrugSummaryData/`,

    //Posts
    addPost: `${pyapi}/addpost`,
    getPosts: `${pyapi}/postsbyuserid`,
    postById: `${pyapi}/post`,
    deletePost: `${pyapi}/deletepostbyid`,
    updatePost: `${pyapi}/updatepostbyid`,
    sharePost: `${pyapi}/sharepost`,
    displayPost: `${pyapi}/postwithouttoken`,

    // articles

    addArticle: `${pyapi}/addarticle`,
    getdraftArticles: `${pyapi}/getdraftarticles/`,
    deleteSaveDraft: `${pyapi}/deletearticle/`,
    updateArticle: `${pyapi}/updatearticle/`,
    getParticularArticleId: `${pyapi}/article/`,

    //  Get feed without token
    getFeedByPageIdByWithoutToken: `${pyapi}/getpostbypageid/`,

    //Poll
    addPoll: `${pyapi}/addpollresponse`,
    deleteresponse: `${pyapi}/deletepollresponsebyid`,

    //Add to subscribers
    addSubscriber: `${pyapi}/addeventsubcriber`,

    //Collection
    addCollection: `${pyapi}/addcollection`,
    deleteCollection: `${pyapi}/deletecollectionbyid`,

    //Likes
    addLikes: `${pyapi}/addlikes`,
    deleteLikes: `${pyapi}/deletelikesbyid`,
    postUsers: `${api}postLikeUsers`,

    //comments
    addComment: `${pyapi}/addcomment`,
    deleteComment: `${pyapi}/deletecommentbyid`,
    getCommentByPost: `${api}CommentByPost`,
    getCommentById: `${api}Comment`,

    //getPagesByName
    getPagesByName: `${api}getPagesByName`,
    findAllUserDataByUserId: `${api}findAllUserDataByUserId/{userId}`,

    // organisation page feed
    findPostByPageId: `${pyapi}/postbypageid`,

    // organisation page feed without keycloak
    findPostByPageIdWithoutKeycloak: `${pyapi}/postbypageidwithouttoken/`,

    //userfeed without token
    findUserPost: `${pyapi}/postsbyuseridwithouttoken/`,
    getPageById: `${api}PagesById`,
    updatePageById: `${api}updatePagesById`,

    //getNotifications
    getNotifications: `${api}findNotificationByUserId`,
    updateNotificationByUserId: `${api}updateNotificationByUserId`,
    updateNotificationById: `${api}updateNotificationById`,

    //Apps
    findAllApps: api + "findAllApps",
    findAppById: api + "findAppsById/",
    suggestedApps: api + "suggestedAppsByUserId/",
    getAppsByVault: api + "getAppsByCategory/",
    searchApps: api + 'searchAppsByName',
    getAppsByAvailability: api + 'getAppsByAvailability',
    findAppsByUserId: api + 'findAppsByUserId/',
    subscribeToApp: api + 'subscribeToApp/',

    //Hashtag
    findhashtag: `${pyapi}/hashtagposts`,

    //Questionnaires
    getQuestionnaire: `${api}findAllQuestionnaires`,
    getQuestionnaireById: `${api}findQuestionnaireById`,
    getQuestionnaireByType: `${api}findQuestionnaireByType`,
    getQuestionnaireByStatus: `${api}findActiveQuestionnaires/Type`,
    submitQuestionnaire: `${elibrary}calculator`,
    submitTestFlow: `${elibrary}test_flow`,

    //document
    getdocument: `${api}getDocumentByUserId`,
    getdocumentbypage: `${api}getDocumentByPageId`,

    // pages without token
    getpagewithouttoken: `${api}PagesByIdWithoutKeycloak`,
    findPeopleByPageIdWithoutToken: `${api}findPeopleByPageIdWithoutToken`,
    PageFollowersByPageIdWithoutToken: `${api}findPageFollowersByPageIdWithoutToken`,
    getbyPageId: `${process.env.REACT_APP_CLINI}Organization/without_token/identifier/pageId/`,
    getDocstethPage: `https://dev.api.docsteth.com/api/v1/PagesByIdWithoutKeycloak/`,

    // page request
    pagerequests: `${api}findPendingRecordsByPageId`,
    acceptpagepeople: `${api}updatePendingRecordsById`,
    findPeopleByPageId: `${api}findPeopleByPageId`,
    followersbypageId: `${api}findPageFollowersByPageId`,

    //events
    Eventsbypageid: `${api}findUpcomingEventsByPageId`,
    Eventsbypageidwithouttoken: `${api}findUpcomingEventsByPageIdWithoutToken`,

    //publication
    getpublicationbyuserid: `${api}findPublicationByUserId`,
    getpublicationwithoutkeyloak: `${api}findPublicationByUserIdWithoutToken`,
    findArticleByUserId: `${api}findArticleByUserId`,

    //Bookmarkpost
    getpostcollections: `${api}collection`,
    getcollectionsbyuserid: `${pyapi}/getcollectionbyuserid`,
    getbookmarkcount: `${pyapi}/bookmarkcount`,

    //MedScope Page
    getlistofjobs: `${api}hr/findlistOfJobs`,
    getlistofevents: `${pyapi}/all_upcoming_events`,

    //linkAccounttoken
    linkedInToken: api + 'getLinkedinAccessToken',
    twitterToken: api + 'getTwitterAccessToken',

    //linkAccount
    linkAccount: api + 'linkAccount',
    getLinkedAccounts: api + "linkedAccountsByUserId/",
    deleteAccount: api + "deleteLinkedAccount/",
    updateAccount: api + 'updateLinkedAccount',

    //link-accounts-post
    twitterPost: api + 'shareOnTwitter',
    linkedInPost: api + 'shareOnLinkedin',

    //post impressions
    addImpression: analytics + 'add-impressions',
    getImpression: analytics + 'get-impressions/',
    allImpressions: analytics + 'get-all-impressions',

    //post interactions
    addInteraction: analytics + 'add-interactions',
    getInteraction: analytics + 'get-interactions/',

    // razorpay payment (app subscription)
    createRazorpayOrder: `${razorpay}createOrder`,
    verifyPayment: `${razorpay}verifyOrder`,
    transactionCreate: `${razorpay}transactionCreate`,
    billingDetail: `${razorpay}billing-details-create`,
    confirmDetail: `${razorpay}confirm-details-create`,
    getBillingDetail: `${razorpay}billing-details-retrieve`,
    // https://devpayments.docsteth.com/payments/billing-details-retrieve/1425645486


    //calculator search
    searchCalculator: `${api}searchCalculators`,

    // feed/updatecachefeed (caching)
    updateChacheFeed: `${pyapi}/updatecachefeed`,

    // webmaster api's
    deleteWebsite: `${webmasterapi}removerecordinroute53andcloudfront/`,

    //upload galary..
    addGallery: `${pyapi}/addGallery`,
    getGalleryDetailsByLocationId: `${pyapi}/getGallery?location=`,
    getGalleryDetailsByHealthServiceId: `${pyapi}/getGallery?healthcareid=`,
    deleteGalleryItem: `${pyapi}/deleteGallery/`,



}

export default URL

