import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Box, Button, Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, Grid, IconButton, Modal, Paper, Snackbar, Tab, Tabs, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PublicIcon from '@mui/icons-material/Public';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useSelector } from 'react-redux';
import { FiEdit3 } from 'react-icons/fi'
import EditProfile from './editProfile';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { URI } from '../../../../utility/uri';
import { D_API } from '../../../../../services/APIService';
import CompareArrowsSharpIcon from '@mui/icons-material/CompareArrowsSharp';
import { Edit, Error, VerifiedUser } from '@mui/icons-material';
import { deleteKeys } from '../../../../common/functions/functions';
import { useDispatch } from 'react-redux';
import { fetchSubOrganization, getFacilities, getIsAdmin, getLocation, getUserOrg, handleUserOrg, headers } from '../../../../../redux/Actions/regActions';
import Services from '../Services';
import PeopleOrg from '../PeopleOrg';
import { useHistory, useParams } from 'react-router-dom';
import { FacilityKYC, HFRDialog } from '../../../../common/components/dialogs';
import './organisation.css'
import AdminTimeline from './adminTimeline';
import OverView from './overview'
import { getPageById } from '../../../../../redux/Actions/actions';
import edithub from '../../../../../images/primaryEdit.svg'
import OrgLogo from '../../../../../images/orglogo.svg'
import QRcode from 'qrcode.react'
import Feed from '../../../../Molecule/Feed/Feed';
import orgcoverpic from '../../../../../icons/orgcoverpic.svg'
import axios from 'axios';
import URL from '../../../../apiurl';
import UserService from '../../../../../services/UserService';
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AdminPublicationNew from '../amenities/adminPublicationNew';
import HealthDeptInfo from "../AdminOrganization/HealthCareDept/particularHealthDeptInfo";
import HealthServiceInfo from "../HealthServicesDept/particularHealthService";

import moment from "moment";
import org from "../../../../../images/Group 13144.svg";
import mail from "../../../../../images/mail.svg";
import AddWebsite from "../amenities/AddWebsite";
import call from "../../../../../images/call.svg";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'
import setCanvasPreview from '../../../../../setCanvasPreview';
import Appiontment from './appiontment';
import HospitalLocationDetails from './hospitallocationdetails';
import LinkAccountOrg from './LinkAccountOrg';
import FacilityDetails from './hospitalsFacilityDetails/facilityDetails';
import { createContext, useContext } from 'react';
import Departments from './HealthCareDept/department';
import OnlyToUploadOrgKYCDocs from './onlyToUploadOrgDoc';
// import { LinkAccountOrg } from '../../../LinkAccount/linkaccount';
// const location = useSelector((state) => state.registration.location);
// import AddWebsite from "../amenities/AddWebsite";

//  const addWebsite = () => {
//    setOpenWebsite(!openWebsite);
//  };
// const [timings, setTimings] = useState({});
// const [activeDay, setActiveDay] = useState(null);
// const [WorkingDays, setWorkingDays] = useState([]);
// const [openWebsite, setOpenWebsite] = useState(false);


// let isToday = days[d.getDay() - 1];

// useEffect(() => {
//   const date = new Date();
//   const today = days[date.getDay() - 1];

//   WorkingDays?.find((item) => {
//     if (item.day == today) {
//       // console.log(item)
//       setTimings(item);
//     }
//   });
// }, [WorkingDays]);

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const MIN_DIMENSION = 144
const ASPECT_RATIO = 1

const LGrid = ({ value }) => {
  return (
    <Grid item sm={4} xs={12} sx={{ opacity: '80%' }}>{value}</Grid>
  )
}

const RGrid = ({ value }) => {
  return (
    <Grid item sm={8} xs={12}>{value}</Grid>
  )
}

const SideTags = ({ Icon, title, className, data }) => {
  return (
    // <div className='flx mv1'>
    <div style={{ display: 'flex', gap: "7px", margin: "10px 0" }}>
      <span style={{ flex: 0.11 }}>{Icon} </span>
      <span style={{ flex: 1 }} className={className}>
        {title ? <>
          {title}
          <br />
          <span style={{ fontSize: '14px' }}>{data}</span>
        </>
          : data}
      </span>
    </div>
  )
}

const links = (text) => {
  return (
    text ? <a href={text} target="_blank" className='text_blue'>{text}</a> : 'N/A'
  )
}

const Imgs = (props) => {
  return <img width='24px' {...props} />
}

const weeks = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
// console.log(days.length, "lineno116");
// const days = [
//   "sunday",
//   "monday",
//   "tuesday",
//   "wednesday",
//   "thursday",
//   "friday",
//   "saturday",
// ];
const daysCompare = { mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6, sun: 7 };

const Test = () => {
  return (
    <div>
      hello
    </div>
  )
}



export const ContextInfo = createContext();
const AdminOrganizationNew = () => {
  const [cover, setCover] = useState('')
  const [editOrg, setEditOrg] = useState(false)
  const [openVerify, setOpenVerify] = useState(false)
  const [openHFR, setOpenHFR] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [openAbout, setOpenAbout] = useState(false)
  const [about, setAbout] = useState('')
  const [orgData, setOrgData] = useState({})
  const [qr, setQr] = useState(null);
  // console.log(orgData, "datttttttttttt")
  const [timings, setTimings] = useState({});
  const [activeDay, setActiveDay] = useState(null);
  const [WorkingDays, setWorkingDays] = useState([]);
  const [openWebsite, setOpenWebsite] = useState(false);
  const [url, setUrl] = useState('')
  const [uploadLoader, setUploadLoader] = useState({ open: false, msg: '' })

  const [alert, setAlert] = useState({ open: false, msg: '' })
  const [error, setError] = useState('')
  const [opentoUploadKycDocs, setOpentoUploadKycDocs] = useState(false);



  const [servicesList, setServicesList] = useState([]);
  // console.log(servicesList, 'servicesList')

  const [docstethPageData, setDocstethPageData] = useState([])
  const [fromGetApiImages, setFromGetApiImages] = useState([])
  // console.log(docstethPageData, "docstethPageData")

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [crop, setCrop] = useState()
  const [showHealthDeptComp, setShowHealthDeptComp] = useState(false);
  const [isAdmin, setIsAdmin] = useState(null);

  const imageRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const croppedImgRef = useRef(null);
  const [tab, setTab] = useState("1"); // Default to "Overview" tab
  const [selectedDept, setSelectedDept] = useState(null); // To store the selected department
  const [selectedServ, setSelectedServ] = useState(null);

  // When department is selected in Overview, update selectedDept and switch to "Department" tab
  const handleSelectDepartment = (department) => {
    setSelectedDept(department);
    setTab("2"); // Switch to "Department" tab
  };
  const handleSelectService = (service) => {
    setSelectedServ(service);
    setTab("4"); // Switch to "Department" tab
  };


  const onImageLoad = (e) => {
    const { width, height, naturalHeight, naturalWidth } = e.currentTarget
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    if (naturalHeight < MIN_DIMENSION || naturalWidth < MIN_DIMENSION) {
      setError('Image must be atleast 144 x 144 pixels')
      setUrl('')
      return
    }
    const crop = makeAspectCrop({
      unit: "%",
      width: cropWidthInPercent,
    }, ASPECT_RATIO, width, height)
    const centeredCrop = centerCrop(crop, width, height)
    setCrop(centeredCrop)
    setError('')
  }

  const updateAvatar = (url) => {
    croppedImgRef.current = url
  }

  const userOrg = useSelector(state => state.registration.userOrg)
  const location = useSelector(state => state.registration.location);
  const checkIsAdmin = useSelector(state => state.registration?.isAdmin);

  console.log(location, "location220")

  console.log(userOrg, "userOrg")

  console.log(orgData, "orgData,,,")
  // const state = useSelector(state => state.userProfile.userProfile);
  // const pages = useSelector(state => state.userProfile.pages);
  // console.log(pages, "pages")
  // console.log(state, "state")


  const addWebsite = () => {
    // console.log("lineno149")
    setOpenWebsite(!openWebsite);
  };

  // console.log(userOrg, "user org")
  // console.log(location, "location here")
  // console.log(servicesList, "servicesList here")

  const { pageData } = useSelector(state => state.userProfile)

  const history = useHistory();
  console.log(history, "history");
  let curPath = history.location.pathname;
  const dispatch = useDispatch()

  const d = new Date();
  let isToday = days[d.getDay() - 1];
  console.log(isToday, "isToday")

  const { id, ibmId } = useParams()

  const listofPages = async () => {
    return await axios.get(`${URL.findUser}/${UserService.getUserId()}`, headers())
      .then(res => {
        return res.data.data.pages
      }).catch(err => {
        // console.log(err, '---------------<<<<<<<<<')
      })
  }

  // console.log(curPath, "curPath")

  // useEffect(async () => {


  //   if (id) {
  //     const AllPagesId = await listofPages()
  //     const isValid = AllPagesId.some((item) => item.id == id)
  //     // console.log(isValid, "isllll")
  //     if (isValid) {
  //       getPageDetails()
  //     } else if (curPath === `/admin/organization/${id}/healthDepartment` || curPath === `/admin/organization/${id}/healthService`) {

  //       if (curPath == `/admin/organization/${id}/healthDepartment`) {
  //         console.log("gh11")
  //         D_API.get(`${URI.organization}/${userOrg?.id}`).then((res) => {
  //           console.log(res.data);
  //           let valId = res.data?.identifier?.filter((e) => e["system"] == "https://pageId/" && e["value"] != null || undefined || "")?.[0]?.value;
  //           if (valId) {
  //             // console.log(valId, "eee")
  //             getDetailsByPageId(valId)
  //             D_API.get(`Organization/identifier/prov/pageId/${Number(valId)}`).then(res => {
  //               // console.log(res, "rrrrrrr")
  //               dispatch(getUserOrg(res?.data?.entry?.[0]?.resource))
  //               setOrgData(res?.data?.entry?.[0]?.resource)
  //             }).catch((err) => console.log(err))
  //           }
  //         }).catch((err) => console.log(err))
  //       }
  //       else if (curPath == `/admin/organization/${id}/healthService`) {
  //         D_API.get(`${URI.organization}/${userOrg?.id}`).then((res) => {
  //           console.log(res.data);
  //           let valId = res.data?.identifier?.filter((e) => e["system"] == "https://pageId/" && e["value"] != null || undefined || "")?.[0]?.value;
  //           if (valId) {
  //             // console.log(valId, "eee")
  //             getDetailsByPageId(valId)
  //             D_API.get(`Organization/identifier/prov/pageId/${Number(valId)}`).then(res => {
  //               // console.log(res, "rrrrrrr")
  //               dispatch(getUserOrg(res?.data?.entry?.[0]?.resource))
  //               setOrgData(res?.data?.entry?.[0]?.resource)
  //             }).catch((err) => console.log(err))
  //           }
  //         }).catch((err) => console.log(err))
  //       }

  //     } else {
  //       history.push("/")
  //     }

  //   }


  // }, [id, userOrg?.id])

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const allPagesId = await listofPages();
        console.log(allPagesId, "allPagesId")
        const isValid = allPagesId?.some(item => item?.id == id);

        console.log(isValid, "isValid")

        if (isValid) {
          getPageDetails();
        } else if (curPath === `/admin/organization/${id}/healthDepartment` || curPath === `/admin/organization/${id}/healthService`) {
          const fetchDetails = async () => {
            // if (userOrg?.id) {
              try {

                const res = await D_API.get(`${userOrg?.id}/${URI.organization}/${userOrg?.id}`);
                const valId = res.data?.identifier?.find(e => e.system === "https://pageId/" && e.value)?.value;

                if (valId) {
                  await getDetailsByPageId(valId);
                  const orgRes = await D_API.get(`${userOrg?.id}/Organization/identifier/prov/pageId/${Number(valId)}`);
                  dispatch(getUserOrg(orgRes?.data?.entry?.[0]?.resource));
                  setOrgData(orgRes?.data?.entry?.[0]?.resource);
                }
              } catch (error) {
                // console.error("Error fetching data:", error);
              }
            // }
          };

          if (curPath === `/admin/organization/${id}/healthDepartment` || curPath === `/admin/organization/${id}/healthService`) {
            fetchDetails();
          }
        } else {
          // history.push("/");
        }
      }

    };

    fetchData();
  }, [id, userOrg?.id]);



  // to test user is admin or not 


  // console.log(isAdmin, "isAdmin")



  // test case 

  useEffect(() => {

    if (curPath === `/admin/organization/${id}`) {
      // D_API.get(`${URI.getOrganizationByPageId}pageId/${id}`).then((res) => {
      //   let orgdataInfo = res.data?.entry?.[0];
      //   if (orgdataInfo?.id) {
      //     getsubOrgDetails(orgdataInfo?.id)
      //   } else {
      //     dispatch(fetchSubOrganization([]));
      //   }
      //   // console.log(res.data?.entry?.[0], "vuppula")
      // }).catch((err) => console.log(err))

    }
  }, [curPath, id])



  useEffect(() => {
    if (location) {
      let data = [];
      const date = new Date();
      const today = days[date.getDay() - 1];
      // console.log(today, "today")
      {
        location?.hoursOfOperation?.map((item, i) => {
          let openingTime = item.openingTime;
          let closingTime = item.closingTime;
          if (item.allDay == true) {
            item?.daysOfWeek?.map((child) => {
              data.push({
                day: child,
                allDay: item.allDay,
                today: child == today ? true : false,
              });
            });
          } else if (item.allDay == false) {
            item.daysOfWeek?.map((child) => {
              data.push({
                day: child,
                allDay: item.allDay,
                today: child == today ? true : false,
              });
            });
          } else {
            item.daysOfWeek?.map((child) => {
              data.push({
                day: child,
                workingHours: [
                  { openingTime: openingTime, closingTime: closingTime },
                ],
                today: child == today ? true : false,
              });
            });
          }
        });
      }


      const mergedSchedule = [];

      for (let i = 0; i < data.length; i++) {
        let day = data[i].day;
        let hours = data[i].workingHours;

        let existingDay = mergedSchedule?.find((item) => item.day === day);
        // console.log(existingDay, "existingDay")
        if (existingDay) {
          existingDay.workingHours = existingDay.workingHours?.concat(hours);
          // console.log(existingDay, "existingDay11")
        } else {
          mergedSchedule.push(data[i]);
        }
      }
      console.log(mergedSchedule, "mergedSchedule,258");

      const sorted = mergedSchedule.sort((a, b) => {
        return daysCompare[a.day] - daysCompare[b.day];
      });
      // console.log(sorted, "sorted");
      setWorkingDays(sorted);
    }
  }, [location?.id]);

  // console.log(WorkingDays, "..........");


  //  useEffect(() => {
  //   console.log("lineno267");
  //    const date = new Date();
  //    const today = days[date.getDay() - 1];
  //    WorkingDays?.find((item) => {
  //      if (item.day == today) {
  //        console.log(item)
  //        setTimings(item);
  //      }
  //    });
  //  }, [WorkingDays]);

  useEffect(() => {
    const date = new Date();
    const today = days[date.getDay() - 1];
    WorkingDays?.find((item) => {
      if (item.day == today) {
        console.log(item)
        setTimings(item);
      }
    });
  }, [WorkingDays]);


  useEffect(() => {
    if (location?.managingOrganization?.reference?.split("/")?.[1]) {

      D_API.get(`${userOrg?.id}/${URI.organization}/${location?.managingOrganization?.reference?.split("/")?.[1]}`).then((res) => {
        dispatch(getUserOrg(res.data));
        // setOrgData(res?.data)
      }).catch((err) => console.log(err))


    }
  }, [location?.id])


  const handleShowHealthDeptComp = () => {
    setShowHealthDeptComp((prev) => !prev);
  }



  const getPageDetails = () => {

    const baseurl1 = `${process.env.REACT_APP_API_URL}v1/PagesById/${id}`
    axios.get(baseurl1,
      { headers: { Authorization: `Bearer ${sessionStorage.getItem('kctoken')}` } }
    ).then((res) => {
      console.log("8080")
      console.log(res.data, "asdfghjkl")
      setDocstethPageData(res.data?.data)
      setFromGetApiImages(res.data?.data[0]?.page?.coverPhoto?.split(","))
    }).catch(err => {
      // console.log(err)
    }
    )
  }


  const getDetailsByPageId = (val) => {
    // https://devfhir.docsteth.com/auth/v1/Organization/identifier/prov/pageId/481
    const baseurl1 = `${process.env.REACT_APP_API_URL}v1/PagesById/${val}`
    axios.get(baseurl1,
      { headers: { Authorization: `Bearer ${sessionStorage.getItem('kctoken')}` } }
    ).then((res) => {
      // console.log(res.data, "asdfghjkl")
      setDocstethPageData(res.data?.data)
      setFromGetApiImages(res.data?.data[0]?.page?.coverPhoto?.split(","))
    }).catch(err => {
      // console.log(err)
    }
    )
  }

  // console.log(docstethPageData, "docstethPageData")
  // console.log(timings, "timings");
  // const [coverPhoto, setCoverPhoto] = useState({})
  const [coverPhoto, setCoverPhoto] = useState([])
  const [allImgCount, setAllImgCount] = useState(null)
  const [uploadedFile, setUploadedFile] = useState([])
  const [toUpdateKey, setToUpdateKey] = useState("")
  // console.log(coverPhoto)

  // console.log(setAllImgCount, "888888");
  const uploadPhotoOrg = (e, fileType, state, name) => {
    setAllImgCount(e.target.files.length)
    if (e.target.files.length > 0) {
      if (e.target.files.length < 6) {
        let a = []
        let b = []
        let c = []
        for (let i = 0; i < e.target.files.length; i++) {
          let types = e.target.files[0].type.split("/")[1]
          const eTypes = types === 'jpg' || types === "png" || types == "jpeg"
          const imgTypes = eTypes || types == "gif"

          if (fileType == "photo") {
            const reader = new FileReader()
            // console.log(reader, "reader")
            reader.readAsDataURL(e.target.files[i])
            reader.onloadend = () => {
              a.push(reader.result)
              let base64 = reader.result.split(",")[1].split("=")[0]
              var strLength = base64.length;
              var fileLength = strLength - (strLength / 8) * 2;

              const [, type] = reader.result.split(";")[0].split('/');
              c.push(type)

              if (a.length || b.length || c.length === e.target.files.length) {
                setUrl(a)

              }
              // setCoverPhoto({ file: base64, fileType: type })
              setCoverPhoto((prev) => {
                return [...prev, { file: base64, fileType: type }]
              })

              fileUpload(base64, type, state == "coverpic" ? 'userCoverPhoto' : 'profilePic', name)
            }
          } else {
            setUploadLoader({ open: true, msg: 'Only .jpg, .png, .jpeg, .gif extensions allowed' })
          }
        }

      } else {
        setUploadLoader({ open: true, msg: "Can upload only upto 5 images" })

      }
    }

  }


  const fileUpload = (file, type, subType, name) => {

    setUploadLoader({ open: true, msg: "uploading ..." })

    let data = {
      "file": [file],
      "fileType": type,
      "multiple": false,
      "subType": subType,
      "type": "userProfilePics"
    }
    // console.log("lllll")
    // console.log(data, "helloo")

    axios.post(`${URL.uploadFile}`, data, {
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`
      }
    }).then(res => {
      // console.log(res.data.data[0])
      setUploadLoader({ open: false, msg: "uploaded successfully" })
      setUploadedFile((prev) => {
        return [...prev, res.data?.data?.[0]]
      })
      setToUpdateKey(name)


      updateOrg1(res.data?.data?.[0], name)
    }).catch(err => {
      setUploadLoader({ open: false, msg: "uploading failed, try again." })

    })
  }


  // console.log(uploadedFile, "uploadedFile")

  useEffect(() => {
    if (uploadedFile.length == allImgCount) {

      let url = uploadedFile.join(',')
      // console.log(url, "final", toUpdateKey)
      updateOrg1(uploadedFile, toUpdateKey)

    }
  }, [uploadedFile])

  const getUpdateFhirOrgIdentifier = (org, uploadFile, url) => {

    // console.log(org, uploadFile, url, "+++")

    if (!org || !Array.isArray(org?.identifier)) {
      return []
    }

    let list = [...org?.identifier]

    const existIn = list?.some((val) => val.system === url);
    // console.log(existIn, "existIn")

    if (existIn && uploadFile) {
      let indx = list?.findIndex((val) => val.system === url);
      if (indx != -1) {
        list[indx] = { system: url, value: uploadFile }
      }

    } else if (!existIn && uploadFile) {
      list.push({ system: url, value: uploadFile })
    }
    return list;

  }










  const updateOrg1 = (uploadedUrl, toupdatekey) => {
    let deepdata = JSON.parse(JSON.stringify(docstethPageData[0]?.page))
    console.log(deepdata)
    let newdata = { ...deepdata };

    // console.log(newdata, "before")

    // newdata.coverPhoto = `${uploadedUrl}`;
    newdata[toupdatekey] = `${uploadedUrl}`;

    console.log(newdata, "after")

    axios.put(`${URL.updatePageById}/${id}`, newdata, {
      headers: {
        'Authorization': `Bearer ${UserService.getToken()}`
      }
    }).then(res => {
      getPageDetails();
      if (userOrg?.id) {
        // console.log(userOrg, "@v")
        let data = {
          ...userOrg,
          identifier: getUpdateFhirOrgIdentifier(userOrg, uploadedUrl, `${process.env.REACT_APP_API_URL}v1/orgImg`)
        }
        if (data?.id) {
          D_API.put(`${data?.id}/${URI.updateOrg}${data?.id}`, data).then((res) => {
            dispatch(getUserOrg(data))
            dispatch(getFacilities(data?.id))
            if (data?.identifier?.[1]?.value) {
              D_API.get(`${data?.id}/Organization/identifier/pageId/${data?.identifier?.[1]?.value}`).then(res => {
                dispatch(handleUserOrg(res.data?.entry?.[0]?.resource))
                dispatch(getUserOrg(res?.data?.entry?.[0]?.resource))
              }).catch(err => {
                console.log(err)
              })
            }
          }).catch((err) => {
            console.log(err)
          })

        }

      }

    }
      // getPage('true');
      // setUploadedFile([])
      // setToUpdateKey("")
      // setDocstethPageData(res.data.data)
    ).catch(err => {
      // setAlert({ open: true, msg: 'Error while uploading...' })
      console.log("Error while uploading...")
    })
  }

  // useEffect(async () => {
  //   getCases()
  // }, [id]);

  // const getCases = async () => {
  //   if (id && curPath === `/admin/organization/${id}`) {
  //     try {
  //       const response = await D_API.get(`Organization/identifier/prov/pageId/${Number(id)}`);
  //       setOrgData(response?.data?.entry?.[0]?.resource);
  //       // dispatch(getUserOrg(response?.data?.entry?.[0]?.resource))
  //     } catch (error) {
  //       console.error("Error fetching organization data:", error);
  //     }
  //   }
  // }
  // console.log(id, "rrrrrrr", curPath)
  useEffect(() => {

    if (id && curPath == `/admin/organization/${id}`) {
      // console.log(id, "rrrrrrr", typeof id)
      D_API.get(`${userOrg?.id}/Organization/identifier/prov/pageId/${Number(id)}`).then(res => {
        // console.log(res, "rrrrrrr")
        setOrgData(res?.data?.entry?.[0]?.resource || {})
        dispatch(getUserOrg(res?.data?.entry?.[0]?.resource))
      }).catch((err) => console.log(err))


    }
  }, [id])

  useEffect(() => {
    // if (userOrg?.id) {
      dispatch(getLocation(userOrg?.id))
    // }
  }, [userOrg?.id])


  useEffect(() => {
    if (userOrg?.id) {
      // dispatch(getLocation(userOrg.id))
      // setOrgData(userOrg)
      // history.push(`/admin/organisation/${userOrg?.identifier?.[1]?.value}`)
    }
    if (userOrg?.identifier?.length === 4) {
      setQr(`https://phrsbx.abdm.gov.in/share-profile?hip-id=${userOrg?.identifier?.[3]?.value}&counter-id=54321`)
    } else {
      setQr(null)
    }
  }, [userOrg?.id])

  const getsubOrgDetails = (id) => {

    // if (userOrg?.id) {
      D_API.get(`${userOrg?.id}/${URI.getFacilitiesOfSubOrg}${id}&type=prov`).then((res) => {
        if (res.data?.entry?.length > 0) {
          let sortList = res.data?.entry?.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofsuborganization/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofsuborganization/")?.[0]?.value);
          dispatch(fetchSubOrganization(sortList));
        } else {
          dispatch(fetchSubOrganization([]));
        }
      }).catch((err) => console.log(err));
    // }


  }



  useEffect(() => {
    if (userOrg?.id) {
      D_API.get(`${userOrg?.id}/${URI.getPermissionForPractitionerRole}${userOrg?.id}`).then((res) => {
        dispatch({
          type: "IS_ADMIN",
          payload: res.data
        })
      }).catch((err) => {
        dispatch({
          type: "IS_ADMIN",
          payload: null
        })
        console.log(err)
      })

    } else {
      dispatch({
        type: "IS_ADMIN",
        payload: null
      })
    }



  }, [userOrg?.id])


  // useEffect(() => {

  //   if (userOrg?.id) {
  //     getsubOrgDetails(userOrg?.id)
  //   }



  // }, [userOrg?.id, tab])



  useEffect(() => {
    setAbout(location?.description || '')

  }, [location?.id])

  useEffect(() => {
    ValidatorForm.addValidationRule('allowedExtension', (value) => {
      if (!value.endsWith('.png') && !value.endsWith('.jpg') && !value.endsWith('jpeg') && !value.endsWith('.pdf')) {
        return false;
      }
      return true;
    });
  }, [])



  const handleTabs = (e, value) => {
    setTab(value)
  }


  const handleOpentoUploadKYCDocs = () => {
    setOpentoUploadKycDocs(true);
  }

  const handleClosetoUploadKYCDocs = () => {
    setOpentoUploadKycDocs(false);
  }



  const hadleEditOrg = () => {

    setEditOrg(prev => !prev)

    dispatch(handleUserOrg(orgData))
  }

  const handleVerify = () => {
    // setOpenVerify(prev => !prev)
    // dispatch(handleUserOrg(orgData))
  }

  const handleHFR = () => {
    setOpenHFR(prev => !prev)
  }

  const handleAbout = () => {
    let data = {
      ...location,
      description: about?.trimStart()?.trimEnd(),
    }

    // console.log(data)
    if (userOrg?.id) {
      D_API.put(`${userOrg?.id}/${URI.addLocation}/${data?.id}`, deleteKeys(data)).then(() => { setOpenAbout(false); dispatch(getLocation(orgData.id)) }).catch(() => { setOpenAbout(false); alert("Internal Server Error...Please Try after some Time") })
    }

  }

  const handleVerifyKyc = (data) => () => {
    setOpenVerify(prev => !prev)
    if (data.id) {
      dispatch(getUserOrg(data))
    }
  }


  console.log(opentoUploadKycDocs, "ttttttttttt")
  return (
    <Box id="services">

      {
        opentoUploadKycDocs && (<OnlyToUploadOrgKYCDocs closeFn={handleClosetoUploadKYCDocs} />)
      }
      {editOrg && (
        <EditProfile close={hadleEditOrg} edit={true} setOrgData={setOrgData} branch="mainBranch" />
      )}

      {openVerify && (
        <FacilityKYC open={openVerify} close={handleVerifyKyc({})} />
      )}

      {openHFR && <HFRDialog open={openHFR} close={handleHFR} />}
      {openWebsite && <AddWebsite close={addWebsite} />}

      {console.log(docstethPageData,)}

      {/* <div style={{ height: 'calc(100vh - 130px)', overflow: 'scroll', padding: "10px",background:"red" }}> */}
      {/* <div style={{  overflow: 'scroll', padding: "10px", }}> */}



      <div style={{ padding: "10px" }}>

        <Paper
          sx={{
            borderRadius: 0,
            boxShadow: "0px 3px 6px #00000029!important",

          }}
        >
          <div
            style={{
              width: "100%",
              overflow: "hidden",
              height: `${cover ? "" : "200px"}`,

            }}
          >
            <div style={{ position: "relative" }}>
              {fromGetApiImages?.length > 0 ? (
                <Carousel
                  showArrows={false}
                  showThumbs={false}
                  showStatus={false}
                >
                  {fromGetApiImages?.map((item, i) => {
                    return (

                      <img

                        key={i}
                        src={
                          fromGetApiImages[i]
                            ? fromGetApiImages[i]
                            : orgcoverpic
                        }
                        className="profile__cover"
                        alt=""
                        width="100%"
                      />
                    );
                  })}
                </Carousel>
              ) : (

                <img
                  src={
                    docstethPageData?.[0]?.page?.coverPhoto
                      ? docstethPageData?.[0]?.page?.coverPhoto
                      : orgcoverpic
                  }
                  className="profile__cover"
                  alt=""
                  width="100%"

                />
              )}

              <div>
                {/* {
                  isAdmin === "PERMIT" && */}
                <div className="orz__cover__edit">
                  {/* <input type="file" name="uploadfile" id="img" accept='.jpg, .png, .jpeg' style={{ display: 'none' }} onChange={(e) => uploadPhotoOrg(e, 'cover')}/> */}
                  {UserService.getUserId() ==
                    docstethPageData?.[0]?.page?.userId && (
                      <>
                        <input
                          type="file"
                          multiple
                          name="uploadfile"
                          id="img"
                          accept=".jpg, .png, .jpeg"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            uploadPhotoOrg(e, "photo", "coverpic", "coverPhoto")
                          }
                        />
                        <label
                          htmlFor="img"
                          className="follow-link fntBold add-cover cursor"
                        >
                          <img
                            src={edithub}
                            alt="edit"
                            className="organisation_edit"
                          />
                        </label>
                      </>
                    )}
                </div>
                {/* } */}

              </div>
            </div>
          </div>
          <div style={{ backgroundColor: "#c7ced9", position: "relative" }}>
            <div style={{ marginTop: "-12px", background: "green", }}>
              {/* <div > */}
              <div className="show-image">
                {docstethPageData?.[0]?.page?.orgPageImage ? (
                  <Avatar
                    className="profile-pic"
                    id="img_orz"
                    src={
                      docstethPageData?.[0]?.page?.orgPageImage
                        ? docstethPageData?.[0]?.page?.orgPageImage
                        : OrgLogo
                      // croppedImgRef.current
                    }
                    ref={croppedImgRef}
                    sx={{
                      backgroundColor: "#A8E8FE",
                      ".MuiAvatar-img": { width: "100%", height: "100%" },
                      boxShadow:
                        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                      border: "4px solid #fff",
                    }}
                  />
                ) : (
                  <Avatar
                    className="profile-pic"
                    id="img_orz"
                    src={OrgLogo}
                    sx={{
                      backgroundColor: "#A8E8FE",
                      ".MuiAvatar-img": { width: "60px", height: "60px" },
                      boxShadow:
                        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                      border: "4px solid #fff",
                    }}
                  />
                )}
                {/* <Avatar className="profile-pic" id='img_orz' src={OrgLogo} sx={{ backgroundColor: '#A8E8FE', '.MuiAvatar-img': { width: '60px', height: '60px' }, boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px', border: '4px solid #fff' }} /> */}

                <div className="cover-link">
                  {UserService.getUserId() ==
                    docstethPageData?.[0]?.page?.userId && (
                      <>
                        <label
                          className="follow-link font-weight add-cover cursor"
                        >
                          <p
                            className="edit-icon"
                            id="orz_image_edit"
                            onClick={handleOpen}
                          >
                            <EditIcon
                              className="cursor position_edit_icon "
                              style={{
                                fontSize: "16px",
                                position: "absolute",
                                top: "15px",
                                left: "62px",
                              }}
                            />
                          </p>
                        </label>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
          <Box
            sx={{
              marginLeft: { md: "180px" },
              marginTop: { xs: "30px", sm: "50px", md: "0px" },
              padding: "10px",
              display: { xs: "", sm: "flex" },
              // flexWrap: "wrap",
              justifyContent: "space-between",


            }}
          >
            {/* <Box sx={{ marginTop: { md: '80px', sm: "50px", xs: "30px" }, paddingLeft: { md: "34px", sm: "31px", xs: "18px" }, paddingRight: "10px", display: 'flex', justifyContent: 'space-between', alignItems: "" }}> */}
            <Box>
              <h1 className="orgname" style={{ color: "#1976d2" }}>{orgData?.name}</h1>
              <Typography style={{ marginBottom: "10px", marginTop: "-5px", color: "#1976D2" }}>
                {orgData?.alias?.[0]}
              </Typography>
              <p
                className="capitalize"
                style={{ textTransform: "capitalize", marginBottom: "1rem" }}
              >
                {
                  [
                    // location?.address?.line?.[0],
                    location?.address?.line?.[1], location?.address?.district, location?.address?.postalCode, location?.address?.line?.[3], location?.address?.country,
                    // location?.address?.state, 
                  ]?.filter(Boolean)?.join(", ") || "N/A"
                }
                {/* {[
                  orgData?.address?.[0]?.line?.[0],
                  orgData?.address?.[0]?.district,
                  orgData?.address?.[0]?.state,
                  orgData?.address?.[0]?.country,
                  orgData?.address?.[0]?.postalCode,
                ]
                  ?.filter(Boolean)
                  ?.toString()
                  ?.replace(/,/g, ", ") || "N/A"} */}
                {/* 
                                {[orgData?.address?.[0]?.line?.[0] || orgData?.address?.[0]?.district || orgData?.address?.[0]?.state || orgData?.address?.[0]?.country || orgData?.address?.[0]?.postalCode] ?
                                    <>
                                        {orgData?.address?.[0]?.line?.[0]}, {orgData?.address?.[0]?.district}, {orgData?.address?.[0]?.state}, {orgData?.address?.[0]?.country || ''}
                                        {orgData?.address?.[0]?.postalCode ? ` - ${orgData?.address?.[0]?.postalCode}` : ''}
                                    </> : 'N/A'} */}
              </p>
            </Box>

            {/* test start */}
            {/* 
            <Box className="mt0-5">


              <Grid container spacing={2}>
                <Grid item xs={4}>
                  {qr && <QRcode id="myqr" value={qr} size={80} />}
                </Grid>
                <Grid item xs={4}>
                  {orgData?.active ? (
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "7px",
                      }}
                    >
                      <VerifiedUser sx={{ color: "#13A800" }} /> Verified
                    </p>
                  ) : (
                    <>
                      <p
                        className="mb0-5 tac"
                        style={{
                          marginBottom: "5px",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        Status: Inactive <InfoIcon className="status_icon" />
                      </p>
              
                      <a
                        href={`${process.env.REACT_APP_REDIRECTCLINIDESK}/${userOrg?.identifier?.[1]?.value}`}
                        target="_blank"
                        style={{ textAlign: "center", display: "block" }}
                        className="mb0 tac"
                      >
                        <Button
                          variant="outlined"
                          onClick={handleVerify}
                          className="verify_kyc capitalize"
                        >
                          <ErrorOutlineIcon />
                          &nbsp;Verify KYC
                        </Button>
                      </a>
                    </>
                  )}

                  {orgData?.identifier?.length === 4 ? (
                    <p className="mt1" style={{ marginTop: "10px" }}>
                      <span className="fntBold" style={{ fontWeight: "500" }}>
                        HFR ID:
                      </span>{" "}
                      {orgData?.identifier?.[3]?.value}
                    </p>
                  ) : (
                    <a
                      href={`${process.env.REACT_APP_REDIRECTCLINIDESK}/${userOrg?.identifier?.[1]?.value}`}
                      target="_blank"
                      style={{
                        textAlign: "center",
                        display: "block",
                        marginTop: "10px",
                      }}
                      className="mb0 mt1 tac"
                    >
                      <Button
                        variant="outlined"
                        onClick={handleHFR}
                        className="verify_kyc capitalize"
                      >
                        <CompareArrowsSharpIcon />
                        &nbsp;Link HFR
                      </Button>
                    </a>
                  )}

                </Grid>
                <Grid item xs={4} sx={{ display: { xs: "block" } }}>

                  <Button
                    sx={{
                      height: "35px !important", marginTop: "15px", "& .MuiTypography-root": {
                        fontSize: "15px",
                        padding: "5px !important",
                        "@media (max-width:600px)": {
                          fontSize: "11px !important",
                        },
                      },
                    }}

                    className="capitalize edit_profile"
                    variant="contained"
                    onClick={hadleEditOrg}
                  >
                    <Edit style={{ fontSize: "15px", marginRight: "5px" }} />
                    <Typography >Edit Profile</Typography>
                  </Button>
                </Grid>
              </Grid>

            </Box> */}

            {/* testend */}

            <Box className="">
              <Grid container spacing={1} pt={""} >
                <Grid item >
                  {/* {qr && <QRcode id="myqr" value={qr} size={80} />} */}
                </Grid>
                <Grid item>
                  {orgData?.active ? (
                    <></>
                    // <p
                    //   style={{
                    //     display: "flex",
                    //     alignItems: "center",
                    //     gap: "7px",
                    //   }}
                    // >
                    //   {/* <VerifiedUser sx={{ color: "#13A800" }} /> Verified */}
                    // </p>
                  )
                    : (
                      <>
                        {/* <p
                          className="mb0-5 tac"
                          style={{
                            marginBottom: "5px",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          Status: Inactive <InfoIcon className="status_icon" />
                        </p>
                        <a
                          href={`${process.env.REACT_APP_REDIRECTCLINIDESK}/${userOrg?.identifier?.[1]?.value}`}
                          target="_blank"
                          style={{ textAlign: "center", display: "block" }}
                          className="mb0 tac"
                        >
                          <Button
                            variant="outlined"
                            onClick={handleVerify}
                            className="verify_kyc capitalize"
                          >
                            <ErrorOutlineIcon />
                            &nbsp;Verify KYC
                          </Button>
                        </a> */}
                      </>
                    )}
                  <div>

                    {
                      checkIsAdmin === "PERMIT" &&
                      <>
                        {

                          orgData?.identifier?.filter((val) => (val?.system == "https://hfrId/" && val?.value?.startsWith("IN")))?.length == 1 ?
                            <p className="mt1" style={{ marginTop: "10px" }}>
                              <span className="fntBold" style={{ fontWeight: "500" }}>
                                HFR ID:
                              </span>{" "}
                              {/* {orgData?.identifier?.[3]?.value} */}
                              {orgData?.identifier?.filter((val) => (val?.system == "https://hfrId/" && val?.value?.startsWith("IN")))?.[0]?.value}
                            </p>
                            :

                            <a
                              // href={`${process.env.REACT_APP_REDIRECTCLINIDESK}/${userOrg?.identifier?.[1]?.value}`}
                              // target="_blank"
                              style={{
                                textAlign: "center",
                                display: "block",
                                marginTop: "10px",
                                width: "100%"
                              }}
                              className="mb0 mt1 tac"
                            >
                              <Button
                                variant="outlined"
                                onClick={handleHFR}
                                className="verify_kyc capitalize"
                                sx={{ width: "100%" }}
                              >
                                <CompareArrowsSharpIcon />
                                &nbsp;Link HFR
                              </Button>
                            </a>
                        }
                      </>
                    }
                  </div>
                  {/* {orgData?.identifier?.length === 4 ? (
                    <p className="mt1" style={{ marginTop: "10px" }}>
                      <span className="fntBold" style={{ fontWeight: "500" }}>
                        HFR ID:
                      </span>{" "}
                      {orgData?.identifier?.[3]?.value}
                    </p>
                  )
                    : (
                      <a
                        // href={`${process.env.REACT_APP_REDIRECTCLINIDESK}/${userOrg?.identifier?.[1]?.value}`}
                        // target="_blank"
                        style={{
                          textAlign: "center",
                          display: "block",
                          marginTop: "10px",
                        }}
                        className="mb0 mt1 tac"
                      >
                        <Button
                          variant="outlined"
                          onClick={handleHFR}
                          className="verify_kyc capitalize"
                        >
                          <CompareArrowsSharpIcon />
                          &nbsp;Link HFR
                        </Button>
                      </a>
                    )

                  } */}


                  {/* test for kyc */}
                  <div style={{ paddingTop: "5px" }}>
                    {
                      docstethPageData && docstethPageData?.[0]?.page?.kycStatus === "PENDING" ?
                        <p className="flx-center" style={{ color: 'red' }}><Error />&nbsp;Pending KYC Verification</p>
                        :
                        docstethPageData && docstethPageData?.[0]?.page?.kycStatus === "APPROVED" ?
                          <p>
                            <VerifiedUser sx={{ color: "#13A800" }} /> Verified
                          </p>
                          :
                          <Button
                            variant="outlined"
                            // onClick={handleVerify} 
                            className="verify_kyc capitalize"
                            sx={{ width: "100%" }}
                            onClick={handleOpentoUploadKYCDocs}
                          >
                            <ErrorOutlineIcon sx={{ fontSize: "15px" }} />
                            &nbsp;Verify KYC
                          </Button>

                    }

                  </div>
                  <div style={{ paddingTop: '5px', }}>

                    {
                      checkIsAdmin === "PERMIT" &&
                      <Button
                        sx={{ height: "30px !important", width: "100%" }}
                        className="capitalize edit_profile"
                        variant="contained"
                        onClick={hadleEditOrg}

                      >
                        <Edit style={{ fontSize: "15px", marginRight: "5px" }} />
                        <Typography>Edit Page</Typography>
                      </Button>
                    }

                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>


        {
          (curPath != `/admin/organization/${id}/healthDepartment` && curPath != `/admin/organization/${id}/healthService`) &&

          <Paper
            sx={{ borderRadius: 0, boxShadow: "none" }}
            className="org_layout"
          >
            <Grid container sx={{ height: "100%" }}>
              <Grid item sm={12} xs={12}>
                <TabContext value={tab}>
                  <Card
                    sx={{ borderRadius: 0, boxShadow: "0px 3px 6px #00000029" }}
                  >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        scrollButtons="auto"
                        classes={{
                          indicator: { backgroundColor: "#0F22B5!important" },
                        }}
                        onChange={handleTabs}
                        aria-label="lab API tabs example"
                      // sx={{
                      //   paddingLeft: { xs: "0px", sm: "250px", md: "300px" },
                      // }}
                      >
                        {["Overview", "Department", "Timeline", "Services", "People"].map(
                          (tab, i) => (
                            <Tab
                              className="capitalize"
                              key={i}
                              classes={{
                                selected: { color: "#0F22B5!important" },
                              }}
                              label={tab}
                              value={`${i + 1}`}
                            />
                          )
                        )}
                      </TabList>
                    </Box>
                  </Card>
                  {/* test case start */}
                  <Grid container sx={{ backgroundColor: "#F4F2EE" }}>
                    <Grid item xs={12} sm={12} md={12}>
                      <TabPanel value="1" sx={{ padding: "1px 0px 0px 0px", backgroundColor: "#F4F2EE" }}>

                        {curPath == `/admin/organization/${id}` &&
                          <>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={4} md={3}>

                                <Appiontment addWebsite={addWebsite} qr={qr} timings={timings} isToday={isToday} WorkingDays={WorkingDays} setTimings={setTimings} setActiveDay={setActiveDay} activeDay={activeDay} />
                                <ContextInfo.Provider value={{ orgObj: userOrg, getsubOrgDetails }}>
                                  {/* <FacilityDetails parentId={userOrg?.id} /> */}
                                  <FacilityDetails />
                                </ContextInfo.Provider>

                                <LinkAccountOrg />
                                <HospitalLocationDetails />

                              </Grid>
                              <Grid item xs={12} sm={8} md={9} >
                                {/* <ContextInfo.Provider value={{ orgObj: userOrg, getsubOrgDetails }}> */}
                                <OverView onSelectDepartment={handleSelectDepartment} tab={tab} setSelectedDept={setSelectedDept}
                                  onSelectService={handleSelectService} setSelectedServ={setSelectedServ}
                                />
                                {/* </ContextInfo.Provider> */}
                              </Grid>
                            </Grid>
                          </>

                        }

                        {/* {curPath == `/admin/organization/${id}/healthDepartment/${ibmId}` &&
                        <>
                          <HealthDeptInfo />
                        </>
                      } */}
                        {/* {
                        curPath == `/admin/organization/${id}/healthService/${ibmId}` &&
                        <>
                          <HealthServiceInfo />
                        </>
                      } */}

                        {/* <HealthDeptInfo /> */}
                      </TabPanel>

                      <TabPanel value="2" sx={{ padding: "5px 0 0 0" }}>
                        <Departments selectedDept={selectedDept} tabs={tab} setSelectedServ={setSelectedServ} />
                      </TabPanel>

                      <TabPanel value="3" sx={{ padding: "5px 0 0 0" }}>
                        <div className="desktop_view_org" >
                          <AdminTimeline />
                        </div>
                        <div className="orz_small_Devices" >
                          {/* <AdminPublicationNew
                          pageUserId={docstethPageData?.[0]?.page?.pageAdmin}
                        /> */}
                          {/* <Feed view={true} /> */}
                          <AdminTimeline />
                        </div>
                      </TabPanel>

                      <TabPanel value="4" sx={{ padding: "5px 0 0" }}>
                        <Services selectedServ={selectedServ} tabs={tab} setSelectedDept={setSelectedDept} />
                      </TabPanel>
                      <TabPanel value="5" sx={{ padding: "0px 0 0" }}>
                        <PeopleOrg tabs={tab} setSelectedDept={setSelectedDept} setSelectedServ={setSelectedServ} />
                      </TabPanel>
                    </Grid>
                  </Grid>
                  {/* testcase end */}
                  {/* <Grid container spacing={2} sx={{ paddingTop: "10px" }}> */}
                  {/* <Grid item xs={12} sm={4} md={3}>
                      <Card sx={{ padding: "1rem" }}>
                        <Typography variant="body2" sx={{ margin: "0" }}>
                          Organization Type
                        </Typography>
                        <Typography
                          sx={{
                            marginBottom: "12px !important",
                            fontWeight: "500",
                            fontSize: "18px",
                          }}
                        >
                          {userOrg?.type?.[0]?.coding?.[0]?.display}
                        </Typography>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            fontSize: "13px",
                          }}
                        >
                          <PublicIcon
                            sx={{ color: "#01CDE5" }}
                            fontSize="small"
                          />
                          <Typography sx={{ color: "#5C5C5C" }}>
                            Website
                          </Typography>
                        </div>
                        {userOrg?.endpoint?.[0]?.display ? (
                          <>
                            <a
                              href={`https://${userOrg?.endpoint?.[0]?.display.toLowerCase()}`}
                              target="_blank"
                              style={{
                                fontStyle: "italic",
                                fontSize: "16px",
                                color: "#6F94E6",
                              }}
                            >
                              {userOrg?.endpoint?.[0]?.display.toLowerCase()}{" "}
                            </a>
                          </>
                        ) : (
                          <Button
                            sx={{
                              boxShadow: 2,
                              width: "100%",
                              minWidth: "25px",
                              marginTop: "10px",
                              textTransform: "capitalize",
                            }}
                            onClick={addWebsite}
                          >
                            Add Website
                          </Button>
                        )}

                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            margin: "12px 0",
                          }}
                        >
                          <Card sx={{ display: "flex", flex: 1 }}>
                            <a
                              className="call"
                              href={`tel:${userOrg?.telecom?.[0]?.value}`}
                            >
                              <img width="24px" height="17px" src={call} />
                              Call
                            </a>
                          </Card>
                          <Card sx={{ display: "flex", flex: 1 }}>
                            <a
                              className="call"
                              href={`mailto:${userOrg?.telecom?.[1]?.value}`}
                            >
                              <img width="24px" height="17px" src={mail} />
                              Email
                            </a>
                          </Card>
                        </div>

                        <Card sx={{ margin: "12px 0" }}>
                          <a
                            className="direction"
                            target="_blank"
                            href={`https://www.google.com/maps?q=${
                              userOrg?.address?.[0]?.line[3]?.split(",")[0]
                            },${userOrg?.address?.[0]?.line[3]?.split(",")[1]}`}
                          >
                            <img width="24px" height="17px" src={mail} />
                            <Typography
                              sx={{ color: "#0243D6", fontWeight: "500" }}
                            >
                              Get directions
                            </Typography>
                          </a>
                        </Card>

                        <div className="workinghours">
                          <img width="24px" height="17px" src={org} />
                          <Typography sx={{ color: "#5C5C5C" }}>
                            Working Hours
                          </Typography>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            overflowY: "scroll",
                            margin: "12px 0",
                          }}
                        >
                          {WorkingDays?.map((item, i) => {
                            // return <Button variant='contained' onClick={() => { setTimings(item); setActiveDay(i) }} disabled={!item.allDay && !item.hasOwnProperty('workingHours')} sx={{ minWidth: "25px", background: activeDay == i && activeDay !== null ? "black" : item.today && activeDay == null ? "black" : '', padding: '0px 7px', }}><Typography variant="caption" sx={{ color: 'white', textTransform: 'capitalize' }}>{item.day}</Typography></Button>
                            return (
                              <Button
                                key={i}
                                variant="contained"
                                onClick={() => {
                                  setTimings(item);
                                  setActiveDay(i);
                                }}
                                sx={{
                                  minWidth: "25px",
                                  background:
                                    activeDay == i && activeDay !== null
                                      ? "black"
                                      : item.today && activeDay == null
                                      ? "black"
                                      : !item.allDay &&
                                        !item.hasOwnProperty("workingHours")
                                      ? "#A9A9A9"
                                      : "#197FC4",
                                  padding: "0px 7px",
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: "white",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item.day}
                                </Typography>
                              </Button>
                            );
                          })}
                        </div>

                        {timings.day && (
                          <Typography
                            variant="body2"
                            sx={{ marginBottom: "5px" }}
                          >
                            Timings -{" "}
                            {timings?.day == "mon"
                              ? "Monday"
                              : timings?.day == "tue"
                              ? "Tuesday"
                              : timings?.day == "wed"
                              ? "Wednesday"
                              : timings?.day == "thu"
                              ? "Thursday"
                              : timings?.day == "fri"
                              ? "Friday"
                              : timings?.day == "sat"
                              ? "Saturday"
                              : timings?.day == "sun"
                              ? "Sunday"
                              : ""}{" "}
                            {timings?.day == isToday ? "(Today)" : ""}
                          </Typography>
                        )}
                        {timings && timings.allDay ? (
                          <Typography variant="body2" className="timing">
                            All Day
                          </Typography>
                        ) : timings.allDay == false ? (
                          <Typography variant="body2" className="timing">
                            No Slot avalable
                          </Typography>
                        ) : (
                          timings?.workingHours &&
                          timings?.workingHours?.map((item, ind) => {
                            return (
                              <Typography
                                key={ind}
                                mb={2}
                                sx={{ marginBottom: "5px !important" }}
                                variant="body2"
                                className="timing"
                              >
                                {moment(item?.openingTime, "hh:mm").format(
                                  "HH:mm A"
                                )}
                                &nbsp;&nbsp;-&nbsp;&nbsp;
                                {moment(item?.closingTime, "hh:mm").format(
                                  "HH:mm A"
                                )}
                              </Typography>
                            );
                          })
                        )}
                      </Card>
                    </Grid> */}
                  {/* <Grid item xs={12} sm={4} md={3}> */}
                  {/* <Card sx={{ padding: "1rem" }}>
                      <Typography variant="body2" sx={{ margin: "0" }}>
                        Organization Type
                      </Typography>
                      <Typography
                        sx={{
                          marginBottom: "12px !important",
                          fontWeight: "500",
                          fontSize: "18px",
                        }}
                      >
                        {userOrg?.type?.[0]?.coding?.[0]?.display}
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          fontSize: "13px",
                        }}
                      >
                        <PublicIcon
                          sx={{ color: "#01CDE5" }}
                          fontSize="small"
                        />
                        <Typography sx={{ color: "#5C5C5C" }}>
                          Website
                        </Typography>
                      </div>
                      {userOrg?.endpoint?.[0]?.display ? (
                        <>
                          <a
                            href={`https://${userOrg?.endpoint?.[0]?.display.toLowerCase()}`}
                            target="_blank"
                            style={{
                              fontStyle: "italic",
                              fontSize: "16px",
                              color: "#6F94E6",
                            }}
                          >
                            {userOrg?.endpoint?.[0]?.display.toLowerCase()}{" "}
                          </a>
                        </>
                      ) : (
                        <Button
                          sx={{
                            boxShadow: 2,
                            width: "100%",
                            minWidth: "25px",
                            marginTop: "10px",
                            textTransform: "capitalize",
                          }}
                          onClick={addWebsite}
                        >
                          Add Website
                        </Button>
                      )}

                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          margin: "12px 0",
                        }}
                      >
                        <Card sx={{ display: "flex", flex: 1 }}>
                          <a
                            className="call"
                            href={`tel:${userOrg?.telecom?.[0]?.value}`}
                          >
                            <img width="24px" height="17px" src={call} />
                            Call
                          </a>
                        </Card>
                        <Card sx={{ display: "flex", flex: 1 }}>
                          <a
                            className="call"
                            href={`mailto:${userOrg?.telecom?.[1]?.value}`}
                          >
                            <img width="24px" height="17px" src={mail} />
                            Email
                          </a>
                        </Card>
                      </div>

                      <Card sx={{ margin: "12px 0" }}>
                        <a
                          className="direction"
                          target="_blank"
                          href={`https://www.google.com/maps?q=${userOrg?.address?.[0]?.line[3]?.split(",")[0]
                            },${userOrg?.address?.[0]?.line[3]?.split(",")[1]}`}
                        >
                          <img width="24px" height="17px" src={mail} />
                          <Typography
                            sx={{ color: "#0243D6", fontWeight: "500" }}
                          >
                            Get directions
                          </Typography>
                        </a>
                      </Card>

                      <div className="workinghours">
                        <img width="24px" height="17px" src={org} />
                        <Typography sx={{ color: "#5C5C5C" }}>
                          Working Hours
                        </Typography>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          overflowY: "scroll",
                          margin: "12px 0",
                        }}
                      >
                        {WorkingDays?.map((item, i) => {
                          // return <Button variant='contained' onClick={() => { setTimings(item); setActiveDay(i) }} disabled={!item.allDay && !item.hasOwnProperty('workingHours')} sx={{ minWidth: "25px", background: activeDay == i && activeDay !== null ? "black" : item.today && activeDay == null ? "black" : '', padding: '0px 7px', }}><Typography variant="caption" sx={{ color: 'white', textTransform: 'capitalize' }}>{item.day}</Typography></Button>
                          console.log(WorkingDays, "66666666668");
                          return (
                            <Button
                              key={i}
                              variant="contained"
                              onClick={() => {
                                setTimings(item);
                                setActiveDay(i);
                              }}
                              sx={{
                                minWidth: "25px",
                                background:
                                  activeDay == i && activeDay !== null
                                    ? "black"
                                    : item.today && activeDay == null
                                      ? "black"
                                      : !item.allDay &&
                                        !item.hasOwnProperty("workingHours")
                                        ? "#A9A9A9"
                                        : "#197FC4",
                                padding: "0px 7px",
                              }}
                            >
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "white",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item.day}
                              </Typography>
                            </Button>
                          );
                        })}
                      </div>

                      {console.log(timings, "666666666")}
                      {timings.day && (
                        <Typography
                          variant="body2"
                          sx={{ marginBottom: "5px" }}
                        >
                          Timings -{" "}
                          {timings?.day == "mon"
                            ? "Monday"
                            : timings?.day == "tue"
                              ? "Tuesday"
                              : timings?.day == "wed"
                                ? "Wednesday"
                                : timings?.day == "thu"
                                  ? "Thursday"
                                  : timings?.day == "fri"
                                    ? "Friday"
                                    : timings?.day == "sat"
                                      ? "Saturday"
                                      : timings?.day == "sun"
                                        ? "Sunday"
                                        : ""}{" "}
                          {timings?.day == isToday ? "(Today)" : ""}
                        </Typography>
                      )}

                      {timings && timings.allDay ? (
                        <Typography variant="body2" className="timing">
                          All Day
                        </Typography>
                      ) : timings.allDay == false ? (
                        <Typography variant="body2" className="timing">
                          No Slot avalable
                          {console.log(timings, "666666666")}
                        </Typography>
                      ) : (
                        timings?.workingHours &&
                        timings?.workingHours?.map((item, ind) => {
                          console.log(item, "");
                          return (
                            <Typography
                              key={ind}
                              mb={2}
                              sx={{ marginBottom: "5px !important" }}
                              variant="body2"
                              className="timing"
                            >
                              {moment(item?.openingTime, "hh:mm").format(
                                "HH:mm A"
                              )}
                              &nbsp;&nbsp;-&nbsp;&nbsp;
                              {moment(item?.closingTime, "hh:mm").format(
                                "HH:mm A"
                              )}
                            </Typography>
                          );
                        })
                      )}
                    </Card> */}
                  {/* <Appiontment addWebsite={addWebsite} qr={qr} timings={timings} isToday={isToday} WorkingDays={WorkingDays} setTimings={setTimings} setActiveDay={setActiveDay} activeDay={activeDay} />
                    <HospitalLocationDetails /> */}
                  {/* </Grid> */}
                  {/* <Grid item xs={12} sm={8} md={9}> */}
                  {/* <TabPanel value="1" sx={{ padding: "5px 0" }}>
                      <OverView />
                    </TabPanel> */}
                  {/* <TabPanel value="2" sx={{ padding: "5px 0" }}>
                      <div className="desktop_view_org">
                        <AdminTimeline />
                      </div>
                      <div className="orz_small_Devices">
                        <Feed view={true} />
                      </div>
                    </TabPanel> */}
                  {/* <TabPanel value="3" sx={{ padding: "5px 0" }}>
                      <Services />
                    </TabPanel> */}
                  {/* <TabPanel value="4" sx={{ padding: "5px 0" }}>
                      <PeopleOrg />
                    </TabPanel> */}
                  {/* </Grid> */}
                  {/* </Grid> */}
                </TabContext>
                {/* <AdminPublicationNew
                          pageUserId={docstethPageData?.[0]?.page?.pageAdmin}
                        /> */}
              </Grid>
            </Grid>
            {/* <Grid container> */}
            {/* <Grid item xs={12} sm={12} md={3}>
                <Card sx={{ padding: "1rem" }}>
                  <Typography variant="body2" sx={{ margin: "0" }}>
                    Organization Type
                  </Typography>
                  <Typography
                    sx={{
                      marginBottom: "12px !important",
                      fontWeight: "500",
                      fontSize: "18px",
                    }}
                  >
                    {userOrg?.type?.[0]?.coding?.[0]?.display}
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      fontSize: "13px",
                    }}
                  >
                    <PublicIcon sx={{ color: "#01CDE5" }} fontSize="small" />
                    <Typography sx={{ color: "#5C5C5C" }}>Website</Typography>
                  </div>
                  {userOrg?.endpoint?.[0]?.display ? (
                    <>
                      <a
                        href={`https://${userOrg?.endpoint?.[0]?.display.toLowerCase()}`}
                        target="_blank"
                        style={{
                          fontStyle: "italic",
                          fontSize: "16px",
                          color: "#6F94E6",
                        }}
                      >
                        {userOrg?.endpoint?.[0]?.display.toLowerCase()}{" "}
                      </a>
                    </>
                  ) : (
                    <Button
                      sx={{
                        boxShadow: 2,
                        width: "100%",
                        minWidth: "25px",
                        marginTop: "10px",
                        textTransform: "capitalize",
                      }}
                      onClick={addWebsite}
                    >
                      Add Website
                    </Button>
                  )}

                  <div
                    style={{ display: "flex", gap: "10px", margin: "12px 0" }}
                  >
                    <Card sx={{ display: "flex", flex: 1 }}>
                      <a
                        className="call"
                        href={`tel:${userOrg?.telecom?.[0]?.value}`}
                      >
                        <img width="24px" height="17px" src={call} />
                        Call
                      </a>
                    </Card>
                    <Card sx={{ display: "flex", flex: 1 }}>
                      <a
                        className="call"
                        href={`mailto:${userOrg?.telecom?.[1]?.value}`}
                      >
                        <img width="24px" height="17px" src={mail} />
                        Email
                      </a>
                    </Card>
                  </div>

                  <Card sx={{ margin: "12px 0" }}>
                    <a
                      className="direction"
                      target="_blank"
                      href={`https://www.google.com/maps?q=${
                        userOrg?.address?.[0]?.line[3]?.split(",")[0]
                      },${userOrg?.address?.[0]?.line[3]?.split(",")[1]}`}
                    >
                      <img width="24px" height="17px" src={mail} />
                      <Typography sx={{ color: "#0243D6", fontWeight: "500" }}>
                        Get directions
                      </Typography>
                    </a>
                  </Card>

                  <div className="workinghours">
                    <img width="24px" height="17px" src={org} />
                    <Typography sx={{ color: "#5C5C5C" }}>
                      Working Hours
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      overflowY: "scroll",
                      margin: "12px 0",
                    }}
                  >
                    {WorkingDays?.map((item, i) => {
                      // return <Button variant='contained' onClick={() => { setTimings(item); setActiveDay(i) }} disabled={!item.allDay && !item.hasOwnProperty('workingHours')} sx={{ minWidth: "25px", background: activeDay == i && activeDay !== null ? "black" : item.today && activeDay == null ? "black" : '', padding: '0px 7px', }}><Typography variant="caption" sx={{ color: 'white', textTransform: 'capitalize' }}>{item.day}</Typography></Button>
                      return (
                        <Button
                          key={i}
                          variant="contained"
                          onClick={() => {
                            setTimings(item);
                            setActiveDay(i);
                          }}
                          sx={{
                            minWidth: "25px",
                            background:
                              activeDay == i && activeDay !== null
                                ? "black"
                                : item.today && activeDay == null
                                ? "black"
                                : !item.allDay &&
                                  !item.hasOwnProperty("workingHours")
                                ? "#A9A9A9"
                                : "#197FC4",
                            padding: "0px 7px",
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{ color: "white", textTransform: "capitalize" }}
                          >
                            {item.day}
                          </Typography>
                        </Button>
                      );
                    })}
                  </div>

                  {timings.day && (
                    <Typography variant="body2" sx={{ marginBottom: "5px" }}>
                      Timings -{" "}
                      {timings?.day == "mon"
                        ? "Monday"
                        : timings?.day == "tue"
                        ? "Tuesday"
                        : timings?.day == "wed"
                        ? "Wednesday"
                        : timings?.day == "thu"
                        ? "Thursday"
                        : timings?.day == "fri"
                        ? "Friday"
                        : timings?.day == "sat"
                        ? "Saturday"
                        : timings?.day == "sun"
                        ? "Sunday"
                        : ""}{" "}
                      {timings?.day == isToday ? "(Today)" : ""}
                    </Typography>
                  )}
                  {timings && timings.allDay ? (
                    <Typography variant="body2" className="timing">
                      All Day
                    </Typography>
                  ) : timings.allDay == false ? (
                    <Typography variant="body2" className="timing">
                      No Slot avalable
                    </Typography>
                  ) : (
                    timings?.workingHours &&
                    timings?.workingHours?.map((item, ind) => {
                      return (
                        <Typography
                          key={ind}
                          mb={2}
                          sx={{ marginBottom: "5px !important" }}
                          variant="body2"
                          className="timing"
                        >
                          {moment(item?.openingTime, "hh:mm").format("HH:mm A")}
                          &nbsp;&nbsp;-&nbsp;&nbsp;
                          {moment(item?.closingTime, "hh:mm").format("HH:mm A")}
                        </Typography>
                      );
                    })
                  )}
                </Card>
              </Grid> */}
            {/* </Grid> */}
          </Paper>
        }

        {
          curPath == `/admin/organization/${id}/healthDepartment` &&
          <>
            <HealthDeptInfo />
          </>
        }
        {
          curPath == `/admin/organization/${id}/healthService` &&
          <>
            <HealthServiceInfo />
          </>
        }
      </div >

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        open={uploadLoader.open}
        onClose={() => setUploadLoader({ open: false, msg: "" })}
        message={uploadLoader.msg}
      />
      <Modal open={open} onClose={handleClose} aria-labelledby="image-modal">
        <Box sx={style}>
          <input
            type="file"
            name="uploadfile2"
            // id="img1"
            accept=".jpg, .png, .jpeg"
            // style={{ display: "none" }}
            onChange={(e) =>
              uploadPhotoOrg(e, "photo", "profilepic", "orgPageImage")
            }
          />
          {error && <p>{error}</p>}
          {url && (
            <ReactCrop
              crop={crop}
              onChange={(pixelCrop, percentCrop) => {
                setCrop(percentCrop);
              }}
            >
              <img
                ref={imageRef}
                src={url}
                style={{ maxHeight: "70vh" }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
          <Button
            variant="outlined"
            color="success"
            style={{ my: 4, textAlign: "center" }}
            onClick={() => {
              setCanvasPreview(
                imageRef.current, // HTMLImageElement
                previewCanvasRef.current, // HTMLCanvasElement
                convertToPixelCrop(
                  crop,
                  imageRef.current.width,
                  imageRef.current.height
                )
              );
              const dataUrl = previewCanvasRef.current.toDataURL();
              updateAvatar(dataUrl);
              handleClose();
            }}
          >
            Crop Image
          </Button>
          <canvas
            style={{ border: "1px solid black", objectFit: "contain", display: "none" }}
            ref={previewCanvasRef}
          />
        </Box>

      </Modal>
    </Box >
  );
}

export default AdminOrganizationNew


