import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import { Box, Button, Card, CardActions, CardContent, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Divider, FormGroup, Grid, IconButton, Snackbar, Tab, Tabs, TextField, TextareaAutosize, Tooltip, Typography, Avatar, CardTitle, DialogTitle } from '@mui/material'
import './organisation.css'
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import AddAmenities from '../amenities/AddAmenities';
import { D_API, D_API_FHIR_WITHOUT_TOKEN, } from '../../../../../services/APIService';
import API from '../../../../../services/APIService';
// import AddWebsite from '../amenities/AddWebsite';
import { useParams, useHistory } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
// import { getLocation } from '../../../../redux/Actions/regActions';
import '../PublicOrganization/publicProfile.css';
import { getLocation, handleUserOrg, gethealthDeptListByPgId, getIsAdmin } from '../../../../../redux/Actions/regActions';
import UserService from '../../../../../services/UserService';
// import icons
import Photo from '../amenities/overviewgallery/Photo';
import PublicIcon from '@mui/icons-material/Public';
import Stethoscope from '../../../../../images/stethoscope1.svg';
import mail from '../../../../../images/mail.svg'
import call from '../../../../../images/call.svg'
import org from '../../../../../images/Group 13144.svg'
import Hospital from '../../../../../images/Hospital.svg'
import Camera from '../../../../../images/camera.svg'
import Edit from '../../../../../images/edit1.svg'
import AMINITIES from '../../../../../images/AMINITIES.svg'
import OrgLogo from '../../../../../images/orglogo.svg'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { BiChevronRightCircle } from "react-icons/bi";
import DeleteIcon from '@mui/icons-material/Delete';
import { URI } from '../../../../utility/uri';
import URL from '../../../../apiurl';
import AddHealthcareDept from "../AdminOrganization/HealthCareDept/addHealthcareDept";
import AddHealthServiceDept from "../HealthServicesDept/addServiceDept";
import HealthDeptInfo from './HealthCareDept/particularHealthDeptInfo';
import { handleHealthDeptLoc, handleHealthDeptOrg, handleHealthServiceLoc, handleHealthServiceOrgInfo, getUserOrg, fetchSubOrganization } from "../../../../../redux/Actions/regActions";
import HealthDeptIcon from "../../../../../icons/Healthcare_Department.svg";
import DefaultHealthServiceIcon from "../../../../../icons/Healthcare_Service.svg";
import ReactMarkdown from 'react-markdown';
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToMarkdown from 'draftjs-to-markdown';
import { Editor } from 'react-draft-wysiwyg';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import TurndownService from 'turndown';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import RearrangeDepartmentsDialog from "./rearrangingDepartments";
import "./overview.css"
import RearrangingServices from './rearrangingServices';
import { ContextInfo } from './adminOrganisationNew';






const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
const daysCompare = { 'mon': 1, 'tue': 2, 'wed': 3, 'thu': 4, 'fri': 5, 'sat': 6, 'sun': 7 }

const Instances = ({ id }) => {
  const [allinstance, setAllInstance] = useState(null)
  const userOrg = useSelector(state => state.registration.userOrg);

  // console.log(allinstance?.[0]?.resource?.physicalType?.text, "check please ")

  useEffect(() => {

    if (id) {
      D_API.get(`${userOrg?.id}/Location/partof/${id}?count=50&page=1`, {
        headers: { Authorization: `bearer ${UserService.getToken()}` },
      })
        .then(res => {
          if (res.data?.entry?.[0]?.resource?.id) {
            instance(res.data?.entry?.[0]?.resource?.id)
          }

        }).catch(err => { })
    }

  }, [id])

  const instance = (id) => {
    if (id) {
      D_API.get(`${userOrg?.id}/Location/partof/${id}`, {
        headers: { Authorization: `bearer ${UserService.getToken()}` },
      })
        .then(res => {
          // console.log(res?.data?.entry, "check please")
          setAllInstance(res?.data?.entry)
        }).catch(err => { })
    }
  }

  return (
    <>
      <Typography sx={{ color: '#5C5C5C' }}>Instances</Typography>
      <Typography>{allinstance?.[0]?.resource?.physicalType?.text ? allinstance?.[0]?.resource?.physicalType?.text : 0} Consultation Rooms</Typography>
    </>
  )

}

const TabsComponent = ({ hoursOfOperation, id }) => {
  const [value, setValue] = React.useState('1');
  const [WorkingDays, setWorkingDays] = useState([])

  const [timings, setTimings] = useState({})

  const [activeDay, setActiveDay] = useState(null)

  // console.log(activeDay, 'activeDay')

  // console.log(timings, "timings")

  // console.log(WorkingDays, "WorkingDays")


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    let data = []
    const date = new Date()
    const today = days[date.getDay() - 1]
    {
      hoursOfOperation?.map((item, i) => {
        let openingTime = item.openingTime
        let closingTime = item.closingTime
        if (item.allDay == true) {
          (item?.daysOfWeek?.map((child) => {
            data.push({ day: child, allDay: item.allDay, today: child == today ? true : false })
          }))
        } else if (item.allDay == false) {
          (item?.daysOfWeek?.map((child) => {
            data.push({ day: child, allDay: item.allDay, today: child == today ? true : false })
          }))
        } else {
          (item?.daysOfWeek?.map((child) => {
            data.push({ day: child, workingHours: [{ openingTime: openingTime, closingTime: closingTime }], today: child == today ? true : false })

          }))

        }
      })
    }


    const mergedSchedule = [];

    for (let i = 0; i < data.length; i++) {
      let day = data[i].day;
      let hours = data[i].workingHours;

      // Check if the day already exists in the merged data
      let existingDay = mergedSchedule.find(item => item.day === day);
      // console.log(existingDay, "existingDay")
      if (existingDay) {
        // If the day exists, merge the working hours
        existingDay.workingHours = existingDay.workingHours.concat(hours);
        // console.log(existingDay, "existingDay")

      } else {
        // If the day doesn't exist, add it to the merged schedule
        mergedSchedule.push(data[i]);
      }
    }

    const sorted = mergedSchedule.sort((a, b) => {
      return daysCompare[a.day] - daysCompare[b.day];
    })


    setWorkingDays(sorted)

  }, [hoursOfOperation])

  useEffect(() => {
    const date = new Date()
    const today = days[date.getDay() - 1]

    WorkingDays?.find((item) => {

      if (item.day == today) {

        setTimings(item)

      }
    })

  }, [WorkingDays])


  const d = new Date();

  let isToday = days[d.getDay() - 1]




  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value} >
          <Box sx={{ background: '#E9F3F8' }}>
            <Tabs value={value} variant="fullWidth" onChange={handleChange} indicatorColor={""} sx={{
              alignItems: 'center', gap: "10px", justifyContent: 'space-around ', '& .MuiTab-root': { minHeight: '30px' }, '& .MuiTabs-scroller': {
                minHeight: '30px',
              },
            }}>
              <Tab label="Availability" value="1"
                sx={{
                  '&.MuiTab-root': { background: '#348FC6', borderRadius: '8px', minHeight: '40px', background: '#E9F3F8', color: 'black', textTransform: 'capitalize' },
                  '&.Mui-selected': { color: 'white', borderBottom: 'none', backgroundColor: '#348fc6' },
                }} />
              <Tab label="Amenities" value="2"
                sx={{
                  '&.MuiTab-root': { background: '#348FC6', borderRadius: '8px', minHeight: '40px', background: '#E9F3F8', color: 'black', textTransform: 'capitalize' },
                  '&.Mui-selected': { color: 'white', borderBottom: 'none', backgroundColor: '#348fc6' },
                }} />
              <Tab label="Services" value="3"
                sx={{
                  '&.MuiTab-root': { background: '#348FC6', borderRadius: '8px', minHeight: '40px', background: '#E9F3F8', color: 'black', textTransform: 'capitalize' },
                  '&.Mui-selected': { color: 'white', borderBottom: 'none', backgroundColor: '#348fc6' },
                }} />
            </Tabs>
          </Box>
          <TabPanel value="1" sx={{ height: "165px", overflow: "scroll", padding: "10px 20px" }}>
            <Instances id={id} />
            <div style={{ display: 'flex', gap: '5px', overflowY: 'scroll', margin: '10px 0 12px' }}>
              {WorkingDays?.map((item, i) => {
                // return <Button variant='contained' onClick={() => { setTimings(item); setActiveDay(i) }} disabled={!item.allDay && !item.hasOwnProperty('workingHours')} sx={{ minWidth: "25px", background: activeDay == i && activeDay !== null ? "black" : item.today && activeDay == null ? "black" : '', padding: '0px 7px', }}><Typography variant="caption" sx={{ color: 'white', textTransform: 'capitalize' }}>{item.day}</Typography></Button>
                return <Button key={i} variant='contained' onClick={() => { setTimings(item); setActiveDay(i) }} sx={{ minWidth: "25px", background: activeDay == i && activeDay !== null ? "black" : item.today && activeDay == null ? "black" : !item.allDay && !item.hasOwnProperty('workingHours') ? "#A9A9A9" : '#197FC4', padding: '0px 7px', }}><Typography variant="caption" sx={{ color: 'white', textTransform: 'capitalize' }}>{item.day}</Typography></Button>
              })}

            </div>


            {timings?.day &&
              <Typography variant='body2'>
                Timings - {timings?.day == "mon" ? "Monday" : timings?.day == "tue" ? "Tuesday" : timings?.day == "wed" ? "Wednesday" : timings?.day == "thu" ? "Thursday" : timings?.day == "fri" ? "Friday" : timings?.day == "sat" ? "Saturday" : "Sunday"} {timings?.day == isToday ? "(Today)" : ""}
              </Typography>
            }

            {
              timings?.allDay ?
                <Typography variant='body2' className='timing' >All Day</Typography>
                : timings.allDay == false ? <Typography variant='body2' className='timing'>No Slot avalable</Typography>
                  :
                  <div style={{ display: 'flex', gap: "10px", flexWrap: 'wrap' }}>
                    {
                      timings?.workingHours && timings?.workingHours?.map((item, ind) => {
                        return <Typography key={ind} variant='body2' className='timing'>{moment(item?.openingTime, 'hh:mm').format('HH:mm A')}&nbsp;&nbsp;-&nbsp;&nbsp;{moment(item?.closingTime, 'hh:mm').format('HH:mm A')}</Typography>
                      })
                    }
                  </div>

              // timings?.workingHours && <Typography variant='body2' className='timing'>{moment(timings?.workingHours?.openingTime, 'hh:mm').format('HH:mm A')}&nbsp;&nbsp;-&nbsp;&nbsp;{moment(timings?.workingHours?.closingTime, 'hh:mm').format('HH:mm A')}</Typography>
            }
            {/* <Typography variant='body2' sx={{ marginBottom: '10px' }}>Timings - Tuesday  (Today)</Typography>
                        <Typography variant='body2' className='timing'>9:30 AM - 5:00 PM</Typography> */}
          </TabPanel>
          <TabPanel value="2" sx={{ height: "165px", overflow: "scroll", padding: "10px 20px" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ color: '#5C5C5C' }}>Clinical</Typography>
              <Typography >Qty</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography >Oxygen Ventilator</Typography>
              <Typography>36</Typography>
            </div>
            <Typography sx={{ color: '#5C5C5C', marginTop: '10px' }}>Non Medical</Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Bed</Typography>
              <Typography>36</Typography>
            </div>
          </TabPanel>
          <TabPanel value="3" sx={{ height: "165px", overflow: "scroll", padding: "10px 20px" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography>Specialities Medical Consultation</Typography>
              <Typography><BiChevronRightCircle /></Typography>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  )
}



const Overview = ({ onSelectDepartment, tab, setSelectedDept, onSelectService, setSelectedServ }) => {

  const [about, setabout] = useState(false)
  const [editAbout, setEditAbout] = useState(false)
  const [editAboutText, setEditAboutText] = useState('')
  const userOrg = useSelector(state => state.registration.userOrg);
  console.log(userOrg, "tt")
  const location = useSelector(state => state.registration.location);
  console.log(location, "location")
  const locationL2Data = useSelector(state => state.registration.locationL2Data)
  const dispatch = useDispatch()
  const [allServices, setAllServices] = useState([])
  const [WorkingDays, setWorkingDays] = useState([])
  const [timings, setTimings] = useState({})
  const [activeDay, setActiveDay] = useState(null)
  const [addAmenities, setAddAmenities] = useState(false)
  const [editAmenities, setEditAmenities] = useState(false)
  const [toBeEditId, setToBeEditId] = useState('')
  const [openWebsite, setOpenWebsite] = useState(false)
  const [alert, setAlert] = useState({ open: false, msg: '' })
  const [uploadLoader, setUploadLoader] = useState({ open: false, msg: '', loader: false })
  const [files, setFiles] = useState([])
  const [totalfiles, setTotalFiles] = useState([])
  const { id } = useParams()
  const [images, setImages] = useState([])
  const [editPhoto, setEditPhoto] = useState(false)
  const [delalertPhoto, setDelalertPhoto] = useState(false)
  const [allspecialty, setAllSpeciality] = useState([])
  const [checkedItems, setCheckedItems] = useState([]); // object of checked checkboxes
  const [filteredPhoto, setFilteredPhoto] = useState([])
  const [activeAccordion, setActiveAccordion] = useState(-1)
  const [openHealthDept, setOpenHealthDept] = useState(false);
  const [listOfHealthDept, setListOfHealthDept] = useState([]);
  const [listOfHealthServices, setListOfHealthServices] = useState([]);
  const [openServiceDept, setOpenServiceDept] = useState(false);
  const [showMoreDepts, setShowMoreDepts] = useState(false);
  const [showMoreServices, setShowMoreServices] = useState(false);
  const [hoverEffectforDepts, setHoverEffectforDepts] = useState(false);
  const [hoverEffectforServices, setHoverEffectforServices] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [showRearrangeDepts, setShowRearrangeDepts] = useState(false);
  const [showRearrangeServices, setShowRearrangeServices] = useState(false);





  const html = ``
  const blocksFromHTML = convertFromHTML(html)
  const content = ContentState.createFromBlockArray(blocksFromHTML)
  const editorContent = EditorState.createWithContent(content)
  const [editorState, setEditorState] = useState(editorContent);
  const [markdownContent, setMarkdownContent] = useState('');
  const [hover, setHover] = useState(false);

  let facilityContext = useContext(ContextInfo);

  const textContainerRef = useRef(null);

  const orgData = useSelector((state) => state.registration.userOrg);

  const healthDeptOrg = useSelector(state => state.registration.healthDeptDetails);
  const healthDeptLoc = useSelector(state => state.registration.healthDeptLocationDetails);

  const healthServiceOrg = useSelector(state => state.registration.healthServiceOrgInfo);
  const healthServiceLoc = useSelector(state => state.registration.healthServiceLocInfo);
  const isAdmin = useSelector(state => state.registration?.isAdmin);

  console.log(isAdmin, "ziff")



  const history = useHistory();
  let curPath = history.location.pathname;
  const width_300 = useMediaQuery('(max-width:300px)');


  useEffect(() => {

    setShowMoreDepts(false);
    setShowMoreServices(false);
    setShowAbout(false);

  }, [])

  useEffect(() => {
    if (tab != 2) {
      setSelectedDept(null)
    }
    if (tab != 4) {
      setSelectedServ(null)
    }
  }, [])



  // this below useEffect function call list HealthCare department

  useEffect(() => {

    if (userOrg?.id) {
      D_API.get(`${userOrg?.id}/resource/authorization/docsteth/Organization?partof=${userOrg?.id}&type=dept`).then((res) => {
        console.log(res.data, "7878")
        let sortList = res.data?.entry ? res.data?.entry.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofdepartments/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofdepartments/")?.[0]?.value) : []
        setListOfHealthDept(sortList);
        // setListOfHealthDept(res?.data?.entry);
      }).catch((err) => console.log(err));
    }
  }, [healthDeptLoc, healthDeptOrg, userOrg])


  console.log(listOfHealthDept, "off")

  // useEffect(() => {
  //   if (listOfHealthDept?.length > 0) {
  //     setCopyOfHealthDepts(listOfHealthServices)
  //   } else {
  //     setCopyOfHealthDepts([])
  //   }

  // }, [listOfHealthDept])


  // useEffect(() => {

  //   setShowMoreDepts()

  // }, [showMoreDepts])





  // useEffect(() => {
  //   D_API.get(`${URI.getAllHealthCareServices}${id}`).then((res) => {
  //     console.log(res.data.entry, "v")
  //     setListOfHealthServices(res?.data?.entry);
  //   }).catch((err) => console.log(err))
  // }, [healthServiceOrg, healthServiceLoc])


  useEffect(() => {
    if (userOrg?.id) {
      D_API.get(`${userOrg?.id}/${URI.getHealthCareServiceList}${userOrg?.id}`).then((res) => {
        let sortList = res.data?.entry ? res.data?.entry.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofservices/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofservices/")?.[0]?.value) : []
        setListOfHealthServices(sortList);
        // console.log(listOfHealthServices, "listOfHealthServices")
      }).catch((err) => console.log(err));
    }

  }, [healthServiceOrg, healthServiceLoc, userOrg])



  useEffect(() => {
    if (userOrg?.id) {
      D_API.get(`${userOrg?.id}/${URI.getPermissionForPractitionerRole}${userOrg?.id}`).then((res) => {
        dispatch({
          type: "IS_ADMIN",
          payload: res.data
        })
      }).catch((err) => {
        dispatch({
          type: "IS_ADMIN",
          payload: null
        })
        console.log(err)
      })

    } else {
      dispatch({
        type: "IS_ADMIN",
        payload: null
      })
    }
  }, [userOrg?.id])




  // console.log(curPath, "infacility")

  const getsubOrgDetails = (IbmId) => {
    D_API.get(`${IbmId}/${URI.getFacilitiesOfSubOrg}${IbmId}&type=prov`).then((res) => {
      if (res.data?.entry?.length > 0) {
        let sortList = res.data?.entry?.sort((a, b) => a?.resource?.identifier?.filter((e) => e.system == "https://positionofsuborganization/")?.[0]?.value - b?.resource?.identifier?.filter((e) => e.system == "https://positionofsuborganization/")?.[0]?.value);
        dispatch(fetchSubOrganization(sortList));
      } else {
        dispatch(fetchSubOrganization([]));
      }


    }).catch((err) => console.log(err));

  }

  useEffect(() => {
    if (curPath === `/admin/organization/${id}`) {

      D_API.get(`${userOrg?.id}/${URI.getOrganizationByPageId}pageId/${id}`).then((res) => {
        let orgdataInfo = res.data?.entry?.[0]?.resource;
        if (orgdataInfo?.id) {
          getsubOrgDetails(orgdataInfo?.id)
        } else {
          dispatch(fetchSubOrganization([]));
        }

      }).catch((err) => console.log(err))
    }

  }, [curPath, id])


  // useEffect(() => {
  //   if (userOrg?.id) {
  //     facilityContext.getsubOrgDetails(userOrg?.id);
  //   }

  // }, [userOrg])

  // useEffect(() => {
  //   if (userOrg?.id) {
  //     // let { partOf } = userOrg;
  //     // let Id = partOf?.reference?.split("/")?.[1]
  //     // if (Id != null && Id != undefined && Id != "") {
  //     //   facilityContext.getsubOrgDetails();
  //     // }
  //     facilityContext.getsubOrgDetails(userOrg?.id)

  //   }

  // }, [userOrg])


  const handleAccordion = (index) => {
    if (activeAccordion == index) {
      setActiveAccordion(-1)
      return
    }
    setActiveAccordion(index)
  }

  const handleEditPhoto = () => [
    setEditPhoto(false),
    setCheckedItems([])
  ]

  const deleteIbmPhoto = () => {
    // console.log(filteredPhoto, "hellllllllllllllllllllllllllllllll")
    axios.delete(`https://dev.api.docsteth.com/api/v1/deleteImage/${userOrg?.id}/${UserService.getUserId()}`, { data: filteredPhoto })
      .then(res => {
        // console.log(res)
        getImagePost()
        setEditPhoto(false)
        setDelalertPhoto(false)
        setAlert({ open: true, msg: 'Photo deleted successfully' })

      }).catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    const filteredItems = images?.filter((item, i) => {
      //  return checkedItems[i] == i
      return checkedItems?.includes(i)
    })
    setFilteredPhoto(filteredItems)
  }, [checkedItems])

  const handleChangePhoto = (index) => {
    const value = index
    if (checkedItems?.includes(value)) {
      setCheckedItems(checkedItems.filter((item) => item !== value))
    } else {
      setCheckedItems([...checkedItems, value])
    }
  }

  useEffect(() => {
    defaultSpecialtyVal()
    dispatch(handleUserOrg(userOrg));
  }, [userOrg])


  useEffect(() => {
    D_API.get(`${userOrg?.id}/${URI.gethealthDepartmentByPageId}${id}`).then((res) => {
      console.log(res.data)
      dispatch(gethealthDeptListByPgId(res.data?.entry))

    }).catch((err) => console.log(err));

  }, [])

  const defaultSpecialtyVal = async () => {
    if (userOrg?.id) {
      D_API_FHIR_WITHOUT_TOKEN.get(
        `HealthcareService?organization=${userOrg?.id}`,
        headers()
      ).then((res) => {
        setAllSpeciality(res?.data?.entry?.[0]?.resource?.specialty);

      }).catch((err) => {
        console.log(err);
      });
    }
  };

  const headers = () => {
    return (
      {
        headers: {
          'X-FHIR-TENANT-ID': 'docsteth',
          'X-FHIR-DSID': 'fhirDocsteth',
          'Realm': 'docsteth'
        }
      }
    )
  }

  // console.log(about, "aboutttt")
  const editUpdate = async () => {

    let locationdata = { ...location }

    locationdata.description = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    await D_API.put(`${userOrg?.id}/Location/${location.id}`, locationdata)
      .then(res => {
        dispatch(getLocation(userOrg.id))

      }).catch(err => {
        console.log(err)
      })

    setEditAbout(false)
  }

  const addAmenitiesFn = () => {
    setAddAmenities(!addAmenities)
    if (editAmenities) {
      setEditAmenities(false)
    }
    if (toBeEditId) {
      setToBeEditId("")
    }
  }

  const editAmenitiesFn = (id) => {
    setToBeEditId(id)
    setEditAmenities(true)
    setAddAmenities(true)
  }

  // const addWebsite = () => {
  //     setOpenWebsite(!openWebsite)
  // }

  useEffect(() => {
    if (location?.id) {
      D_API.get(`${userOrg?.id}/Location/partof/${location.id}?count=50&page=1`)
        .then(res => {
          // setAllServices(res.data.entry)
          dispatch({
            type: 'Location_L2_Data',
            payload: res.data.entry
          })
        })
    }
  }, [location])

  useEffect(() => {

    if (location) {
      let data = []
      const date = new Date()
      const today = days[date.getDay() - 1]
      {
        location?.hoursOfOperation?.map((item, i) => {
          let openingTime = item.openingTime
          let closingTime = item.closingTime
          if (item.allDay == true) {
            (item?.daysOfWeek?.map((child) => {
              data.push({ day: child, allDay: item.allDay, today: child == today ? true : false })
            }))
          } else if (item.allDay == false) {
            (item.daysOfWeek?.map((child) => {
              data.push({ day: child, allDay: item.allDay, today: child == today ? true : false })
            }))
          } else {
            (item.daysOfWeek.map((child) => {
              data.push({ day: child, workingHours: [{ openingTime: openingTime, closingTime: closingTime }], today: child == today ? true : false })

            }))

          }
        })
      }

      const mergedSchedule = [];

      for (let i = 0; i < data.length; i++) {
        let day = data[i].day;
        let hours = data[i].workingHours;

        let existingDay = mergedSchedule.find(item => item.day === day);
        // console.log(existingDay, "existingDay")
        if (existingDay) {
          existingDay.workingHours = existingDay.workingHours.concat(hours);
          // console.log(existingDay, "existingDay11")

        } else {
          mergedSchedule.push(data[i]);
        }
      }

      const sorted = mergedSchedule.sort((a, b) => {
        return daysCompare[a.day] - daysCompare[b.day];
      })

      setWorkingDays(sorted)

      // const rawHTML = marked(location?.description);
      // const plainText = DOMPurify.sanitize(rawHTML, { ALLOWED_TAGS: [] });
      // console.log(rawHTML, "plainText", plainText)
      // setEditAboutText(location?.description || "");


      setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(location?.description || `<p></p>`))))

      setEditAboutText(location?.description)


      // if (about == false) {
      //   let filterData = location?.description;
      //   // let filterData = location?.description?.slice(0, 400);
      //   setEditAboutText(filterData);
      // } else {
      //   setEditAboutText(location?.description)
      // }


    }

  }, [location, about])

  useEffect(() => {
    const date = new Date()
    const today = days[date.getDay() - 1]
    WorkingDays?.find((item) => {
      if (item.day == today) {
        // console.log(item)
        setTimings(item)
      }
    })
  }, [WorkingDays])

  const uploadPhoto = (e, fileType) => {
    setTotalFiles(e.target.files.length)
    if (e.target.files.length > 0) {
      let files = []
      if (e.target.files.length < 6) {
        for (let i = 0; i < e.target.files.length; i++) {
          let types = e.target.files[i].type.split("/")[1]
          const eTypes = types === 'jpg' || types === "png" || types == "jpeg"
          const imgTypes = eTypes || types == "gif"

          if (fileType == "photo" ? imgTypes : types === "mp4") {
            const reader = new FileReader()
            // console.log(reader, "reader")
            reader.readAsDataURL(e.target.files[i])

            reader.onloadend = () => {
              let base64 = reader.result.split(",")[1].split("=")[0]

              const [, type] = reader.result.split(";")[0].split('/');

              setFiles((prev) => {
                return [...prev, { file: base64, fileType: type }]
              })
            }
          }
          else {
            setAlert({ open: true, msg: 'Only .jpg, .png, .jpeg, .gif extensions allowed' })
          }
        }
      }
    } else {
      setAlert({ open: true, msg: 'Can upload only upto 5 images' })
    }
  }

  useEffect(() => {
    if (totalfiles === files?.length) {
      handlePost(files)

    }
  }, [files])

  const handlePost = (files) => {
    setUploadLoader({ open: true, msg: "uploading ...", loader: true, })
    if (userOrg.id) {
      // D_API.post(`https://devwebhooks.docsteth.com/api/v1/uploadfile/${userOrg.id}/${UserService.getUserId()}`, files, {
      // axios.post(`https://dev.api.docsteth.com/api/v1/uploadImage/${UserService.getUserId()}/${userOrg.id}`, files, {
      axios.post(`${process.env.REACT_APP_API_URL}v1/uploadImage/${UserService.getUserId()}/${userOrg.id}`, files, {
        headers: { Authorization: `bearer ${UserService.getToken()}` },
      }).then(res => {
        // console.log(res)
        setUploadLoader({ open: true, msg: "uploaded successfully", loader: false })
        setFiles([])
        getImagePost()
      }).catch(err => {
        console.log(err)
        setUploadLoader({ open: true, msg: "uploading failed, try again.", loader: false })
      })
    }
  }

  const getImagePost = useCallback(() => {
    if (userOrg?.id) {

      // D_API.get(`https://devwebhooks.docsteth.com/api/v1/getImage/${userOrg.id}/${UserService.getUserId()}`, {
      axios.get(`${process.env.REACT_APP_API_URL}v1/getImagesByOrgIdAndUsrId/${UserService.getUserId()}/${userOrg?.id}`, {
        headers: { Authorization: `bearer ${UserService.getToken()}` },
      }).then(res => {
        if (res.data?.length > 0) {

          setImages(res.data)
        } else {
          setImages([])
        }
      }).catch(err => {
        // console.log(err)
      })
    }
  }, [images])
  // console.log(images, "007")

  useEffect(() => {
    getImagePost()
  }, [userOrg])

  const d = new Date();

  let isToday = days[d.getDay() - 1]
  // console.log(isToday)

  // handle open and close for add health dept Dialog
  const handleaddHealthDeptDialog = () => {
    setOpenHealthDept((prev) => !prev);
    dispatch(handleHealthDeptLoc({}));
    dispatch(handleHealthDeptOrg({}));
  }

  // handle open and close for add health service Dialog
  const handleAddServiceDeptDialog = () => {
    setOpenServiceDept((prev) => !prev);
    dispatch(handleHealthServiceLoc({}));
    dispatch(handleHealthServiceOrgInfo({}));
  }

  const showMoreCopyOfHealthDepts = () => {
    setShowMoreDepts(true)
  }

  const showLessCopyOfHealthDepts = () => {
    setShowMoreDepts(false)
  }

  const showMoreCopyOfHealthService = () => {
    setShowMoreServices(true)
  }

  const showLessCopyOfHealthService = () => {
    setShowMoreServices(false)
  }

  console.log(showMoreDepts, "tuv")


  const DeleteAddwebsite = () => {
    let result = userOrg?.endpoint?.[0]?.display.toLowerCase()?.split(".");
    let websiteName = result[0]

    // let data = { ...userOrg, endpoint: [] };

    axios.delete(`${URL.deleteWebsite}${websiteName}`).then((res) => {
      console.log(res.data.status)
      if (res.status == 200) {
        let data = { ...userOrg, endpoint: [] };
        D_API.put(`${userOrg?.id}/${URI.updateOrg}${userOrg?.id}`, data).then((res) => console.log(res.data)).catch((err) => console.log(err))
      }
    }).catch((err) => console.log(err))
  }
  const editState = (editorState) => {
    setEditorState(editorState)
  }

  const handleOpenRearrangeDeptDialog = () => {
    setShowRearrangeDepts(true)

  }

  const handleClsRearrangeDeptDialog = () => {
    setShowRearrangeDepts(false)
  }



  const handleOpenRearrangeServiceDialog = () => {
    setShowRearrangeServices(true);
  }
  const handleClsRearrangeServiceDialog = () => {
    setShowRearrangeServices(false);
  }

  let data = location?.description;
  let tempElement = document.createElement('div');
  tempElement.innerHTML = data;
  let textContent = tempElement?.textContent || tempElement?.innerText;
  // console.log(textContent, "textContent");

  // let descriptionText = '';

  // if (location && location.description) {
  //   descriptionText = location.description.replace(/<[^>]+>/g, '');
  // } else {
  //   descriptionText = "No description available.";
  // }

  // console.log(descriptionText, "descriptionEditorState1");
  // const description = location?.description;

  // const editorStated = EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(description)));
  // console.log("parsedDescription")
  const check = "<p>hello <strong>world </strong><strong><em>welcomes you </em></strong><del><strong><em>strikeed cod</em></strong></del><strong><em> value </em></strong><em>about</em></p> <ul> <li><em>world</em></li> <li><em>new </em><strong><em>host</em></strong></li> <li><span>live check 4<sub>3</sub></span></li> </ul> <ol> <li><span>numbers</span></li> <ul> <li><span>cercless</span></li> </ul> </ol>"
  const htmlToMarkdown = (htmlString) => {
    const turndownService = new TurndownService();
    return turndownService.turndown(htmlString);
  };
  const handleClose = () => {
    setEditAbout(false)
  }
  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Data1 = reader.result.split(",")[1];

        // const base64Data = reader.result.replace("data:image/png;base64,", "");
        const type = file.type.split("/")[1];
        // console.log(base64Data1, "basebase")
        const payload = {
          file: [base64Data1],
          fileType: type,
          multiple: false,
          subType: "profilePic",
          type: "userProfilePics"
        };

        axios.post(`${URL.uploadFile}`, payload, {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('kctoken')}`,
            'Content-Type': 'application/json'
          }
        }).then(response => {

          console.log(response, "rereddss")
          resolve({ data: { link: response.data?.data?.[0] } });
        }).catch(error => {
          reject(error);
        });
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };
  const handle = () => {
    console.log("sai")
  }








  return (
    <Card sx={{
      // p: { xs: "10px", sm: " 10px ", },
      boxShadow: "none", backgroundColor: "#F4F2EE",
      padding: "0 1px"
    }}>

      <Grid
        container
        spacing={5}

      // sx={{boxShadow:"0px 1px 1px "}}
      >
        {addAmenities && (
          <AddAmenities
            close={addAmenitiesFn}
            editAmenities={editAmenities}
            toBeEditId={toBeEditId}
          />
        )}
        {/* {openWebsite && <AddWebsite close={addWebsite} />} */}
        {/* <Grid item xs={12} sm={12} md={3}>
                <Card sx={{ padding: '1rem' }}>
                    <Typography variant='body2' sx={{ margin: '0' }}>Organization Type</Typography>
                    <Typography sx={{ marginBottom: '12px !important', fontWeight: '500', fontSize: '18px' }}>{userOrg?.type?.[0]?.coding?.[0]?.display}</Typography>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: '13px' }}><PublicIcon sx={{ color: '#01CDE5' }} fontSize='small' /><Typography sx={{ color: '#5C5C5C' }}>Website</Typography></div>
                    {
                        userOrg?.endpoint?.[0]?.display ?
                            <>
                                <a href={`https://${userOrg?.endpoint?.[0]?.display.toLowerCase()}`} target='_blank' style={{ fontStyle: 'italic', fontSize: '16px', color: '#6F94E6' }}>{userOrg?.endpoint?.[0]?.display.toLowerCase()} </a>
                                
                            </>
                            :
                            <Button sx={{ boxShadow: 2, width: "100%", minWidth: "25px", marginTop: "10px", textTransform: 'capitalize' }} onClick={addWebsite}>Add Website</Button>
                    }

                    <div style={{ display: 'flex', gap: '10px', margin: '12px 0' }}>
                        <Card sx={{ display: 'flex', flex: 1 }} ><a className='call' href={`tel:${userOrg?.telecom?.[0]?.value}`}><img width="24px" height='17px' src={call} />Call</a></Card>
                        <Card sx={{ display: 'flex', flex: 1 }} ><a className='call' href={`mailto:${userOrg?.telecom?.[1]?.value}`}><img width="24px" height='17px' src={mail} />Email</a></Card>
                    </div>

                    <Card sx={{ margin: '12px 0' }}><a className='direction' target='_blank' href={`https://www.google.com/maps?q=${userOrg?.address?.[0]?.line[3]?.split(",")[0]},${userOrg?.address?.[0]?.line[3]?.split(",")[1]}`}><img width="24px" height='17px' src={mail} /><Typography sx={{ color: '#0243D6', fontWeight: '500', }}>Get directions</Typography></a></Card>

                    <div className='workinghours'><img width="24px" height='17px' src={org} /><Typography sx={{ color: '#5C5C5C' }}>Working Hours</Typography></div>

                    <div style={{ display: 'flex', gap: '5px', overflowY: 'scroll', margin: '12px 0' }}>
                        {WorkingDays?.map((item, i) => {

                            // return <Button variant='contained' onClick={() => { setTimings(item); setActiveDay(i) }} disabled={!item.allDay && !item.hasOwnProperty('workingHours')} sx={{ minWidth: "25px", background: activeDay == i && activeDay !== null ? "black" : item.today && activeDay == null ? "black" : '', padding: '0px 7px', }}><Typography variant="caption" sx={{ color: 'white', textTransform: 'capitalize' }}>{item.day}</Typography></Button>
                            return <Button key={i} variant='contained' onClick={() => { setTimings(item); setActiveDay(i) }} sx={{ minWidth: "25px", background: activeDay == i && activeDay !== null ? "black" : item.today && activeDay == null ? "black" : !item.allDay && !item.hasOwnProperty('workingHours') ? "#A9A9A9" : '#197FC4', padding: '0px 7px' }}><Typography variant="caption" sx={{ color: 'white', textTransform: 'capitalize' }}>{item.day}</Typography></Button>
                        })}
                    </div>


                    {timings.day &&
                        <Typography variant='body2' sx={{ marginBottom: "5px" }}>
                            Timings - {timings?.day == "mon" ? "Monday" : timings?.day == "tue" ? "Tuesday" : timings?.day == "wed" ? "Wednesday" : timings?.day == "thu" ? "Thursday" : timings?.day == "fri" ? "Friday" : timings?.day == "sat" ? "Saturday" : timings?.day == "sun" ? "Sunday" : ""} {timings?.day == isToday ? "(Today)" : ""}
                        </Typography>
                    }
                    {

                        timings && timings.allDay ?
                            <Typography variant='body2' className='timing' >All Day</Typography>
                            : timings.allDay == false ? <Typography variant='body2' className='timing'>No Slot avalable</Typography>
                                : timings?.workingHours && timings?.workingHours?.map((item, ind) => {
                                    return <Typography key={ind} mb={2} sx={{ marginBottom: "5px !important" }} variant='body2' className='timing'>{moment(item?.openingTime, 'hh:mm').format('HH:mm A')}&nbsp;&nbsp;-&nbsp;&nbsp;{moment(item?.closingTime, 'hh:mm').format('HH:mm A')}</Typography>
                                })
                    }
                </Card>
            </Grid> */}
        {/* <Grid item xs={12} sm={12} md={9} > */}
        <Grid item xs={12} sm={12} md={12} >
          {/* <Typography mt={2} variant='h5' sx={{ color: '#6E5989', fontWeight: "600" }}>About Hospital</Typography> */}
          <Card className='mt1-5'
            style={{
              padding: '0px 0px',

              // margin: "10px",
              // padding: '0px 10px',
              borderRadius: "10px",
              // width: "59vw",
              // width: "100vw"
              // width: "100vw",
              boxShadow: "0px 0px 1.6px #888",

            }}
          >
            <CardContent>
              <Typography
                variant="p"
                sx={{
                  color: "#3084BE",
                  fontSize: "18px",
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                About Hospital &nbsp;&nbsp;
                <>
                  {
                    isAdmin === "PERMIT" &&
                    <>
                      {
                        location?.id ?
                          <EditIcon
                            sx={{ cursor: "pointer", }}
                            onClick={() => {
                              if (location?.id) {
                                setEditAbout(!editAbout);
                                setEditAboutText(location?.description);
                              }
                            }}
                            fontSize={"small"}
                          />
                          :
                          <Tooltip title="Complete your Edit Page profile" arrow>
                            <EditIcon
                              sx={{ cursor: "pointer", }}
                              fontSize={"small"}
                            />
                          </Tooltip>
                      }
                    </>
                  }


                </>
              </Typography>
              {/* {editAbout && (
                <div
                  style={{
                   
                  }}
                >
               
                  <Editor
                    editorStyle={{
                      overflow: 'auto',
                      height: 'auto',
                    }}
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={(editorState) => editState(editorState)}
                  />
                  <Button
                    fontSize="small"
                    sx={{ float: 'right', p: 1, m: 1 }}
                    variant="contained"
                    disabled={editAboutText?.length == 0}
                    onClick={editUpdate}
                  >
                    Update
                  </Button>
                </div>
              )} */}
              {editAbout && (
                <Dialog
                  // fullWidth="lg"
                  maxWidth={"md"}
                  open={editAbout}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"About Hospital"}
                  </DialogTitle>
                  {/* <IconButton
                    onClick={handleClose}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                    }}
                  >
                    <Close />
                  </IconButton> */}
                  <DialogContent sx={{ padding: '2px 5px' }}>
                    {/* <TextField
                    fontSize="small"
                    fullWidth
                    multiline
                    sx={{ marginTop: "10px" }}
                    value={editAboutText}
                    onChange={(e) => setEditAboutText(e.target.value)}
                  /> */}
                    <Editor
                      editorStyle={{
                        // overflow: 'hidden',
                        textOverflow: 'hidden',
                        height: '250px',
                      }}
                      editorState={editorState}
                      toolbar={{
                        // inline: { inDropdown: true },
                        // list: { inDropdown: true },
                        // textAlign: { inDropdown: true },
                        // link: { inDropdown: true },
                        // history: { inDropdown: true },
                        image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
                      }}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={(editorState) => editState(editorState)}
                    />
                  </DialogContent>
                  <DialogActions >
                    <Button
                      fontSize="small"
                      // sx={{ float: 'right', p: "", m: "" }}
                      variant="outlined"
                      color="error"
                      onClick={handleClose}
                      className="capitalize"
                    >
                      cancel
                    </Button>
                    <Button
                      fontSize="small"
                      sx={{ float: 'right', p: 1, m: 1 }}
                      variant="contained"
                      className="capitalize"
                      disabled={editAboutText?.length == 0}
                      onClick={editUpdate}
                    >
                      Update
                    </Button>
                  </DialogActions>




                </Dialog>
              )}


              {!editAbout && location?.description && (
                <Typography my={1}

                >
                  <div
                    dangerouslySetInnerHTML={{ __html: editAboutText }}
                    // style={{ whiteSpace: 'pre-wrap' }}
                    style=
                    {{
                      display: !showAbout ? '-webkit-box' : 'block',
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      WebkitLineClamp: 2, // Set this to the number of lines you want to display
                      whiteSpace: 'pre-wrap'
                    }}

                  />

                </Typography>
              )}



            </CardContent>


            {/* <Divider sx={{ borderBottomWidth: 5, borderRadius: 20, color: '#F7F7F7' }} />
            <CardActions>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "transparent",
                  color: "#A3A3A3",
                  boxShadow: "none",
                  textTransform: "capitalize",
                  marginX: "auto",
                  alignItems: "center",
                  "&:hover": {
                    backgroundColor: "transparent"
                  }
                }}
              >
                Show All Details
                <ArrowRightAltIcon />
              </Button>
            </CardActions> */}


            <Divider />
            <div type="label" style={{ textAlign: 'center' }}>
              {location?.description?.length > 400 && (

                <div
                  style={{
                    padding: '8px', cursor: 'pointer', fontWeight: 'bold', backgroundColor: "white",
                    // background: hover ? "#F4F2EE" : ""
                  }}
                  onClick={() => setabout(!about)}
                // onMouseEnter={() => setHover(true)}
                // onMouseLeave={() => setHover(false)}
                >
                  {/* {editAboutText?.length > 400 ? */}
                  {
                    !showAbout ?
                      <div className='flx-center justify-center' onClick={() => setShowAbout(true)} >
                        <div  >
                          Show More
                        </div>
                        <KeyboardArrowDown style={{ marginTop: '3px' }} />
                      </div>
                      :
                      <div className='flx-center justify-center' onClick={() => setShowAbout(false)}>
                        <div > Show Less </div>
                        <KeyboardArrowUp style={{ marginTop: '3px' }} />
                      </div>
                  }

                  {/* <div className='flx-center justify-center'>
                    <div onClick={() => setShowAbout(true)}> Show less </div>
                    <KeyboardArrowUp style={{ marginTop: '3px' }} />
                  </div>
                  : <div className='flx-center justify-center'>
                    <div onClick={() => setShowAbout(false)} >
                      Show More
                    </div>
                    <KeyboardArrowDown style={{ marginTop: '3px' }} />
                  </div> */}
                  {/* } */}

                </div>

              )}
            </div>
          </Card>

          {/* <Card sx={{ mt: 1 }}>
            <div
              className="markdown-body"
              style={{ width: '100%', height: '30vh', padding: '10px', overflowY: 'auto', borderLeft: '1px solid #ddd' }}
            >
              <ReactMarkdown>{htmlToMarkdown(editAboutText || "")}</ReactMarkdown>
            </div>
          </Card> */}


          {/* Specialities */}


          {/* {allspecialty?.length > 0 && (
            <>
              <Typography
                variant="h6"
                sx={{
                  color: "#750083",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  margin: "12px 0",
                  marginTop: "15px !important",
                }}
              >
                <img src={Stethoscope} />
                &nbsp;&nbsp;Specialities
              </Typography>
              <Grid container mt={1} mb={3} spacing={2}>
                {allspecialty?.map((item, index) => (
                  <Grid item md={2}>
                    {console.log(item)}
                    <Box className="box">
                      <img
                        src={Stethoscope}
                        alt="Stethoscope"
                        width="30%"
                        height="30%"
                      />
                      <Typography
                        sx={{
                          position: "absolute",
                          bottom: "10px",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {item.text}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </>
          )} */}


          {/* old code important for future */}
          {/* <Typography
            variant="h6"
            sx={{
              color: "#750083",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              margin: "12px 0",
              marginTop: "15px !important",
            }}
          >
            <img src={AMINITIES} />
            &nbsp;&nbsp;Service Area
          </Typography> */}

          {/* <Grid container spacing={2}>
            {locationL2Data?.map((item, index) => {
              return (
                <Grid key={index} item xs={12} sm={12} md={6}>
                  <Card>
                    <CardContent>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "15px",
                            marginBottom: "8px",
                          }}
                        >
                          <Tooltip
                            title={item?.resource?.name}
                            placement="top-start"
                          >
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: "600",
                                fontSize: "18px",
                                width: "300px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.resource?.name}
                            </Typography>
                          </Tooltip>
                          {item?.resource?.status && (
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                color: "#0B6F07",
                              }}
                            >
                              <TaskAltIcon fontSize="small" />
                              &nbsp;{item?.resource?.status}
                            </Typography>
                          )}
                        </div>
                        <img
                          src={Edit}
                          height="18"
                          alt="edit"
                          onClick={() => editAmenitiesFn(item?.resource?.id)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          height: "2em",
                        }}
                      >
                        <Typography sx={{ overflow: "hidden" }}>
                          {item?.resource?.name}
                        </Typography>
                        <div style={{ display: "flex", gap: "15px" }}>
                          <img src={call} alt="call" />
                          <img src={mail} alt="call" />
                        </div>
                      </div>
                    </CardContent>
                    <Button
                      onClick={() => handleAccordion(index)}
                      sx={{
                        display: activeAccordion == index ? "none" : "block",
                        width: "100%",
                        borderRadius: 0,
                        background: "rgba(52, 143, 198, 0.1)",
                        color: "#197FC4",
                        ":hover": {
                          background: "rgba(52, 143, 198, 0.1)",
                          color: "#197FC4",
                        },
                        textTransform: "capitalize",
                      }}
                      variant="contained"
                    >
                      Learn More
                    </Button>
                    <Box
                      sx={{
                        display: activeAccordion == index ? "block" : "none",
                      }}
                    >
                      <TabsComponent
                        hoursOfOperation={item.resource.hoursOfOperation}
                        id={item.resource.id}
                      />
                    </Box>
                  </Card>
                </Grid>
              );
            })}

            <Grid item xs={12} sm={12} md={12}>
              <Card sx={{ padding: "10px" }} onClick={() => addAmenitiesFn()}>
                <Typography
                  variant="h6"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#0B6F07",
                    fontWeight: "500",
                    cursor: 'pointer'
                  }}
                >
                  <AddCircleRoundedIcon />
                  &nbsp;Service Locations & Aminities
                </Typography>
              </Card>
            </Grid>
          </Grid> */}

          {/* old code end */}


          {/* healthcare department start */}
          <div className='mt1-5' sx={{}}>
            <Card sx={{ padding: "10px !important", borderRadius: "10px", boxShadow: "0px 0px 1.6px #888" }} >

              <div className='flx  justify-between' style={{ height: "40px", border: "" }}>
                <div style={{ color: "#3084BE", fontSize: "18px", fontWeight: 600 }}>
                  Healthcare Department
                </div>
                <div className='flx  justify-between'>
                  {
                    (listOfHealthDept?.length >= 2 && isAdmin === "PERMIT") && <MenuIcon className="cursor" sx={{ width: "20px", height: "20px", color: "#750083", '.MuiSvgIcon-root': { width: "20px", height: "20px" } }}
                      onClick={handleOpenRearrangeDeptDialog} />
                  }
                  {isAdmin === "PERMIT" && <AddCircleRoundedIcon className="cursor" sx={{ display: "flex", alignItem: "center", color: "#750083", width: "20px", height: "20px", '.MuiSvgIcon-root': { width: "20px", height: "20px" }, paddingLeft: "10px" }}
                    onClick={handleaddHealthDeptDialog} />}
                </div>
              </div>

              <div style={{ width: "100%" }}>
                {
                  <Grid container spacing={2}>
                    {
                      listOfHealthDept?.length > 0 && listOfHealthDept?.slice(0, showMoreDepts ? undefined : 4).map((val) => {

                        return (
                          <Grid item xs={12} sm={12} md={6} lg={6} >
                            <Card sx={{ padding: "5px", boxShadow: "0px 0px 1.6px #888", borderRadius: "", height: { sm: "45px", md: "45px", lg: "45px" } }} className='mv1 cursor' onClick={() => {
                              onSelectDepartment(val.resource)
                              // history.push({
                              //   pathname: `/admin/organization/${val?.resource?.id}/healthDepartment`,
                              //   state: val
                              // }
                              // );
                              // dispatch(getUserOrg(userOrg))
                            }

                            } >

                              <div style={{ display: "flex", gap: "10px" }}>
                                <div>
                                  <Avatar variant="square" sx={{
                                    width: '40px !important', height: '40px !important',

                                  }}
                                    src={val?.resource?.identifier?.filter((val) => val["system"] == `${process.env.REACT_APP_API_URL}v1/logo` && val["value"]?.startsWith("https"))?.[0]?.value || HealthDeptIcon} />
                                </div>

                                <div style={{ height: "40px", width: "100%" }}>
                                  <div className='fntMd capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.name}</div>
                                  <div className='fntXSm capitalize' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.alias?.[0]}</div>
                                </div>
                              </div>
                            </Card>
                          </Grid>
                        )
                      }
                      )
                    }

                  </Grid>
                }
              </div>

              {/* <Divider /> */}

              <div type="label" style={{ textAlign: 'center' }}>
                {listOfHealthDept?.length > 4 && (
                  <>
                    <Divider />
                    <div style={{ paddingTop: '5px', cursor: 'pointer', fontWeight: 'bold', background: hoverEffectforDepts ? "#F4F2EE" : "" }}

                    // onMouseEnter={() => setHoverEffectforDepts(true)}
                    // onMouseLeave={() => setHoverEffectforDepts(false)}
                    >
                      {showMoreDepts ?
                        <div className='flx-center justify-center' onClick={showLessCopyOfHealthDepts}>
                          <div> Show Less</div>
                          <KeyboardArrowUp style={{ marginTop: '3px' }} />
                        </div>
                        :
                        <div className='flx-center justify-center' onClick={showMoreCopyOfHealthDepts} >
                          <div> Show More </div>
                          <KeyboardArrowDown style={{ marginTop: '3px' }} />
                        </div>
                      }
                    </div>
                  </>
                )}
              </div>


              <AddHealthcareDept opn={openHealthDept} cls={handleaddHealthDeptDialog} />
            </Card>
          </div>

          {/* healthcare department end */}



          {/* healthcare service start */}


          <div className='mt2'>
            <Card sx={{ padding: "10px", borderRadius: "10px", boxShadow: "0px 0px 1.6px #888", }} >
              <div className='flx  justify-between' style={{ height: "40px", border: "" }}>
                <div style={{ color: "#3084BE", fontSize: "18px", fontWeight: 600 }}>
                  {/* Healthcare Services */}
                  Clinical Specialties
                </div>
                <div className='flx  justify-between'>
                  {
                    (listOfHealthServices?.length >= 2 && isAdmin === "PERMIT") && <MenuIcon className="cursor" sx={{ width: "20px", height: "20px", color: "#750083", '.MuiSvgIcon-root': { width: "20px", height: "20px" } }} onClick={handleOpenRearrangeServiceDialog} />
                  }
                  {isAdmin === "PERMIT" && <AddCircleRoundedIcon className="cursor" sx={{ display: "flex", alignItem: "center", color: "#750083", width: "20px", height: "20px", '.MuiSvgIcon-root': { width: "20px", height: "20px" }, paddingLeft: "10px" }} onClick={handleAddServiceDeptDialog} />}
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <Grid container spacing={2}>
                  {
                    listOfHealthServices?.length > 0 && listOfHealthServices?.slice(0, showMoreServices ? undefined : 4)?.map((val) => {
                      return (
                        <Grid item xs={12} sm={12} md={6} lg={6} >
                          <Card sx={{ padding: "5px", borderRadius: "", boxShadow: "0px 0px 1.6px #888", height: { sm: "45px", md: "45px", lg: "45px" } }} className='mv1 cursor '

                            onClick={() => {
                              onSelectService(val.resource)
                              // history.push({
                              //   pathname: `/admin/organization/${val?.resource?.id}/healthService`,
                              //   state: val
                              // });
                              // dispatch(getUserOrg(userOrg))
                            }
                            }

                          >
                            <div style={{ display: "flex", gap: "10px" }}>
                              <div>
                                <Avatar variant="square" sx={{

                                  width: '40px !important', height: '40px !important',

                                }} src={val?.resource?.photo?.url || DefaultHealthServiceIcon} />
                              </div>

                              <div style={{ height: "40px", width: '100%' }}>
                                <div className='fntMd capitalize ' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.name}</div>
                                <div className='fntXSm capitalize ' style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: `calc(100% - 50px)` }}>{val?.resource?.comment}</div>

                              </div>
                            </div>
                          </Card>
                        </Grid>

                      )
                    })

                  }
                </Grid>
              </div>
              {/* <Divider /> */}

              <div type="label" style={{ textAlign: 'center' }}>
                {listOfHealthServices?.length > 4 && (
                  <>
                    <Divider />
                    <div style={{ paddingTop: '5px', cursor: 'pointer', fontWeight: 'bold', background: hoverEffectforServices ? "#F4F2EE" : "" }}

                    // onMouseEnter={() => setHoverEffectforServices(true)}
                    // onMouseLeave={() => setHoverEffectforServices(false)}

                    >
                      {showMoreServices ?
                        <div className='flx-center justify-center' onClick={showLessCopyOfHealthService}>
                          <div> Show Less</div>
                          <KeyboardArrowUp style={{ marginTop: '3px' }} />

                        </div>
                        :
                        <div className='flx-center justify-center' onClick={showMoreCopyOfHealthService} >
                          <div> Show More </div>
                          <KeyboardArrowDown style={{ marginTop: '3px' }} />
                        </div>
                      }
                    </div>
                  </>

                )}
              </div>
              <AddHealthServiceDept opn={openServiceDept} cls={handleAddServiceDeptDialog} />
            </Card>





          </div>

          <Grid container md={12} className='mt2' >
            <Card sx={{ width: "100%", padding: "5px 0 0 10px", boxShadow: "0 0 1.6px #888" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  margin: "12px 0",
                }}
              >
                <Typography variant="p" sx={{
                  color: "#3084BE", fontSize: "18px",
                  fontWeight: "600",
                }}>
                  <img src={Hospital} height="18" /> &nbsp;Facility Tour
                </Typography>
                {/* <Box sx={{ boxShadow: 1 }} className='camera'><img src={Camera} alt="camera" />&nbsp;upload</Box> */}
                <input
                  type="file"
                  name="uploadfile"
                  multiple
                  id="orgimg"
                  accept=".png, .jpg, jpeg, .gif"
                  style={{ display: "none" }}
                  onChange={(e) => uploadPhoto(e, "photo")}
                />

                {
                  isAdmin === "PERMIT" &&

                  <div
                    style={{ display: "flex", gap: "20px", alignItems: "center" }}
                  >
                    {/* <Tooltip title="delete" placement="top-start">
                                <IconButton disabled={images.length == 0} onClick={() => setEditPhoto(true)}>
                                    <DeleteIcon sx={{ cursor: "pointer", color: '#3084BE' }} />
                                </IconButton>
                            </Tooltip> */}
                    {/* 
                            <Dialog open={editPhoto} onClose={handleEditPhoto}>
                                <DialogContent>
                                    <DialogContentText>Select</DialogContentText>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: "10px", width: "250px", height: "300px" }}>
                                        {images.map((item, i) => {
                                            return <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                                                <Checkbox checked={checkedItems[i]} onChange={() => handleChangePhoto(i)} />
                                                <img src={item} width={40} height={40} style={{ borderRadius: '8px' }} />
                                            </div>
                                        })}
                                    </div>
                                </DialogContent>
                                <div style={{ display: "flex", justifyContent: "end", gap: "10px", marginTop: "10px", padding: "10px" }}>
                                    <Button size='small' variant='outlined' sx={{ textTransform: "capitalize", height: "30px" }} onClick={() => setEditPhoto(false)}>Cancel</Button>
                                    <Button size='small' variant='outlined' sx={{ textTransform: "capitalize", height: "30px" }} disabled={checkedItems.length == 0} onClick={() => setDelalertPhoto(true)}>Delete</Button>
                                </div>
                            </Dialog>

                            <Dialog open={delalertPhoto} onClose={() => setDelalertPhoto(false)}>
                                <DialogContent sx={{ width: "240px" }}>
                                    <DialogContentText>Do you want to delete ?</DialogContentText>
                                    <div style={{ display: "flex", justifyContent: "end", gap: "10px", marginTop: "10px" }}>
                                        <Button size='small' variant='outlined' sx={{ textTransform: "capitalize", height: "30px" }} onClick={() => setDelalertPhoto(false)}>Cancel</Button>
                                        <Button size='small' variant='outlined' sx={{ textTransform: "capitalize", height: "30px" }} onClick={deleteIbmPhoto}>Yes</Button>
                                    </div>
                                </DialogContent>
                            </Dialog> */}

                    <label htmlFor="orgimg" className="cursor">
                      <Box sx={{ boxShadow: 1 }} className="camera">
                        <img src={Camera} alt="camera" />
                        &nbsp;upload
                      </Box>
                    </label>
                    {uploadLoader.loader && <CircularProgress size={50} />}
                  </div>
                }
              </div>
            </Card>
          </Grid>

          {images?.length > 0 && (
            <Grid container>
              <Grid item xs={12} sm={12} md={9}>
                <Photo
                  url={images}
                  id={id}
                  getImagePost={getImagePost}
                  photoDelAdmin={true}
                />
              </Grid>
            </Grid>
          )}


          {/* healthcare service end */}

          {/* <div >
                {
                  listOfHealthDept?.length > 0 && listOfHealthDept?.map((val) => {

                    return (

                      <Card sx={{ padding: "10px", width: "300px", borderRadius: "" }} className='mv1' onClick={() => {
                        history.push(`/admin/organization/18f09fb332e-acbebb69-c4c2-49b0-89d5-2f845570e786/healthDepartment`);
                      }} >

                        <Grid container alignItems="center" spacing={2}>
                          <Grid item>
                            <img src={val.resource?.identifier?.filter((info) => info["system"].includes("logo") && info["value"])?.[0]?.value || OrgLogo} style={{ width: "50px", height: "50px" }} />
                            
                          </Grid>
                          <Grid item>
                            <div className='fntMd'>{val?.resource?.name}</div>
                            <div className='fntXSm '>{val?.resource?.alias?.[0]}</div>

                          </Grid>

                        </Grid>
                      </Card>

                    )
                  })
                }
              </div> */}

        </Grid>

        <RearrangeDepartmentsDialog open={showRearrangeDepts} close={handleClsRearrangeDeptDialog} departList={listOfHealthDept} updateGetList={setListOfHealthDept} />
        <RearrangingServices open={showRearrangeServices} close={handleClsRearrangeServiceDialog} servicesList={listOfHealthServices} updateGetList={setListOfHealthServices} />

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={2000}
          open={alert.open}
          onClose={() => setAlert({ open: false, msg: "", loader: false })}
          message={alert.msg}
        />
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={2000}
          open={uploadLoader.open}
          onClose={() =>
            setUploadLoader({ open: false, msg: "", loader: false })
          }
          message={uploadLoader.msg}
        />
      </Grid >
    </Card >
  );
}

export default Overview