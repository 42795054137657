import React, { useEffect, useState } from 'react'
import { Typography, Button, Grid, Dialog, Box } from '@mui/material'
import ViewPost from "../../feedposts/viewPost";
import moment from 'moment'
import UserService from "../../../../services/UserService";
import axios from 'axios';
import URL from '../../../apiurl';
import { useDispatch } from 'react-redux';
import { fetchProfile, getMetaData } from '../../../../redux/Actions/actions';
import Snackbar from '@mui/material/Snackbar'
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import DialogBox from '../../../DialogBox/dialog';
import CloseIcon from '@mui/icons-material/Close';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import Switch from '@mui/material/Switch'
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import EventView from './eventView'
import event_icon from '../../../../images/event.svg'
import { AttendBtn } from './eventView'
import { useHistory } from 'react-router-dom';

const AttendEvent = (props) => {

    const [selected, setSelected] = useState(1)
    const [checked, setChecked] = useState(true)
    const [accept, setAccept] = useState(false)

    const selectFun = (index) => {
        setSelected(index)
        if (index === 3) {
            setChecked(false)
        }
        else {
            setChecked(true)
        }
    }

    return (
        <Box sx={{ color: 'black' }}>
            <div className="d-flex profile__top fw__semibold publish__inputOptions">
                Join Event <CloseIcon onClick={props.handleClose} className="cursor" />
            </div>
            <div style={{ padding: '20px 40px' }}>
                <Typography>Are you going to attend this event ?</Typography>
                <ul className="event_list">
                    <li className="newcontact-fieldheader e-align" onClick={() => selectFun(1)}>Yes I'm Attending {selected === 1 ? <DoneOutlinedIcon /> : ''}</li>
                    <li className="newcontact-fieldheader e-align" onClick={() => selectFun(2)}>May be {selected === 2 ? <DoneOutlinedIcon /> : ''}</li>
                    <li className="newcontact-fieldheader e-align" onClick={() => selectFun(3)}>Not Interested {selected === 3 ? <DoneOutlinedIcon /> : ''}</li>
                </ul>
                <Typography style={{ marginTop: '30px' }}>Add Reminder to this event</Typography>
                <div className="normalfont" style={{ marginLeft: '30px' }}>
                    <div className="font">
                        <span>
                            <Switch
                                checked={selected === 3 ? false : checked}
                                onChange={(e) => setChecked(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </span>
                        <span className="">Yes</span></div>
                </div>
                <Typography style={{ marginTop: '30px' }} className="d-flex e-align">
                    <Checkbox className="r-check" sx={{ pl: '0px', mr: 1 }} disableRipple checked={accept} name="check" onChange={(e) => setAccept(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }} />
                    <span>I hereby read and accept the <span className="b-color add-cover cursor">terms and conditions</span> related to event management</span>
                </Typography>

                <Box sx={{ mt: '20px', textAlign: 'right' }}>
                    <Button className="close-article" onClick={props.handleClose}>Cancel</Button>
                    <Button disabled={!accept} className="add-option" onClick={() => selected !== 3 ? props.participate(checked) : props.handleClose} sx={{ mt: '0px!important', borderRadius: '10px!important' }} variant="outlined">Done</Button>
                </Box>
            </div>
        </Box>
    )
}

const EventData = ({ event }) => {
    return (
        <>
            {event.title ?
                <Typography className="font-bold capitalize" style={{ pt: 1, fontSize: '20px' }}>
                    {event.title}
                </Typography> :
                <Typography sx={{ pt: 1 }} className="font-bold capitalize">Event</Typography>}

            {event.organiser ? <Typography className="font-bold"><span dangerouslySetInnerHTML={{ __html: event.organiser }} /> (Organiser)</Typography> : ''}

            <Typography className="d-flex e-align">From&nbsp;&nbsp;{`${moment.utc(event.openingTime).local().format('DD-MM-YYYY | hh:mm A')}`}&nbsp;<InsertInvitationIcon style={{ fontSize: '20px' }} /></Typography>

            {event.closingTime ? <Typography className="d-flex e-align">To&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{moment.utc(event.closingTime).local().format('DD-MM-YYYY | hh:mm A')}&nbsp;<InsertInvitationIcon style={{ fontSize: '20px' }} /></Typography> : ''}

            {event.sponsor ? <Typography className="font-bold"><span dangerouslySetInnerHTML={{ __html: event.sponsor }} /> (Sponsor)</Typography> : ''}

            {event.preregistration ? <Typography variant="string"><a className="add-cover" target="_blank" href={event.registrationform}>Registration Link</a></Typography> : ''}

            {event.paymentlink ? <Typography variant="string"> <a href={event.paymentlink} target="_blank" className="add-cover follow-link">Payment Link</a></Typography> : ''}

            {event.location ? <Typography>Venue Details: {event.location}</Typography> : ''}
        </>
    )
}

export default function Event(props) {
    const event = props.post.eventData
    console.log(event, "mani")
    const storeData = useSelector(state => state.userProfile.userProfile)
    const [open, setOpen] = useState(false)
    const [sub, setSub] = useState(false)
    const [alert, setAlert] = useState({ open: false, msg: '' })
    const [openDialog, setOpenDialog] = useState(false)
    const history = useHistory()

    const dispatch = useDispatch()

    useEffect(() => {
        subscribers()
    }, [])

    const subscribers = () => {
        let subscribers = event.subscribers
        // if (subscribers && subscribers !== null && subscribers.includes(',')) {
        //     subscribers = subscribers.split(',')
        //     subscribers.filter(sub => sub == UserService.getUserId()) ? setSub(true) : setSub(false)
        // }
        if (subscribers && subscribers === Number(UserService.getUserId())) setSub(true)
        else setSub(false)
        // else if (subscribers !== null) {
        //     subscribers == UserService.getUserId() ? setSub(true) : setSub(false)
        // }
    }

    const participate = async () => {
        const data = {
            "eventId": Number(event.id),
            "subscribers": UserService.getUserId(),
            "metadata": await getMetaData(),
            "userData": {
                "fullName": storeData?.practioner?.name[0].text,
                "profilePic": storeData?.practioner?.photo[0]?.url,
                "caption": storeData.captionAndTaglines
            },
        }
        axios.post(`${URL.addSubscriber}`, data, {
            headers: {
                Authorization: `Bearer ${UserService.getToken()}`
            }
        }).then(res => {
            // console.log(res)
            props.getPostById();
            setSub(true)
            dispatch(fetchProfile())
            setOpenDialog(false)
        }).catch(err => {
            setAlert({ open: true, msg: 'Error...Try after sometime' })
            setOpenDialog(false)
        })
    }

    const joinEvent = () => {
        setOpenDialog(true)
    }

    return (
        <div style={{ border: '1px solid lightgrey', borderRadius: '10px', color: 'black', margin: "10px" }}>
            <DialogBox open={openDialog} handleDialog={() => setOpenDialog(false)} style={{ padding: '0px' }}
                Content={<AttendEvent handleClose={() => setOpenDialog(false)} participate={participate} />}
            />
            <Dialog
                scroll={"body"}
                open={open}
                onClose={() => setOpen(false)}
                maxWidth={'lg'}
                fullWidth
            >
                <Grid container direction="row" style={{ height: '600px' }}>
                    <Grid item xs={8} style={{ height: '100%', position: 'relative', backgroundColor: '#1d2226' }} className="elib-right__content">

                        <img style={{ margin: "auto", position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, maxWidth: '100%', maxHeight: '100%' }} src={event.eventImage}></img>

                    </Grid>
                    <Grid item xs={4} style={{ height: '100%', overflow: 'scroll' }}>
                        <ViewPost post={props.post} {...props} onClose={() => setOpen(false)}>
                            <div>
                                <EventView data={event} image>
                                    {props.post.userId == UserService.getUserId() ? <></> :
                                        sub ? <AttendBtn text={props.post.eventData.webinarPlatform !== 'Docsteth' ? 'Attending' : 'Registered'} variant="outlined" fun={() => { }} /> : <AttendBtn text="Register" fun={() => history.push(`/webinar/eventdata-${event.id}`)} />
                                    }
                                </EventView>
                            </div>
                        </ViewPost>
                    </Grid>
                </Grid>
            </Dialog>
            <div>
                <EventView data={event} open={() => setOpen(true)}>
                    {/* </div>

            <div>
                {event.eventImage ? <div style={{ maxHeight: '300px', overflow: 'hidden', borderRadius: props.post.userId == UserService.getUserId() ? '0px 0px 10px 10px' : '0px' }} className="cursor" onClick={() => setOpen(true)}>
                    <img src={event.eventImage} alt="Event Image" style={{
                        maxHeight: '100%', maxWidth: '100%', objectFit: 'cover', width: '100%',
                    }} />

                </div> : ''} */}
                    {props.post.userId == UserService.getUserId() ? <></> : sub ? <AttendBtn text={props.post.eventData.webinarPlatform !== 'Docsteth' ? 'Attending' : 'Registered'} variant="outlined" fun={() => { }} /> : <AttendBtn text="Register" fun={() => history.push(`/webinar/eventdata-${event.id}`)} />}
                </EventView>
            </div>

            {/* {props.post.userId == UserService.getUserId() ? '' : <div style={{ padding: '10px', backgroundColor: '#e8e8e0', justifyContent: 'flex-end', borderRadius: '0 0 10px 10px' }} className="d-flex">

                {sub ? <Button className="evnt-btn add-option" sx={{ pt: 0 }} variant="outlined">Attending</Button> :

                    <Typography component="div"> Do you want to attend webinar? <p className="text-right"><Button className="evnt-btn add-option" variant="outlined" onClick={() => joinEvent()}>Attend</Button></p></Typography>
                }

            </div>} */}
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={2000}
                open={alert.open}
                onClose={() => setAlert({ open: false, msg: '' })}
                message={alert.msg}
            />

        </div>
    )
}