import React, { useState, useRef, useEffect } from 'react';
import { createReactEditorJS } from 'react-editor-js';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Checklist from '@editorjs/checklist';
import ImageTool from '@editorjs/image';
import LinkTool from '@editorjs/link';
import Table from '@editorjs/table';
import { Button, Dialog, DialogActions, DialogTitle, useMediaQuery } from '@mui/material';
import CustomSuperScript from "./custmSuperscriptTool"
import PDFTool from "./pdfTool";
import "./editor.css";
import CreatePost from '../Molecule/Feed/createpost';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { headers } from '../../redux/Actions/connectActions';
import URL from '../apiurl';
const ReactEditorJS = createReactEditorJS();


const EditorComponent1 = ({ handleSaveDraft, imgInfo, dataInfo, titleInfo, toPublishArticle, postDetails }) => {
    const editorRef = useRef(null);
    const [check, setCheck] = useState('');
    const [openDialg, setOpenDialg] = useState(false);
    const [openPublish, setOpenPublish] = useState(false);
    const [successApiStatus, setSuccessApiStatus] = useState(false);
    const [objForBeforePost, setObjForBeforePost] = useState(null);
    const [dataFromSaveData, setDataFromSaveData] = useState(null);


    const matches = useMediaQuery('(max-width:960px)');

    const history = useHistory();

    // useEffect(() => {
    //     setDataFromSaveData({ blocks: htmltoConvert })
    // }, [htmltoConvert]);

    // console.log(dataFromSaveData, "0404", htmltoConvert)


    useEffect(() => {
        let titleInfo = postDetails?.articleData?.articleDescription ? postDetails?.articleData?.articleDescription : '';
        // Function to convert HTML to Editor.js blocks
        const parseHTMLToBlocks = (html) => {
            const parser = new DOMParser();
            const document = parser.parseFromString(html, 'text/html');
            const blocks = [];

            // Parse paragraphs
            document.querySelectorAll('p').forEach((p) => {
                blocks.push({
                    type: 'paragraph',
                    data: { text: p.innerHTML }, // Keep the HTML content of the paragraph
                });
            });

            // Parse unordered lists (for both checklist and regular lists)
            document.querySelectorAll('ul').forEach((ul) => {
                const items = [];
                ul.querySelectorAll('li').forEach((li) => {
                    const isChecked = li.textContent.startsWith('[x]');
                    let text = li.textContent.trim();

                    // Remove the checkbox indicators [ ] or [x] from the text
                    if (isChecked) {
                        text = text.replace('[x]', '').trim(); // Remove '[x]' for checked items
                    } else if (text.startsWith('[ ]')) {
                        text = text.replace('[ ]', '').trim(); // Remove '[ ]' for unchecked items
                    }

                    items.push({ text, checked: isChecked });
                });

                if (ul.querySelector('li')?.textContent.startsWith('[x]')) {
                    blocks.push({ type: 'checklist', data: { items } }); // Checklist format
                } else {
                    blocks.push({
                        type: 'list',
                        data: {
                            items: items.map((e) => e.text), // Regular list format
                        },
                    });
                }
            });

            // Parse links
            document.querySelectorAll('a').forEach((a) => {
                const link = a.href;  // Get the URL from the href attribute
                const text = a.textContent.trim();  // Get the actual link text and trim any extra spaces or characters

                blocks.push({
                    type: 'linkTool',
                    data: {
                        link: link,  // URL from href
                        text: text,  // Cleaned text from anchor tag
                    },
                });
            });

            // Parse images
            document.querySelectorAll('img').forEach((img) => {
                console.log(img.src, "888")
                const src = img?.src;  // Get the image URL from the src attribute
                const alt = img?.alt || '';  // Get the alt text (if available)

                blocks.push({
                    type: 'image',
                    data: {
                        file: {
                            url: src,  // Image source URL
                        },
                        caption: alt,  // Alt text as caption
                    },
                });
            });

            // Parse tables (if you want to convert them to table blocks)
            document.querySelectorAll('table').forEach((table) => {
                const rows = [];
                table.querySelectorAll('tr').forEach((row) => {
                    const cells = [];
                    row.querySelectorAll('td, th').forEach((cell) => {
                        cells.push(cell.innerHTML); // Use innerHTML for content
                    });
                    rows.push(cells);
                });
                blocks.push({
                    type: 'table',
                    data: {
                        content: rows,
                    },
                });
            });

            console.log('Parsed Blocks:', JSON.stringify(blocks, null, 2));
            return blocks;
        };

        // If titleInfo is available, parse the HTML content and update the editor data
        if (titleInfo) {
            const parsedBlocks = parseHTMLToBlocks(titleInfo);
            setDataFromSaveData({ blocks: parsedBlocks })

        }


    }, [postDetails]);

    useEffect(() => {
        let articleId = postDetails?.articleData?.id;
        if (articleId) {
            getArticleById(articleId);
        } else {
            console.error('No article ID available');
        }
    }, [postDetails]);

    useEffect(() => {
        const fetchArticle = async () => {
            let articleId = postDetails?.articleData?.id;
            if (articleId) {
                const articleData = await getArticleById(articleId);
                setObjForBeforePost(articleData);
                // console.log(articleData,"777")
            } else {
                console.error('No article ID available');
            }
        };
        fetchArticle();
    }, [postDetails])


    const getArticleById = async (ids) => {
        try {
            let res = await axios.get(`${URL.getParticularArticleId}${ids}`, headers());
            if (res.status === 200 && res.data) {
                // console.log('API Response:', res.data);
                return res.data;
            } else {
                console.error('Error: Received unexpected response status or empty data');
                return null;
            }
        } catch (error) {
            console.error('Error fetching article:', error);
            return null;
        }
    };



    const handleToPublish = async () => {
        let res;
        try {
            res = await editorRef.current?.saver?.save();
        } catch (error) {

            console.error('Error saving:', error, "fghjhgf");
            return;
        }

        if (!res?.blocks || !Array.isArray(res.blocks)) {
            alert("Please fill Description for your article");
            console.error('No valid blocks found.');
            return;
        }

        const parsedHtml = res.blocks
            .map((block) => {
                const type = block.type;
                const parser = parserOptions[type];
                if (parser) {
                    return parser(block);
                }
                return '';
            })
            .join('');
        console.log(parsedHtml, "editor result", res.blocks)

        setCheck(parsedHtml);

        let result = (async () => {
            try {
                const res = await toPublishArticle(imgInfo, dataInfo, titleInfo, parsedHtml, objForBeforePost);
                console.log(res, "result");
                return res;
            } catch (err) {
                console.log(err);
            }
        })();
        result.then(res => {
            if (res.postStatus == true && res.articleObj.active == true && res.articleObj.id) {
                handleOpenDialg();
                setObjForBeforePost(res.articleObj)
            } else {
                history.push("/");
                setObjForBeforePost(null)
            }
            console.log("Resolved result:", res);  // Handle the resolved result here
        }).catch(err => {
            history.push("/");
            setObjForBeforePost(null)
            console.log("Error:", err);
        });





        // if (statusres == true) {
        //     handleOpenDialg();
        // } else {
        //     alert("Unable to Publish the Article");
        // }

    }


    console.log(objForBeforePost, "iii", openDialg)


    const handleOpenDialg = () => {
        setOpenDialg(true)
    }
    const handleCloseDialg = () => {
        setOpenDialg(false);
    }

    const handleOpenPublish = () => {
        setOpenPublish(true);
        handleCloseDialg();
    }

    const handleClosePublish = () => {
        setOpenPublish(false);
        setObjForBeforePost(null);
    }

    const handleOnChange = (outputData) => {
        editorRef.current = outputData;
    };

    const handleSave = async () => {
        let res;
        try {
            res = await editorRef.current?.saver?.save();
        } catch (error) {

            console.error('Error saving:', error, "fghjhgf");
            return;
        }

        if (!res?.blocks || !Array.isArray(res.blocks)) {
            alert("Please fill Description for your article");
            console.error('No valid blocks found.');
            return;
        }


        const parsedHtml = res.blocks
            .map((block) => {
                const type = block.type;
                const parser = parserOptions[type];
                if (parser) {
                    return parser(block);
                }
                return ''; // Return an empty string if no parser is found for the block type
            })
            .join('');
        console.log(parsedHtml, "editor result", res.blocks)

        setCheck(parsedHtml);
        handleSaveDraft(imgInfo, dataInfo, titleInfo, parsedHtml)
    };





    const parserOptions = {
        header: (block) => `<h${block.data.level}>${block.data.text}</h${block.data.level}>`,
        paragraph: (block) => `<p>${block.data.text}</p>`,
        // linkTool: (block) => `<a href="${block.data.link}" target="_blank">${block.data.text}</a>`,
        table: (block) => {
            const content = block.data.content;
            const headerRow = content[0];
            const dataRows = content.slice(1);
            const thead = `<thead><tr>${headerRow.map((cell) => `<th>${cell}</th>`).join('')}</tr></thead>`;
            const tbody = `<tbody>${dataRows.map((row) => `<tr>${row.map((cell) => `<td>${cell}</td>`).join('')}</tr>`).join('')}</tbody>`;
            return `<table>${thead}${tbody}</table>`;
        },
        list: (block) => `<ul>${block.data.items.map((item) => `<li>${item}</li>`).join('')}</ul>`,
        checklist: (block) => `<ul>${block.data.items.map((item) => `<li>${item.checked ? '[x]' : '[ ]'} ${item.text}</li>`).join('')}</ul>`,
        image: (block) => `<img src="${block.data.file.url}" alt="${block.data.caption}" style="width: 100%; height: 300px;" />`,
        pdf: (block) => {
            return `<a href="${block.data.url}" target="_blank" style="color: blue">To View PDF</a>`;
        },

    };

    console.log(openPublish, "openPublish")

    return (
        <div style={{ height: matches ? '270px' : '450px', position: 'relative' }} className="mb0-5">
            {openPublish ? <CreatePost keystate={"article"} dataOfArticle={objForBeforePost} handleClose={handleClosePublish} /> : ""}
            {/* handleClose={handleClosePublish} */}
            {/* {shareEdit?.open ? <CreatePost postdata={post} handleOpen={shareEdit.name} handleClose={() => { getPostById(post?.id); setShareEdit({ open: false, name: '' }) }} /> : ''} */}
            <div id="coverIdOfEditor" style={{ height: matches ? '220px' : '400px', overflowY: 'scroll' }}>


                {dataFromSaveData ? (
                    <ReactEditorJS
                        id="react-editor-js-19339d30418"
                        className="ce-block__content ce-toolbar__content"
                        ref={editorRef}
                        onChange={handleOnChange}
                        data={dataFromSaveData}
                        tools={{
                            header: {
                                class: Header,
                                inlineToolbar: ['link'],
                            },
                            paragraph: {
                                class: Paragraph,
                                inlineToolbar: true,
                            },
                            list: {
                                class: List,
                                inlineToolbar: true,
                            },
                            checklist: {
                                class: Checklist,
                                inlineToolbar: true,
                            },
                            linkTool: LinkTool,
                            image: {
                                class: ImageTool,
                                config: {
                                    uploader: {
                                        uploadByFile: (file) => Promise.resolve({ success: 1, file: { url: URL.createObjectURL(file) } }),
                                    },
                                },
                            },
                            superscript: {
                                class: CustomSuperScript,
                                inlineToolbar: true,
                            },
                            table: Table,
                            pdf: PDFTool,
                        }}
                    />
                )
                    :
                    (
                        <ReactEditorJS
                            id="react-editor-js-19339d30418"
                            className="ce-block__content ce-toolbar__content"
                            ref={editorRef}
                            onChange={handleOnChange}
                            tools={{
                                header: {
                                    class: Header,
                                    inlineToolbar: ['link'],
                                },
                                paragraph: {
                                    class: Paragraph,
                                    inlineToolbar: true,
                                },
                                list: {
                                    class: List,
                                    inlineToolbar: true,
                                },
                                checklist: {
                                    class: Checklist,
                                    inlineToolbar: true,
                                },
                                linkTool: LinkTool,
                                image: {
                                    class: ImageTool,
                                    config: {
                                        uploader: {
                                            uploadByFile: (file) => Promise.resolve({ success: 1, file: { url: URL.createObjectURL(file) } }),
                                        },
                                    },
                                },
                                superscript: {
                                    class: CustomSuperScript,
                                    inlineToolbar: true,
                                },
                                table: Table,
                                pdf: PDFTool,
                            }}
                        />
                    )
                }

                {/* {
                    dataFromSaveData &&
                    (
                    <ReactEditorJS
                        id="react-editor-js-19339d30418"
                        className="ce-block__content ce-toolbar__content"
                        ref={editorRef}
                        onChange={handleOnChange}
                        data={dataFromSaveData}
                        tools={{
                            header: {
                                class: Header,
                                inlineToolbar: ['link'],
                            },
                            paragraph: {
                                class: Paragraph,
                                inlineToolbar: true,
                            },
                            list: {
                                class: List,
                                inlineToolbar: true,
                            },
                            checklist: {
                                class: Checklist,
                                inlineToolbar: true,
                            },
                            linkTool: LinkTool,
                            image: {
                                class: ImageTool,
                                config: {
                                    uploader: {
                                        uploadByFile: (file) => Promise.resolve({ success: 1, file: { url: URL.createObjectURL(file) } }),
                                    },
                                },
                            },
                            superscript: {
                                class: CustomSuperScript,
                                inlineToolbar: true,
                            },
                            table: Table,
                            pdf: PDFTool,

                        }}
                    />
                    )
                } */}

            </div>
            {
                objForBeforePost?.id
                    ?
                    <>

                        {
                            matches ?
                                <div className="article-style" style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', bottom: '0px', right: '5px' }}>
                                    <Button variant="outlined" className="" style={{ marginRight: '10px' }} disabled={titleInfo ? false : true} onClick={handleSave}>
                                        Save Draft
                                    </Button>
                                    <Button variant="outlined" disabled={objForBeforePost.id && openDialg ? true : false} onClick={handleToPublish}>
                                        Publish
                                    </Button>
                                </div>
                                :
                                <div className="article-style" style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', bottom: '0px', right: '5px' }}>
                                    <Button variant="outlined" className="" style={{ marginRight: '10px' }} disabled={titleInfo ? false : true} onClick={handleSave}>
                                        Save Draft
                                    </Button>
                                    <Button variant="outlined" disabled={objForBeforePost.id && openDialg ? true : false} onClick={handleToPublish}>
                                        Publish
                                    </Button>
                                </div>
                        }

                    </>
                    :
                    <>

                        {
                            matches ?
                                <div className="article-style" style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', bottom: '0px', right: '5px' }}>
                                    <Button variant="outlined" className="" style={{ marginRight: '10px' }} disabled={titleInfo ? false : true} onClick={handleSave}>
                                        Save Draft
                                    </Button>
                                    <Button variant="outlined" disabled={titleInfo ? false : true} onClick={handleToPublish}>
                                        Publish
                                    </Button>
                                </div>
                                :
                                <div className="article-style" style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', bottom: '0px', right: '5px' }}>
                                    <Button variant="outlined" className="" style={{ marginRight: '10px' }} disabled={titleInfo ? false : true} onClick={handleSave}>
                                        Save Draft
                                    </Button>
                                    <Button variant="outlined" disabled={titleInfo ? false : true} onClick={handleToPublish}>
                                        Publish
                                    </Button>
                                </div>
                        }

                    </>
            }

            {/* {
                matches ?
                    <div className="article-style" style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', bottom: '0px', right: '5px' }}>
                        <Button variant="outlined" className="" style={{ marginRight: '10px' }} disabled={titleInfo ? false : true} onClick={handleSave}>
                            Save Draft
                        </Button>
                        <Button variant="outlined" disabled={titleInfo && editorRef ? false : true} onClick={handleToPublish}>
                            Publish
                        </Button>
                    </div>
                    :
                    <div className="article-style" style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', bottom: '0px', right: '5px' }}>
                        <Button variant="outlined" className="" style={{ marginRight: '10px' }} disabled={titleInfo ? false : true} onClick={handleSave}>
                            Save Draft
                        </Button>
                        <Button variant="outlined" disabled={titleInfo && editorRef ? false : true} onClick={handleToPublish}>
                            Publish
                        </Button>
                    </div>
            } */}



            <Dialog open={openDialg} onClose={handleCloseDialg} maxWidth="sm" fullWidth>
                <DialogTitle sx={{ fontSize: "16px", padding: "14px 16px !important" }}>Do you want to publish this post ?</DialogTitle>
                <DialogActions>
                    <Button variant="contained" className="capitalize" sx={{ padding: "4px 20px !important", borderRadius: "15px !important", height: "25px" }} onClick={handleOpenPublish}>Yes</Button>
                    <Button variant="outlined" className="capitalize" sx={{ padding: "4px 20px !important", borderRadius: "15px !important", height: "25px" }} onClick={() => {
                        handleCloseDialg();
                        history.push("/")
                    }
                    }>No</Button>
                </DialogActions>

            </Dialog>
        </div>
    );
};

export default EditorComponent1;














// import React, { useState, useEffect, useRef } from 'react';
// import { createReactEditorJS } from 'react-editor-js';
// import Header from '@editorjs/header';
// import Paragraph from '@editorjs/paragraph';
// import List from '@editorjs/list';
// import Checklist from '@editorjs/checklist';
// import ImageTool from '@editorjs/image';
// import LinkTool from '@editorjs/link';
// import Table from '@editorjs/table';
// import PDFFile from '@editorjs/pdf';
// import axios from 'axios';
// import { headers } from '../../redux/Actions/connectActions';
// import URL from '../apiurl';

// const ReactEditorJS = createReactEditorJS();

// const EditorComponent1 = () => {
//     const editorRef = useRef(null);
//     const [check, setCheck] = useState('');

//     useEffect(() => {
//         // Apply table styles when check is updated
//         const tableElements = document.querySelectorAll('.editor-content table');

//         tableElements.forEach(table => {
//             table.style.width = '100%';
//             table.style.borderCollapse = 'collapse';

//             const thElements = table.querySelectorAll('th');
//             thElements.forEach(th => {
//                 th.style.backgroundColor = '#f4f4f4';
//                 th.style.padding = '8px';
//                 th.style.border = '1px solid #ddd';
//             });

//             const tdElements = table.querySelectorAll('td');
//             tdElements.forEach(td => {
//                 td.style.padding = '8px';
//                 td.style.border = '1px solid #ddd';
//             });

//             const thead = table.querySelector('thead');
//             if (thead) {
//                 thead.style.backgroundColor = '#f0f0f0';
//             }
//         });
//     }, [check]);

//     // Parser functions for EditorJS blocks
//     const headerParser = (block) => {
//         return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
//     };

//     const paragraphParser = (block) => {
//         return `<p>${block.data.text}</p>`;
//     };

//     const linkToolParser = (block) => {
//         const { link, meta } = block.data;
//         const title = meta?.title || 'Link';
//         return `<a href="${link}" target="_blank" rel="noopener noreferrer">${title}</a>`;
//     };

//     const tableParser = (block) => {
//         const { content } = block.data;
//         const headerRow = content[0]; // First row will be the header
//         const dataRows = content.slice(1); // The rest will be table rows
//         const thead = `<thead><tr>${headerRow.map(cell => `<th>${cell}</th>`).join('')}</tr></thead>`;
//         const tbody = `<tbody>${dataRows.map(row => {
//             return `<tr>${row.map(cell => `<td>${cell}</td>`).join('')}</tr>`;
//         }).join('')}</tbody>`;

//         return `<table>${thead}${tbody}</table>`;
//     };

//     const listParser = (block) => {
//         const items = block.data.items.map(item => `<li>${item}</li>`).join('');
//         return `<ul>${items}</ul>`;
//     };

//     const checklistParser = (block) => {
//         const items = block.data.items.map(item => {
//             return `<li>${item.checked ? '[x]' : '[ ]'} ${item.text}</li>`;
//         }).join('');
//         return `<ul>${items}</ul>`;
//     };

//     const uploadImage = (block) => {
//         return `<img src="${block?.data?.file?.url}" alt="${block?.data?.caption}" style="width: 100%; height: 300px;" />`;
//     }
//     // Define parserOptions
//     const parserOptions = {
//         header: headerParser,
//         paragraph: paragraphParser,
//         linkTool: linkToolParser,
//         table: tableParser,
//         list: listParser,
//         checklist: checklistParser,
//         image: uploadImage,
//     };

//     const handleSave = async () => {
//         let res;
//         try {
//             res = await editorRef.current.saver.save();
//             console.log(res, "res");
//         } catch (error) {
//             console.error("Error saving:", error);
//             return;
//         }

//         if (!res?.blocks || !Array.isArray(res.blocks)) {
//             console.error("No valid blocks found.");
//             return;
//         }
//         console.log(res.blocks, "Parsed blocks before conversion");

//         // Parsing using custom parser functions for each block
//         const parsedHtml = res.blocks.map((block) => {
//             const type = block.type;
//             const parser = parserOptions[type];
//             if (parser) {
//                 return parser(block);
//             }
//             return ''; // Return an empty string if no parser is found for the block type
//         }).join('');

//         console.log(parsedHtml, "Parsed HTML");

//         setCheck(parsedHtml);
//     };

//     const handleOnChange = (outputData) => {
//         editorRef.current = outputData;
//     };




//     // Upload image by file (base64 encoded)
//     const uploadByFile = async (file) => {
//         let reader = new FileReader();
//         return new Promise((resolve, reject) => {
//             reader.onloadend = async () => {
//                 try {
//                     const result = await toconvertImgtoimgUrl(reader.result);
//                     resolve(result);
//                 } catch (error) {
//                     reject(error);
//                 }
//             };
//             reader.onerror = (error) => reject(error);
//             reader.readAsDataURL(file);
//         });
//     };

//     const toconvertImgtoimgUrl = async (imgUrl) => {
//         if (!imgUrl) {
//             return { success: 0, message: "No image provided" };
//         }

//         const image = imgUrl.split(',')[1];
//         const type = imgUrl.split(',')[0].split('/')[1].split(';')[0];

//         const data = {
//             file_type: 'IMAGE',
//             files: [
//                 {
//                     file_size: (image.length / 1048576).toFixed(3), // file size in MB
//                     file_extension: type,
//                     data: image,
//                 },
//             ],
//         };

//         try {
//             const res = await axios.post(`${URL.upload}`, data, headers());
//             console.log(res.status, "Response from API", res.data);
//             if (res.status === 200 && res.data?.length > 0) {
//                 return {
//                     success: 1,
//                     file: {
//                         url: res.data?.[0],
//                     },
//                 };
//             } else {
//                 console.error("Upload failed, no file URL in response");
//                 return {
//                     success: 0,
//                     message: "Upload failed, no file URL",
//                 };
//             }
//         } catch (error) {
//             console.error("API error:", error);
//             return {
//                 success: 0,
//                 message: "Upload failed due to an error",
//             };
//         }
//     };


//     const uploadByUrl = async (file) => {
//         return file;
//     };

//     return (
//         <>
//             <ReactEditorJS
//                 ref={editorRef}
//                 onChange={handleOnChange}
//                 tools={{
//                     header: {
//                         class: Header,
//                         inlineToolbar: ['link'],
//                     },
//                     paragraph: {
//                         class: Paragraph,
//                         inlineToolbar: true,
//                     },
//                     list: {
//                         class: List,
//                         inlineToolbar: true,
//                     },
//                     checklist: {
//                         class: Checklist,
//                         inlineToolbar: true,
//                     },
//                     linkTool: LinkTool,
//                     image: {
//                         class: ImageTool,
//                         config: {
//                             uploader: {
//                                 uploadByFile,
//                                 uploadByUrl,
//                             },
//                         },
//                     },
//                     table: Table,
//                     pdf: {
//                         class: PDFFile,
//                         config: {
//                             uploadEndpoint: '/upload-pdf'
//                         }
//                     }
//                 }}
//             />

//             <h2>After converting into string format</h2>
//             <div
//                 className="editor-content"
//                 dangerouslySetInnerHTML={{ __html: check }}
//                 style={{
//                     WebkitBoxOrient: 'vertical',
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                     WebkitLineClamp: 2,
//                     whiteSpace: 'pre-wrap',
//                 }}
//             />
//             <button onClick={handleSave}>Save</button>
//         </>
//     );
// };

// export default EditorComponent1;





// old code
// import React, { useState, useRef } from 'react';
// import { createReactEditorJS } from 'react-editor-js';
// import Header from '@editorjs/header';
// import Paragraph from '@editorjs/paragraph';
// import List from '@editorjs/list';
// import Checklist from '@editorjs/checklist';
// import ImageTool from '@editorjs/image';
// import LinkTool from '@editorjs/link';
// import Table from '@editorjs/table';
// import edjsHTML from 'editorjs-html';
// import axios from 'axios';
// import { headers } from '../../redux/Actions/connectActions';
// import URL from '../apiurl';

// const ReactEditorJS = createReactEditorJS();

// const EditorComponent1 = () => {
//     const editorRef = useRef(null);
//     const [check, setCheck] = useState('');




//     // Parser functions
//     const headerParser = (block) => {
//         return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
//     };

//     const paragraphParser = (block) => {
//         return `<p>${block.data.text}</p>`;
//     };

//     const linkToolParser = (block) => {
//         const { link, meta } = block.data;
//         const title = meta?.title || 'Link';
//         return `<a href="${link}" target="_blank" rel="noopener noreferrer">${title}</a>`;
//     };

//     const tableParser = (block) => {
//         const rows = block.data.content.map(row => {
//             const cells = row.map(cell => `<td>${cell}</td>`).join('');
//             return `<tr>${cells}</tr>`;
//         }).join('');
//         return `<table>${rows}</table>`;
//     };

//     const listParser = (block) => {
//         const items = block.data.items.map(item => `<li>${item}</li>`).join('');
//         return `<ul>${items}</ul>`;
//     };

//     const checklistParser = (block) => {
//         const items = block.data.items.map(item => {
//             return `<li>${item.checked ? '[x]' : '[ ]'} ${item.text}</li>`;
//         }).join('');
//         return `<ul>${items}</ul>`;
//     };

//     // Define parserOptions
//     const parserOptions = {
//         header: headerParser,
//         paragraph: paragraphParser,
//         linkTool: linkToolParser,
//         table: tableParser,
//         list: listParser,
//         checklist: checklistParser,
//     };
//     const handleSave = async () => {
//         let res;
//         try {
//             res = await editorRef.current.saver.save();
//             console.log(res, "res");
//         } catch (error) {
//             console.error("Error saving:", error);
//             return;
//         }

//         // Check if blocks are present and valid
//         if (!res?.blocks || !Array.isArray(res.blocks)) {
//             console.error("No valid blocks found.");
//             return;
//         }

//         // Log the structure of res.blocks for debugging
//         console.log(res.blocks, "Parsed blocks before conversion");

//         const edjsParser = edjsHTML();

//         try {
//             // Check each block's structure
//             // res.blocks.forEach((block, index) => {
//             //     console.log(`Block ${index}:`, block);
//             //     if (!block.data) {
//             //         console.warn(`Block ${index} does not have data:`, block);
//             //     }
//             // });
//             const parsedHtml = edjsParser.parse(res);
//             console.log(parsedHtml, "Parsed HTML");

//             const convertIntoHtml = parsedHtml.join('');
//             setCheck(convertIntoHtml);
//         } catch (error) {
//             console.error("Error parsing blocks:", error);
//         }
//     };

//     console.log(check, "check");

//     const handleOnChange = (outputData) => {
//         editorRef.current = outputData;
//     };

//     const toconvertImgtoimgUrl = async (imgUrl) => {
//         if (!imgUrl) {
//             return { success: 0, message: "No image provided" };
//         }

//         let image = imgUrl.split(',')[1];
//         let type = imgUrl.split(',')[0].split('/')[1].split(';')[0];
//         let base64 = imgUrl.split(",")[1].split("=")[0];
//         let strLength = base64.length;
//         let fileLength = strLength - (strLength / 8) * 2;

//         let data = {
//             file_type: 'IMAGE',
//             files: [
//                 {
//                     file_size: Number((fileLength / 1048576).toFixed(3)),
//                     file_extension: type,
//                     data: image.toString(),
//                 },
//             ],
//         };

//         try {
//             const res = await axios.post(`${URL.upload}`, data, headers());
//             console.log(res, "after");

//             if (res.status === 200) {
//                 return {
//                     success: 1,
//                     file: {
//                         url: res.data?.[0], // Adjust based on your actual response structure
//                     },
//                 };
//             } else {
//                 return {
//                     success: 0,
//                     message: "Upload failed",
//                 };
//             }
//         } catch (error) {
//             console.error("API error:", error);
//             return {
//                 success: 0,
//                 message: "Upload failed",
//             };
//         }
//     };




//     const uploadByFile = async (file) => {
//         console.log(file, "file")
//         let reader = new FileReader()
//         reader.readAsDataURL(file);
//         console.log(reader.readAsDataURL, "RESULT")
//         reader.onloadend = () => {
//             toconvertImgtoimgUrl(reader.result)
//         }

//     }

//     const uploadByUrl = async (file) => {
//         return file;
//     };

//     return (
//         <>
//             <ReactEditorJS
//                 ref={editorRef}
//                 onChange={handleOnChange}
//                 tools={{
//                     header: {
//                         class: Header,
//                         inlineToolbar: ['link'],
//                     },
//                     paragraph: {
//                         class: Paragraph,
//                         inlineToolbar: true,
//                     },
//                     list: {
//                         class: List,
//                         inlineToolbar: true,
//                     },
//                     checklist: {
//                         class: Checklist,
//                         inlineToolbar: true,
//                     },
//                     linkTool: LinkTool,
//                     image: {
//                         class: ImageTool,
//                         config: {
//                             uploader: {
//                                 uploadByFile,
//                                 uploadByUrl
//                             },
//                         },
//                     },
//                     table: Table,
//                 }}
//             />

//          <h2>After converting into string format</h2>
//             <div
//                 dangerouslySetInnerHTML={{ __html: check }}
//                 style={{
//                     WebkitBoxOrient: 'vertical',
//                     overflow: 'hidden',
//                     textOverflow: 'ellipsis',
//                     WebkitLineClamp: 2,
//                     whiteSpace: 'pre-wrap',
//                 }}
//             />
//             <button onClick={handleSave}>Save</button>
//         </>
//     );
// };

// export default EditorComponent1;
