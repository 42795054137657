import Postingdd from '../Atom/postingdropdown/postingdropdown';
import Publicdd from '../Atom/publicdd/publicdd';
import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import CssTextField from '../Fields/CssTextField';
// import { styled } from '@mui/material/styles';
import { styled } from '@mui/styles';
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from 'react';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import '../Molecule/feedposts/events/event.css'
import ArticlePage from '../Molecule/ShareownArticle/Article';
import '../../Components/Molecule/ShareownArticle/Article.css';
import { Typography } from '@mui/material';


const SelectTextField = styled(Select)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '12px',
      height: '54px',
      boxShadow: '0px 3px 4px #756d6d29',
      color: 'grey',
    },
  }
});

export default function Preview(props) {

console.log(props,"cousss")
  // dropdown state
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { handleChange } = props
  return (
    <div>
      <div className="a-tags" style={{ textAlign: 'left', padding: '10px 30px' }}>

        {/* <p style={{paddingBottom: '8px'}}><input type="text" className="form-input w-100" placeholder="What would you like to share?"/></p> */}
        {/* <input type="text" className="form-input w-100" placeholder="#tags" value={props.data.tags} onChange={(e)=>handleChange('tags',e.target.value)}/> */}
        {/* <Button className="a-btn mt-20" sx={{ mt: 2 }} href="/newarticle">New Article</Button> */}
        {/* <div className="a-tags" style={{border: '1px solid  black',borderRadius: '8px',padding: '10px',marginTop: '10px'}}>
                    <p style={{color: 'black'}}><strong>Share a Published Article</strong></p>
                    <ul className="w-100" style={{listStyle: 'unstyled'}}>
                    <li>Co-authors <input type="text" value={props.data.coAuthors} onChange={(e)=>handleChange('coAuthors',e.target.value)} className="form-input" placeholder="Mention @"/></li>
                    <li>Contributors <input type="text" value={props.data.contributors} onChange={(e)=>handleChange('contributors',e.target.value)} className="form-input" placeholder="Mention @"/></li>
                    <li>Organisations <input type="text" value={props.data.organisations} onChange={(e)=>handleChange('organisations',e.target.value)} className="form-input" placeholder="Mention @organisation department"/></li>
                    <li>Select Article
                              <Button style={{border:'2px solid gray',marginLeft:'13px',borderRadius:'8px',width:'212px',height:'27px',textTransform:'capitalize',color:'#2E3B43',fontSize:'14px',fontWeight:"400"}}
                                    id="demo-positioned-button"
                                    aria-controls="demo-positioned-menu"
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={handleClick}
                                  //  endIcon={<KeyboardArrowDownIcon style={{ marginLeft: "20px" }}/>}
                                >
                                  <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
                                      <div >Select article type</div>
                                      <div><KeyboardArrowDownIcon style={{marginTop:'6px'}}/></div>
                                  </div>
                          </Button>
                    </li>
                      </ul>
                <Menu 
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                    }}
                    transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                    }}
                >
       
      
                        <div >
                           <MenuItem style={{width:'205px'}} onClick={props.subarticlepage}>Organisation Name</MenuItem>
                           <MenuItem style={{width:'205px'}} onClick={props.subarticlepage}>Organisation Name</MenuItem>
                           <MenuItem style={{width:'205px'}} onClick={props.subarticlepage}>Organisation Name</MenuItem>
                           <MenuItem style={{width:'205px'}} onClick={props.subarticlepage}>Organisation Name</MenuItem>
                           
                        </div>
                </Menu>
                </div> */}


        {/* <ArticlePage data={data} url={url} open={send} close={()=>setSend(false)}/> */}

        
        <div style={{ padding: '15px 20px', backgroundColor: '#e8e8e0', justifyContent: 'space-between' }} className="d-flex cursor">
          {props.articleData?.articleTitle ?
            <p style={{ overflow: "hidden", flex: 1 }}>
              <Typography className="font-bold capitalize add-cover text-ellipsis" style={{ pt: 1, fontSize: '20px' }}>
                {props.articleData?.articleTitle}
              </Typography></p> : ''}

          <Button variant="outlined" sx={{ py: '3px!important' }}>Read Article</Button>
        </div>

      </div>

    </div>
  )
}