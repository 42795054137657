import { Box } from "@mui/material"
import axios from "axios"
import { useEffect, useState } from "react"
import AutocompleteForm from "./Autocomplete"

const OpenChoice = (props) => {

    const [options, setOptions] = useState([])
    let extensionType = props?.item?.extension?.[0]?.valueCodeableConcept?.coding?.[0]?.display

    useEffect(() => {
        const fetchData = async () => {
            if (props?.item?.answerValueSet) {
                let data = await props?.getOptions(props?.item?.answerValueSet)
                setOptions(data)
            }
            else {
                setOptions(props?.item?.answerOption)
            }
        }

        fetchData()
    }, [props?.item])

    return (
        <Box>
            {extensionType === 'Lookup' || extensionType === 'Auto-complete' ?
                <AutocompleteForm
                    options={options} {...props}
                    validators={props?.item?.required ? ['required'] : []}
                    errorMessages={props?.item?.required ? ['Select an option'] : []}
                    multiple={props?.item?.repeats}
                    freeSolo={true}
                />
                : ''}
        </Box>
    )
}

export default OpenChoice