import React, { useState } from "react";
import axios from 'axios'
import URL from '../../Components/apiurl'
import UserService from '../../services/UserService';
import CircularProgress from '@mui/material/CircularProgress';
export const payloadData = (type, payload) => {
    return ({
        type: type,
        payload: payload
    })
}


export const headers = () => {
    return (
        {
            headers: {
                Authorization: `Bearer ${UserService.getToken()}`
            }
        }
    )
}

export const getPeers = () => {
    return (dispatch) => {
        axios.get(`${URL.findPeersDataByUserId}/${UserService.getUserId()}`, headers()).then(user => {
            let list = user.data.data.map(data => {
                data['display'] = data['name'] // Assign new key
                delete data['name']; // Delete old key
                return data;
            })
            dispatch({
                type: 'PEERSDATA',
                payload: list
            })
        }).catch(err => {
            dispatch({
                type: 'PEERSDATA',
                payload: []
            })
        })
    }
}

export const getRequests = () => {
    return (dispatch) => {
        axios.get(`${URL.requestPending}/${UserService.getUserId()}`, headers()).then(res => {
            dispatch({
                type: 'REQUESTS',
                payload: res.data.data
            })
        })
            .catch(err => {
                dispatch({
                    type: 'REQUESTS',
                    payload: []
                })
            })
    }
}

export const getHashtags = () => {
    return (dispatch) => {
        axios.get(`${URL.suggestedTopics}suggested_topics`, headers()).then(res => {
            dispatch({
                type: 'HASHTAGS',
                payload: res.data.data
            })
        }).catch(err => {
            dispatch({
                type: 'HASHTAGS',
                payload: []
            })
        })
    }
}

export const getMentions = (org) => {
    return (dispatch) => {
        axios.get(`${URL.mentions}/${org}`, headers()).then(res => {

            dispatch({
                type: 'MENTIONS',
                payload: res.data.data
            })
        }).catch(err => {
            dispatch({
                type: 'MENTIONS',
                payload: []
            })
        })
    }
}

export const getAllApps = () => {
    return (dispatch) => {
        axios.get(URL.findAllApps, headers()).then(res => {
            dispatch({
                type: 'ALL_APPS',
                payload: res.data.data
            })
        }).catch(err => {
            dispatch({
                type: 'ALL_APPS',
                payload: []
            })
        })
    }
}

export const getSuggestedApps = () => {
    return dispatch => {
        axios.get(URL.suggestedApps + UserService.getUserId(), headers())
            .then(res => dispatch(payloadData('SUGGESTED_APPS', res.data.data))
            )
    }
}

export const getAppsByUserId = () => {
    return dispatch => {
        axios.get(URL.findAppsByUserId + UserService.getUserId(), headers())
            .then(res => dispatch(payloadData('APPS_BY_USER_ID', res.data.data))
            )
    }
}

export const getPeople = (api, key, limit, offset, setIsLoading) => {
    console.log(setIsLoading, api, key, limit, offset, "vero")
    return (dispatch) => {
        if (key == true) {
            setIsLoading(true)
            axios.get(`${URL[api]}/${sessionStorage.getItem("userId")}/limit/${limit}/offset/1`, headers()).then(res => res.data).then(data => {
                dispatch({
                    type: 'PEOPLE',
                    payload: data.data
                })

                setIsLoading(false)

            }).catch(err => {
                dispatch({
                    type: 'PEOPLE',
                    payload: []
                })
                setIsLoading(false)
            })
        }
        else {

            axios.get(`${URL[api]}/${sessionStorage.getItem("userId")}`, headers()).then(res => res.data).then(data => {
                dispatch({
                    type: 'PEOPLE',
                    payload: data.data
                })

            }).catch(err => {
                dispatch({
                    type: 'PEOPLE',
                    payload: []
                })

            })
        }
        // axios.get(`${URL[api]}/${sessionStorage.getItem("userId")}/limit/15/offset/1`, {
    }
}

export const getConnectCount = () => {
    return (dispatch) => {
        axios.get(`${URL.getCounts}/${UserService.getUserId()}`, headers()).then(res => {
            dispatch({
                type: 'COUNT',
                payload: res.data.data
            })
        }).catch(err => {
            dispatch({
                type: 'COUNT',
                payload: []
            })
        })
    }
}

export const getLinkedAccounts = () => {
    return dispatch => {
        axios.get(URL.getLinkedAccounts + UserService.getUserId(), headers())
            .then(res => dispatch(payloadData('LINKED_ACCOUNTS', res.data)))
            .catch(() => dispatch(payloadData('LINKED_ACCOUNTS', [])))
    }
}
export const getLinkedAccounts1 = () => {
    return dispatch => {

        axios.get(process.env.REACT_APP_API_URL_PYI + `/getLinkedAccounts`,
            {
                headers: {
                    "Authorization": `bearer ${UserService.getToken()}`
                }
            }
        )
            .then(res => {
                console.log("kumar123")
                console.log(res.data, "2222344232ed")
                dispatch(payloadData('LINKED_ACCOUNTS', res.data))
            }).catch(() => dispatch(payloadData('LINKED_ACCOUNTS', [])))

        // axios.get(URL.getLinkedAccounts + UserService.getUserId(), headers())
        //     .then(res => dispatch(payloadData('LINKED_ACCOUNTS', res.data)))
        //     .catch(() => dispatch(payloadData('LINKED_ACCOUNTS', [])))
    }
}
export const getLinkedAccountsOrg = (id) => {
    console.log(id, "idtest")
    return dispatch => {
        if (id) {
            axios.get(process.env.REACT_APP_API_URL_PYI + `/getLinkedAccountsDocstethPage/${id}`,
                {
                    headers: {
                        "Authorization": `bearer ${UserService.getToken()}`
                    }
                }
            )
                .then(res => {
                    console.log(res, "9090saiii")
                    dispatch(payloadData('LINKED_ACCOUNTS_ORG', res.data))
                }).catch(() => dispatch(payloadData('LINKED_ACCOUNTS_ORG', [])))
        }
    }
}